import React, { useCallback } from "react";

const useContrastColor = () => {
  const hexToRgb = useCallback((hex) => {
    hex = hex.replace(/^#/, "");

    // Parse r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
  }, []);

  const getLuminance = useCallback((hex) => {
    const [r, g, b] = hexToRgb(hex).map((c) => {
      c /= 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }, []);

  const getFontColor = useCallback((backgroundColor) => {
    const luminance = getLuminance(backgroundColor);
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
  }, []);

  return [getFontColor];
};

export default useContrastColor;
