import React, { useContext, useEffect, useState } from "react";
import Index from "../../Index";
import {
  UserLogin,
  activeShortCut,
  dashboardSortTagAction,
  manageCustomScript,
  manageStocksTagsAction,
} from "../../../redux/user/action";
import { loginValidations } from "../../../validation/Validations";
import { ThemeContext } from "../../../theme/ThemeContext";
// reducer.js or wherever your reducers are defined

const Login = () => {
  // Const declaration region start
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();

  const { setTheme } = useContext(ThemeContext);

  const { wishListDataTemp } = Index.useSelector((state) => state.UserReducer);
  // Const declaration region end
  const [disable, setDisable] = useState(false);
  // State declaration region start
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [userError, setUserError] = useState({});
  const [error, setError] = useState("");

  // eye button handle for password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // State declaration region end

  // Hooks declaration region start
  // Hooks declaration region end

  // Function declaration region start
  // Input fields on change input handle start

  const onChangeInput = (e) => {
    setError("");
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const validationErrors = loginValidations({ ...user, [name]: value });
    setUserError((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
    if (validationErrors.email) {
      setDisable(true);
    } else if (!Object.keys(validationErrors)?.length) {
      setDisable(false);
    }
  };
  // Input fields on change input handle end

  //get all stocks tags
  const getStocksTags = async (payload, stocks) => {
    const { token, settings } = payload;
    const tokens = stocks?.map((item) => JSON.parse(item?.token));
    let bodyData = {
      tokens,
      chartView: settings?.chartView,
      timeFrame: settings?.chartDuration[settings?.chartView]?.timeFrame,
      interval: settings?.chartDuration[settings?.chartView]?.interval,
    };
    try {
      const response = await Index.DataService(token).post(
        Index.Api.user.getMultipleStocksTags,
        bodyData
      );

      if (response?.data?.data) {
        const tagsData = response?.data?.data?.map((item) => ({
          token: item?.token,
          tag: item?.tag,
        }));

        dispatch(manageStocksTagsAction(tagsData || []));
        const sortTags = settings?.sorting?.["Dashboard"]?.tags ?? [];
        dispatch(dashboardSortTagAction({ tags: sortTags }));
      }
    } catch (error) {}
  };

  //All watchList
  const getWatchList = async (payload, stocks) => {
    const { token } = payload;
    try {
      const response = await Index.DataService(token).get(
        Index.Api.user.getUserWatchList
      );
      dispatch(manageCustomScript(response?.data?.data));
      getStocksTags(payload, stocks);
    } catch (error) {}
  };

  // Submit handler function start

  const handleUserLoginForm = async (e) => {
    e.preventDefault();
    setDisable(true);
    const formData = new URLSearchParams();
    formData.append("email", user.email);
    formData.append("password", user.password);

    const validationErrors = loginValidations(user);
    if (Object.keys(validationErrors).length > 0) {
      setUserError(validationErrors);
    } else {
      await dispatch(
        UserLogin(
          formData,
          navigate,
          getWatchList,
          setUser,
          setDisable,
          setError,
          setTheme
        )
      );
    }
  };
  // Submit handler function end
  // Function declaration region end

  return (
    <>
      <Index.Box className="login-bg">
        <Index.Box className="login-inner-main auth-main custom-login-inner">
          <img className="main-logo" src={Index.Png.logo} alt="logo" />
          <Index.Typography variant="h1" className="check-target">
            Log In To Your Account
          </Index.Typography>
          <Index.Typography
            className="blue-text login-sub-heading cus-forgot-sub-heading check-target"
            variant="p"
            component="p"
          >
            Welcome back! Please enter your details.
          </Index.Typography>
          <Index.Box component="form" onSubmit={handleUserLoginForm}>
            <Index.Box className="cus-input-grp">
              <Index.Typography
                className="cus-lable light-font check-target"
                variant="p"
                component="p"
              >
                Email
              </Index.Typography>
              <Index.Box className="form-group">
                <Index.TextField
                  value={user.email}
                  fullWidth
                  id="fullWidth.email"
                  name="email"
                  className="form-control custom-form-input"
                  placeholder="Enter email"
                  // autoComplete="off"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={onChangeInput}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                />
              </Index.Box>
              {userError.email && (
                <span className="auth-error-msg">{userError.email}</span>
              )}
            </Index.Box>
            <Index.Box className="cus-input-grp mt-5">
              <Index.Typography
                className="cus-lable light-font check-target"
                variant="p"
                component="p"
              >
                Password
              </Index.Typography>
              <Index.Box className="form-group">
                <Index.OutlinedInput
                  name="password"
                  fullWidth
                  id="fullWidth-password outlined-adornment-password"
                  className="form-control custom-form-input custom_form_control"
                  placeholder="Enter password"
                  onChange={onChangeInput}
                  value={user.password}
                  type={showPassword ? "text" : "password"}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                  inputProps={{
                    autoComplete: "new-password",
                    maxLength: 30,
                  }}
                  autoComplete="off"
                  autofill="off"
                  endAdornment={
                    <Index.InputAdornment
                      position="end"
                      className="pass_position"
                    >
                      <Index.IconButton
                        className="icon_end_icon"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Index.VisibilityOff className="pass-icon-color-admin" />
                        ) : (
                          <Index.Visibility className="pass-icon-color-admin" />
                        )}
                      </Index.IconButton>
                    </Index.InputAdornment>
                  }
                />
              </Index.Box>
              {userError.password && (
                <span className="auth-error-msg">{userError.password}</span>
              )}
            </Index.Box>
            <Index.Box className="forgot-link-main custom-forgot-box">
              <Index.Link className="forgot-link cus-link" to="forgot-password">
                <span className="forgot-text"> Forgot your password ?</span>
              </Index.Link>
            </Index.Box>
            {error && <span className="auth-error-msg">{error}</span>}
            <Index.PrimaryButton
              className="primary-btn login-btn custom-btn"
              btnLabel="Log In"
              type="submit"
              disabled={disable}
              // onClick={handleSubmit}
            />
          </Index.Box>
          {/* <Index.Link className="forgot-link" to="forgot-password">
            Forgot your password ?
          </Index.Link> */}
          <Index.Link className="forgot-link cus-link" to="register">
            Don't have an account ? <span> Register</span>
          </Index.Link>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Login;
