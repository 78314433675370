import {
  memo,
  React,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Index from "../../Index";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import _ from "lodash";
import Constants from "../../../component/common/constants";
import useRowPerPage from "../../../component/hooks/useRowPerPage";
import useCurrentInterval from "../../../component/common/current-interval/useCurrentInterval";
import { manageDashboardTags } from "../../../redux/user/action";

const OpenOrderList = ({
  socket,
  scriptData,
  handleUpdateStock,
  getStockTagsCount,
  openOrderUpdate,
  setOpenOrderUpdate,
  openOrderExitStockTokens,
  setOpenOrderExitStockTokens,
}) => {
  const dispatch = Index.useDispatch();
  const [curr, next, getCurrentInterval] = useCurrentInterval();
  const {
    token: userAuthToken,
    selectedScript,
    chartView,
    chartDuration,
    currentPageRedux,
    userLoginDetail,
    displaySettings,
    wishListDataTemp,
    sorting,
  } = Index.useSelector((state) => state.UserReducer);
  const dataGridRef = useRef(null);
  const [size, setSize] = useState({
    width: window.innerWidth - 10,
    height: 0,
  });
  const [dataTableHeight, setDataTableHeight] = useState(0);
  const [loader, setLoader] = useState(false);
  const [chartLiveData, setChartLiveData] = useState(null);
  const [orderTagsData, setOrderTagsData] = useState([]);
  // const [scriptData, setScriptData] = useState([]);
  // const [rowsPerPage] = useRowPerPage(dataGridRef, dataTableHeight, 52, orderTagsData);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 0,
  });

  const handlePageSizeChange = (size) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: size }));
  };

  const onResize = (event, { size }) => {
    setSize((prev) => ({ ...prev, height: size.height }));
  };

  const getColorClassByTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "buy":
        return "green-buy-btn";
      case "sell":
        return "red-buy-btn";
      case "exit":
        return "red-buy-btn";
      case "watch":
        return "blue-watch-btn";
      default:
        return "";
    }
  };

  const handleTagUpdate = (tagName, payload) => {
    const filterCondition = (item, data) => {
      if (data?.chartView == "Positional") {
        return (
          JSON.parse(item?.token) == JSON.parse(data?.token) &&
          item?.chartView === data?.chartView &&
          item?.interval === data?.interval &&
          item?.positionalTimeFrame == data?.positionalTimeFrame
        );
      }
      if (data?.chartView == "Intraday") {
        return (
          JSON.parse(item?.token) == JSON.parse(data?.token) &&
          item?.chartView === data?.chartView &&
          item?.interval === data?.interval
        );
      }
      return false;
    };

    if (!tagName) {
      setOrderTagsData((prev) =>
        prev?.filter((item) => {
          return !filterCondition(item, payload);
        })
      );
    } else {
      setOrderTagsData((prev) =>
        prev?.map((item) => {
          const isMatch = filterCondition(item, payload);
          if (isMatch) {
            return { ...item, ...payload };
          }
          return item;
        })
      );
    }

    if (!openOrderUpdate) {
      handleUpdateStock(payload);
    }

    setOpenOrderUpdate(null);
  };

  useEffect(() => {
    if (openOrderUpdate) {
      if (openOrderUpdate?.removeTag) {
        handleTagUpdate("", openOrderUpdate);
      } else {
        const tag = openOrderUpdate?.tag ?? "Tag";
        handleTagUpdate(tag, openOrderUpdate);
      }
    }
  }, [openOrderUpdate]);

  useEffect(() => {
    if (openOrderExitStockTokens && openOrderExitStockTokens?.length > 0) {
      setOrderTagsData((prev) => {
        return prev?.filter(
          (item) => !openOrderExitStockTokens?.includes(JSON.parse(item?.token))
        );
      });
      setOpenOrderExitStockTokens([]);
    }
  }, [openOrderExitStockTokens]);

  const updateTokenTags = useCallback(async (t, rowData) => {
    const formattedTimestamp = getCurrentInterval(
      +rowData?.intervalValue,
      rowData?.exchange_type === "MCX"
    );
    try {
      const response = await Index.DataService(userAuthToken).post(
        `/user/stock/stockTagAddAndUpdate`,
        {
          tag: t,
          stock_name: rowData?.stock_name || "-",
          previousTag: rowData?.tag,
          script: selectedScript?.script?.toString(),
          lastTradedPrice: rowData?.lastTradePrice,
          target: 12,
          stopLoss: 12,
          entryPrice: rowData?.lastTradePrice,
          // exitPrice: 3,
          token: JSON.parse(rowData?.token),
          tradeType: rowData?.tradeType,
          timeFrame: rowData?.timeFrame,
          timeStamp: formattedTimestamp,
          chartView,
          positionalTimeFrame: rowData?.positionalTimeFrame,
          interval: rowData?.interval,
          intervalValue: rowData?.intervalValue,
        }
      );
      dispatch(
        manageDashboardTags(userAuthToken, {
          chartView,
          chartDuration,
          wishListDataTemp,
          sorting,
        })
      );
    } catch (error) {
      dispatch(
        manageDashboardTags(userAuthToken, {
          chartView,
          chartDuration,
          wishListDataTemp,
          sorting,
        })
      );
    }

    let rowPayload = {
      tag: t,
      token: JSON.parse(rowData?.token),
      chartView: rowData?.chartView,
      interval: rowData?.interval,
      lastTradedPrice: rowData?.lastTradePrice,
      entryPrice: rowData?.lastTradePrice,
      timeStamp: formattedTimestamp,
    };

    if (rowData?.chartView === "Positional") {
      rowPayload["positionalTimeFrame"] = rowData?.positionalTimeFrame;
    }

    if (t === "Reverse") {
      rowPayload["tag"] = rowData?.tag == "Buy" ? "Sell" : "Buy";
      handleTagUpdate(t, rowPayload);
    } else {
      handleTagUpdate(t, rowPayload);
    }
    getStockTagsCount();

    if (socket) {
      socket.emit("tagging_action", userLoginDetail?._id);
    }
  }, []);

  const removeStockTag = useCallback(async (t, payload) => {
    try {
      let data = {
        token: JSON.parse(payload?.token),
        tag: t,
        lastTradedPrice: payload?.lastTradePrice,
        chartView: payload?.chartView,
        timeFrame: payload?.positionalTimeFrame,
        interval: payload?.interval,
      };
      const response = await Index.DataService(userAuthToken).post(
        Index.Api.user.removeStock,
        data
      );

      let rowPayload = {
        tag: payload?.tag,
        token: JSON.parse(payload?.token),
        chartView: payload?.chartView,
        interval: payload?.interval,
      };

      if (payload?.chartView === "Positional") {
        rowPayload["positionalTimeFrame"] = payload?.positionalTimeFrame;
      }

      handleTagUpdate("", rowPayload);
      dispatch(
        manageDashboardTags(userAuthToken, {
          chartView,
          chartDuration,
          wishListDataTemp,
          sorting,
        })
      );
      getStockTagsCount();
      if (socket) {
        socket.emit("tagging_action", userLoginDetail?._id);
      }
    } catch (error) {}
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "S no.",
      width: 74,
      hideable: false,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "script",
      headerName: "Script",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "entry",
      headerName: "Entry",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stopLoss",
      headerName: "Stop Loss",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      padding: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "target",
      headerName: "Target",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      padding: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 100,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        let text = "";

        if (params?.row?.chartView === "Intraday") {
          text = params?.row?.timeFrame;
        }

        if (params?.row?.chartView === "Positional") {
          const timeFrameValue = params?.row?.positionalTimeFrame
            ? Constants.shortPositionalTimeframe[
                +params?.row?.positionalTimeFrame
              ]
            : "";
          const intervalValue = params?.row?.timeFrame;
          text = `${
            timeFrameValue ? timeFrameValue + "-" : ""
          }${intervalValue}`;
        }
        return (
          <Index.Box className="list-label-main">
            <Index.Button
              className={`${getColorClassByTag(params?.value)} label-btn`}
              disableRipple
            >
              {params?.value}
            </Index.Button>
            <Index.Button
              className={`${getColorClassByTag(params?.value)} timeframe-btn`}
              disableRipple
            >
              {text}
            </Index.Button>
          </Index.Box>
        );
      },
    },
    {
      field: "CMP",
      headerName: "CMP",
      type: "number",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chargepoint",
      headerName: "Change Points (%)",
      type: "number",
      width: 230,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
      renderCell: (params) => {
        return (
          <>
            <Index.Box
              className="MuiDataGrid-cellContent"
              sx={{ color: params?.value?.[0] == "+" ? "#008000" : "red" }}
            >
              {params.value}
            </Index.Box>
          </>
        );
      },
    },
    {
      field: "dateTime",
      headerName: "Date & Time",
      type: "number",
      width: 230,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
    },
    {
      field: "action",
      headerName: "Actions",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <DropdownMenuAction
          className="drop-down"
          row={params.row}
          updateTokenTags={updateTokenTags}
          removeStockTag={removeStockTag}
          handleTagUpdate={handleTagUpdate}
        />
      ),
    },
  ];

  let isWeekendDay = [6, 7]?.includes(Index.moment().isoWeekday());
  let { date: prevCloseDate } = usePreviousDateCalculator(isWeekendDay ? 2 : 1);

  const rows = useMemo(() => {
    return orderTagsData?.map((item, index) => {
      if (
        chartLiveData &&
        JSON.parse(item.token) == JSON.parse(chartLiveData.token)
      ) {
        item["chartLiveData"] = chartLiveData;
        item["livePrice"] = chartLiveData.tick;
        item["lastTradePrice"] = chartLiveData.tick[5];
        item["closePrice"] = chartLiveData.tick[6];
      }

      if (!chartLiveData && item?.stockData && item?.stockData?.length) {
        const lastDateData = item?.stockData?.filter(
          (data) =>
            Index.moment(new Date(data[0])).format("YYYY-MM-DD") ==
            Index.moment(prevCloseDate).format("YYYY-MM-DD")
        );

        let prevClosePrice =
          lastDateData.length > 0
            ? lastDateData[lastDateData.length - 1][4]
            : item?.previousClosePrice;

        item["lastTradePrice"] =
          item?.stockData[item?.stockData?.length - 1][4] || 0;
        item["closePrice"] = prevClosePrice || 0;
      }
      return {
        _id: item?._id,
        rowIndex: index - paginationModel?.page * paginationModel?.pageSize,
        rowId: index + 1,
        tag: item?.tag || "-",
        CMP: item?.lastTradePrice || "-",
        entry: item?.entryPrice || "-",
        stopLoss: item?.topLossAt?.price || "-",
        target: item?.targetAt?.price || "-",
        chargepoint: `${
          item?.closePrice > item?.lastTradePrice
            ? (item?.lastTradePrice - item?.closePrice).toFixed(2)
            : item?.closePrice !== item?.lastTradePrice
            ? `+${(item?.lastTradePrice - item?.closePrice).toFixed(2)}`
            : 0
        } (${
          item?.lastTradePrice && item?.closePrice
            ? `${(
                ((item?.lastTradePrice - item?.closePrice) / item?.closePrice) *
                100
              ).toFixed(2)}%`
            : "0%"
        })`,
        script: item?.stock_name || "-",
        stockData: item?.stockData || [],
        livePrice: item?.livePrice,
        chartLiveData: item?.chartLiveData,
        tradeType: item?.tradeType || "",
        chartView: item?.chartView || "",
        timeFrame: item?.timeFrame || "",
        positionalTimeFrame: item?.positionalTimeFrame || null,
        interval: item?.interval || null,
        intervalValue: item?.intervalValue || null,
        id: index + 1,
        dateTime: Index.moment(item?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        miniChart: {
          token: item?.token,
          lastTradePrice: "",
        },
        token: item?.token,
        exchange_type: item?.exchange_type,
        action: [
          {
            token: item?.token,
            value: Index.Svg.roundPlus,
          },
          {
            token: item?.token,
            stockName: item?.stock_name || "-",
            value: Index.Svg.whiteDots,
            currentTag: item?.tag || "-",
          },
        ],
      };
    });
  }, [orderTagsData, chartLiveData]);

  const { getDate } = usePreviousDateCalculator(
    chartView === "Intraday" ? 1 : chartDuration[chartView]?.timeFrame - 1
  );

  const getAllHistoryData = async (dataLength, live) => {
    // const tokens = dataLength?.map((item) => ({
    //   token: JSON.parse(item?.token),
    //   type: item?.exchange_type,
    // }));
    const fromDate = getDate(+dataLength?.positionalTimeFrame);
    const tokens = [
      {
        token: JSON.parse(dataLength?.token),
        type: dataLength?.exchange_type,
      },
    ];
    const intervalData = Constants.chartInterval[dataLength?.interval];
    const minutesToLess =
      Constants.chartInterval[dataLength?.interval]?.value * 60000;
    const formData = {
      tokens: tokens,
      interval: intervalData?.interval,
      fromDate,
      toDate: Index.moment().format("YYYY-MM-DD"),
      timeFrame:
        Constants.shortPositionalTimeframe[+dataLength?.positionalTimeFrame] ??
        "",
      offset: intervalData?.offset,
    };

    const formDataIntraday = {
      tokens: tokens,
      interval: intervalData?.interval,
      fromDate: Index.moment().format("YYYY-MM-DD"),
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset: intervalData?.offset,
      previousDate: prevCloseDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(userAuthToken).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
          ]
        : [
            Index.DataService(userAuthToken).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
            Index.DataService(userAuthToken).post(
              Index.Api.user.getAllHistoricalData,
              formData
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (formattedCombinedData.length) {
        setOrderTagsData((prevData) =>
          prevData?.map((ele) => {
            const token = JSON.parse(ele?.token);
            const item = formattedCombinedData.find((data) => data[token]);

            if (item && item[token]?.length) {
              const format = item[token].map((el) => [
                Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess,
                +el[1],
                +el[2],
                +el[3],
                +el[4],
              ]);
              return {
                ...ele,
                stockData: format,
                previousClosePrice: previousDateData[token],
              };
            } else {
              return ele;
            }
          })
        );
      }
    } catch (error) {}
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(() => resolve, ms));

  const callAllHistorical = async () => {
    let dataLength = orderTagsData?.slice(
      (paginationModel?.page + 1) * paginationModel?.pageSize -
        paginationModel?.pageSize,
      (paginationModel?.page + 1) * paginationModel?.pageSize
    );

    if (dataLength?.length) {
      const chunkData = _.chunk(dataLength, 4);
      chunkData?.forEach(async (item) => {
        item?.forEach((ele) => {
          getAllHistoryData(ele, chartView === "Intraday");
        });
        await delay(500);
      });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      callAllHistorical();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [scriptData, paginationModel, chartDuration, chartView]);

  let mainContentSecElement =
    document.querySelector(".main-content-sec")?.clientHeight ?? 0;
  let mainContentElement = document.querySelector(".open-order-list-resizable");
  let buttonSecElement = document.querySelector(".open-order-header");

  useEffect(() => {
    if (mainContentElement && buttonSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const buttonSecHeight = buttonSecElement.clientHeight;
      setDataTableHeight(mainHeight - buttonSecHeight);
    }
  }, [mainContentElement, buttonSecElement, size]);

  let tableBodyContentElement = document.querySelector(
    ".MuiDataGrid-virtualScroller"
  )?.clientHeight;

  useEffect(() => {
    if (tableBodyContentElement) {
      const totalRows = Math.floor(tableBodyContentElement / 52);
      handlePageSizeChange(totalRows);
    }
  }, [tableBodyContentElement, size]);

  useEffect(() => {
    if (socket && orderTagsData?.length && chartView === "Intraday") {
      orderTagsData
        ?.slice(
          (paginationModel?.page + 1) * paginationModel?.pageSize -
            paginationModel?.pageSize,
          (paginationModel?.page + 1) * paginationModel?.pageSize
        )
        ?.forEach((token) => {
          socket.emit("joinRoom", `token_${JSON.parse(token?.token)}`);

          socket.on(`token_${JSON.parse(token?.token)}`, (data) => {
            setChartLiveData(data);
          });
        });
    }
    return () => {
      // if (socket && orderTagsData?.length) {
      //   orderTagsData?.forEach((token) => {
      //     socket.emit("leaveRoom", `token_${token?.token}`);
      //   });
      // }
      // setChartLiveData(null);
    };
  }, [socket, orderTagsData, paginationModel, chartView]);

  useEffect(() => {
    setOrderTagsData(scriptData);
  }, [scriptData]);

  return (
    <>
      <ResizableBox
        className={`open-order-list-resizable ${
          displaySettings?.UtilityBar == "left"
            ? "open-order-list-resizable-left"
            : ""
        }`}
        width={size.width}
        height={size.height}
        onResize={onResize}
        minConstraints={[size.width, 0]}
        maxConstraints={[size.width, mainContentSecElement * 0.5]}
        axis="y"
        resizeHandles={["n"]}
      >
        <Index.Box
          className="box"
          sx={{ width: size.width + "px", height: size.height + 50 + "px" }}
        >
          <Index.Box className="open-order-list-sec">
            <Index.Box className="open-order-header">
              <Index.Box className="open-order-title">
                <Index.Typography
                  className="order-title-text"
                  variant="p"
                  component="p"
                >
                  Open Order List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="open-order-button">
                <Index.Box
                  className="order-btn-bg cus-center minus-btn"
                  onClick={() => {
                    setSize((prev) => ({ ...prev, height: 0 }));
                  }}
                >
                  <img
                    className="order-btn-img"
                    src={Index.Svg.minusGreen}
                    alt="minusGreen"
                  />
                </Index.Box>
                <Index.Box
                  className="order-btn-bg cus-center max-btn"
                  onClick={() => {
                    setSize((prev) => ({
                      ...prev,
                      height: mainContentSecElement * 0.5,
                    }));
                  }}
                >
                  <img
                    className="order-btn-img"
                    src={Index.Svg.maxScrren}
                    alt="maxScrren"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              className="open-order-body"
              sx={{
                height: size?.height + "px",
                paddingTop: size?.height - 60 < 1 ? 0 : 10 + "px",
              }}
            >
              <Index.Box className="table-sec">
                <Index.Box
                  className="open-order-table-main"
                  sx={{ width: "100%", height: size?.height - 10 + "px" }}
                >
                  <Index.DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 12,
                        },
                      },
                    }}
                    pageSizeOptions={[1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={({ page, pageSize }) => {
                      setPaginationModel({ page, pageSize });
                      // dispatch(manageListSettings({ page, pageSize }));
                    }}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    components={{
                      NoRowsOverlay: () => (
                        <Index.NoRowsOverlay loader={loader} />
                      ),
                    }}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Footer></Index.Footer>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </ResizableBox>
    </>
  );
};

const DropdownMenuAction = ({
  row,
  updateTokenTags,
  removeStockTag,
  handleTagUpdate,
}) => {
  const {
    id,
    tag,
    token,
    CMP,
    script,
    timeFrame,
    tradeType,
    chartView,
    positionalTimeFrame,
    interval,
    intervalValue,
    exchange_type,
  } = row;
  const [allTags, setAllTags] = useState([
    "Exit",
    "Watch",
    "Buy",
    "Sell",
    "Remove",
    "Reverse",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);

  //Tag menu controllers
  const handleTagMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTagMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTagMenuActionClick = (item, tag, token) => {
    let rowData = {
      stock_name: script,
      lastTradePrice: CMP,
      token: token,
      tag: tag,
      positionalTimeFrame,
      interval,
      intervalValue,
      timeFrame,
      tradeType,
      chartView,
      exchange_type,
    };

    let chartPayload = {
      token,
      tag: tag,
      lastTradedPrice: CMP,
      entryPrice: CMP,
      chartView,
      interval,
      exchange_type,
    };
    if (chartView === "Positional") {
      chartPayload["positionalTimeFrame"] = positionalTimeFrame;
    }

    if (item === "Reverse") {
      updateTokenTags("Reverse", rowData);
    } else if (item === "Remove") {
      removeStockTag("Watch", rowData);
    } else if (item === "Exit") {
      removeStockTag(tag, rowData);
    } else {
      updateTokenTags(item, rowData);
    }
    handleTagMenuClose();
  };

  const handleFilterTag = (item) => {
    if (!tag) {
      return ["Watch", "Buy", "Sell"].includes(item);
    }

    if (tag?.toLowerCase() === "watch") {
      return ["Watch", "Buy", "Sell", "Remove"].includes(item);
    }

    if (tag?.toLowerCase() === "buy") {
      return ["Exit", "Buy", "Reverse"].includes(item);
    }

    if (tag?.toLowerCase() === "sell") {
      return ["Exit", "Sell", "Reverse"].includes(item);
    }

    if (tag?.toLowerCase() === "exit") {
      return ["Exit", "Buy", "Sell"].includes(item);
    }
  };

  return (
    <Index.Box className="my-Menu-Component">
      <Index.Box className="d-flex">
        <button
          className="table-gray-btn menu-three-dot-btn"
          disableRipple
          onClick={handleTagMenuClick}
        >
          <img
            className="action-btn-img"
            alt="add-action-icon"
            src={Index.Svg.whiteDots}
          />
        </button>
      </Index.Box>
      <Index.Box className="menu-list-main">
        {/* Tag Menu Dropdown */}
        <Index.Menu
          id="simple-menu"
          className="tag-menu analysis-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleTagMenuClose}
        >
          {allTags.filter(handleFilterTag).map((item) => (
            <Index.MenuItem
              key={`${item}`}
              className={`${tag === item && "li-active"}`}
              onClick={() => handleTagMenuActionClick(item, tag, token)}
            >
              {item}
            </Index.MenuItem>
          ))}
        </Index.Menu>
      </Index.Box>
    </Index.Box>
  );
};

export default memo(OpenOrderList);

// import { React, useEffect, useMemo, useState } from "react";
// import Index from "../../Index";
// import {
//   Subscribe_stock_tokens,
//   useSocketData,
//   getPrviousDataOfStock,
//   useStockDataListener,
// } from "../../../socket";
// import { useSelector } from "react-redux";
// import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
// import { ContactsOutlined } from "@mui/icons-material";
// import CustomMiniChart from "../../../component/custom-chart/mini-chart/CustomMiniChart";
// import moment from "moment";
// import { toast } from "react-toastify";
// import CustomMiniHistoricalChart from "../../../component/custom-chart/mini-chart/CustomMiniHistoricalChart";

// const setDatefun = (data) => {
//   let d = new Date(data);
//   let h = d.getHours() + 5;
//   let m = d.getMinutes() + 30;
//   d.setHours(h, m, 0, 0);
//   return d.getTime();
// };

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 1000,
//   bgcolor: "#1E2431",
//   borderRadius: "10px 10px 0 0",
//   border: "none",
// };

// const allStockTypes = [
//   { id: 1, name: "All", value: "All" },
//   { id: 2, name: "Equity", value: "NSE" },
//   { id: 3, name: "F&O", value: "MCX" },
// ];

// const OpenOrderList = (props) => {
//   const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse", "Remove"];
//   let stock_data = useStockDataListener();
//   const { socketData, addSocketData } = useSocketData();
//   const [allTagsData, setAllTagsData] = useState([]);
//   const [stockCategoryFilter, setStockCategoryFilter] = useState("All");
//   const {
//     wishListData,
//     token,
//     displaySettings,
//     timeFrame,
//     chartView,
//     chartDuration,
//     rowsPerPage,
//     twoSideArrowZoom,
//   } = useSelector((state) => state.UserReducer);
//   const [miniChartOpen, setminiChartOpen] = useState(false);
//   const [isActive, SetIsActive] = useState(true);
//   const [active, Setactive] = useState(false);
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setminiChartOpen(true);
//   const [tags, setTag] = useState("watch");
//   const [scriptData, setScriptData] = useState();
//   const [activeMiniChartOpen, setActiveMiniChartOpen] = useState();
//   const [openModel, setOpenModel] = useState({});
//   const [currenChart, setCurrenChart] = useState("");
//   const [orderCurrentChart, setOrderCurrentChart] = useState("");
//   const [updateOrder, setUpdateOrder] = useState({});
//   const [placeOrderData, setPlaceOrderData] = useState({});
//   const [editOrderData, setEditOrderData] = useState({});
//   const [targetOrTopLoss, setTargetOrTopLoss] = useState();
//   const [paginationModel, setPaginationModel] = useState({
//     page: 0,
//     pageSize: rowsPerPage,
//   });
//   const handleClose = () => {
//     setOpenModel({});
//     setminiChartOpen(false);
//   };
//   const userLoginToken = Index.useSelector((state) => state.UserReducer.token);
//   const currentTime = moment();
//   const marketOpenTime = moment().set({ hour: 9, minute: 15, second: 0 });
//   const marketCloseTime = moment().set({ hour: 15, minute: 30, second: 0 });

//   const isMarketOpen = currentTime.isBetween(
//     marketOpenTime,
//     marketCloseTime,
//     null,
//     "[]"
//   );

//   useEffect(() => {
//     Index.DataService(userLoginToken)
//       .get(`${Index.Api.user.getAllTags}?open=true`)
//       // Index.DataService(userLoginToken).get(`${Index.Api.user.getAllTags}`)
//       .then((data) => {
//         setAllTagsData(data?.data?.data);
//       })
//       .catch((error) => {});
//   }, []);

//   const columns = [
//     { field: "id", headerName: "Sr.", width: 76, headerAlign: "center", hideable: false, align:'center' },
//     {
//       field: "script",
//       headerName: "Script",
//       width: 200,
//       editable: false,
//       align:'center',
//       headerAlign: "center",
//     },
//     {
//       field: "tag",
//       headerName: "Tag",
//       width: 120,
//       editable: false,
//       align:'center',
//       headerAlign: "center",

//       renderCell: (params) => (
//         <Index.Button
//           className={
//             params.value.toLowerCase() === "buy"
//               ? "green-buy-btn"
//               : params.value.toLowerCase() === "sell"
//               ? "red-buy-btn"
//               : params.value.toLowerCase() === "watch"
//               ? "blue-watch-btn"
//               : "green-buy-btn"
//           }
//           disableRipple
//         >
//           {params.value}
//         </Index.Button>
//       ),
//     },
//     {
//       field: "CMP",
//       headerName: "CMP",
//       headerAlign: "center",
//       type: "number",
//       width: 150,
//       editable: false,
//       align:'center',
//     },
//     {
//       field: "miniChart",
//       headerName: "Mini Chart",
//       type: "number",
//       width: 200,
//       headerAlign: "center",
//       align:'center',
//       editable: false,
//       renderCell: (params) => (
//         <Index.Button
//         onClick={() => {
//           handleOpen();
//           setActiveMiniChartOpen(params?.value?.token);
//         }}
//         disableRipple
//         >
//           {/* <CustomLineChartSingle
//             height={65}
//             width={200}
//             liveData={socketData}
//             index={`1_${params?.value?.token}`}
//             stockToken={params?.value?.token}
//           /> */}
//           {isMarketOpen ? (
//             <CustomMiniChart
//               chartData={socketData}
//               token={JSON.parse(params?.value?.token)}
//             />
//           ) : (
//             <CustomMiniHistoricalChart
//               chartData={params?.value?.stockData || []}
//               // chartData={socketData}
//               token={JSON.parse(params?.value?.token)}
//               index={params?.value?.index}
//               exchangeType={params?.value?.exchange_type}
//             />
//           )}
//         </Index.Button>
//       ),
//     },
//     {
//       field: "entry",
//       headerName: "Entry",
//       type: "number",
//       width: 150,
//       editable: false,
//       headerAlign: "center",
//       align:'center',

//       // renderCell: (params) => <img className='table-img' src={params.value} />
//     },
//     {
//       field: "stopLoss",
//       headerName: "Stop Loss",
//       type: "number",
//       width: 120,
//       editable: false,
//       headerAlign: "center",
//       align:'center',
//       padding: 0,
//     },
//     {
//       field: "target",
//       headerName: "Target",
//       type: "number",
//       width: 120,
//       editable: false,
//       headerAlign: "center",
//       align:'center',
//       padding: 0,
//     },
//     {
//       field: "timeFrame",
//       headerName: "Frame",
//       type: "number",
//       width: 100,
//       editable: false,
//       headerAlign: "center",
//       align:'center',
//       padding: 0,
//     },
//     {
//       field: "createdAt",
//       headerName: "Time",
//       type: "number",
//       width: 120,
//       editable: false,
//       headerAlign: "center",
//       align:'center',
//       padding: 0,
//     },
//   ];

//   useEffect(() => {
//     if (
//       stock_data !== null &&
//       allTagsData.some((e) => e.token === +JSON.parse(stock_data?.token)) &&
//       isMarketOpen
//     ) {
//       setScriptData((prevScriptData) => ({
//         ...prevScriptData,
//         [stock_data?.token]: stock_data,
//       }));

//       let token = JSON.parse(stock_data?.token);
//       if (socketData[token]) {
//         socketData[token].push([
//           setDatefun(+stock_data?.exchange_timestamp),
//           stock_data.last_traded_price / 100,
//         ]);
//       } else {
//         socketData[token] = [
//           [
//             setDatefun(+stock_data?.exchange_timestamp),
//             stock_data.last_traded_price / 100,
//           ],
//         ];
//       }
//       addSocketData(token, socketData[token]);
//     }
//   }, [stock_data, allTagsData]);

//   useEffect(() => {
//     setScriptData(null);
//     if (allTagsData?.length > 0) {
//       // const tokenList = wishListData?.map((token) => {
//       const tokenList = allTagsData?.map((token) => {
//         return token?.token?.toString() || 0;
//       });
//       Subscribe_stock_tokens(tokenList);
//     }
//     // }, [wishListData]);
//   }, [allTagsData]);

//   const row = useMemo(() => {
//     return Object.values(scriptData || {})?.map((item, index) => {
//       const lastTradePrice = isMarketOpen
//         ? (
//             parseInt(scriptData?.[item?.token]?.last_traded_price) / 100
//           ).toFixed(2)
//         : parseInt(item?.stockData?.[item?.stockData?.length - 1][2])?.toFixed(
//             2
//           );
//       const closePrice = isMarketOpen
//         ? (parseInt(scriptData?.[item?.token]?.close_price) / 100).toFixed(2)
//         : parseInt(item?.stockData?.[item?.stockData?.length - 1][4])?.toFixed(
//             2
//           );
//       const changePointPercent =
//         ((lastTradePrice - closePrice) / closePrice) * 100;
//       const changePointValue = lastTradePrice - closePrice;
//       const script = wishListData?.filter(
//         (data) => data?.token == JSON.parse(item?.token)
//       )?.[0]?.name;

//       // const tag = getCurrentTag(item?.token)
//       const tagData = scriptData?.[item?.token]?.findToken;

//       return {
//         tag: tagData?.tag || item?.tag || "Watch",
//         status: tagData?.tag || item?.tag || "Watch",
//         CMP: lastTradePrice,
//         entry: tagData?.entryPrice || item?.entryPrice || "-",
//         stopLoss: tagData?.topLossAt?.price || item?.topLossAt?.price || "-",
//         target: tagData?.targetAt?.price || item?.topLossAt?.price || "-",
//         chargepoint: `${
//           closePrice > lastTradePrice
//             ? changePointValue.toFixed(2)
//             : closePrice !== lastTradePrice
//             ? `+${changePointValue.toFixed(2)}`
//             : 0
//         } (${changePointPercent ? `${changePointPercent.toFixed(2)}%` : "0%"})`,
//         script: script || item?.stock_name,
//         id: index + 1,
//         miniChart: {
//           token: item?.token,
//           lastTradePrice: lastTradePrice,
//         },
//         timeFrame: tagData?.timeFrame || item?.timeFrame,
//         exchange_type: tagData?.exchange_type || item?.exchange_type,
//         createdAt: Index.moment(tagData?.createdAt).format("HH:MM:SS"),
//         token: item?.token,
//         action: [
//           {
//             token: item?.token,
//             value: Index.Svg.roundPlus,
//           },
//           {
//             token: item?.token,
//             stockName: item?.name || item?.stock_name,
//             value: Index.Svg.whiteDots,
//           },
//         ],
//       };
//     });
//   }, [scriptData]);

//   //handle Tag update
//   const handleTagUpdate = (tagName, token) => {
//     setScriptData((prevScriptData) => ({
//       ...prevScriptData,
//       [JSON.stringify(token)]: {
//         ...prevScriptData[JSON.stringify(token)],
//         findToken: {
//           ...(prevScriptData[JSON.stringify(token)]?.findToken || {}),
//           tag: tagName,
//         },
//       },
//     }));
//   };

//   const removeTopLossAtOrTargetAt = async (token, status) => {
//     // try {
//     //   const response = await Index.DataService(userLoginToken).post(
//     //     "/user/stocks/remove-toploss-or-target",
//     //     {
//     //       token: JSON.parse(token),
//     //       targetTag: status,
//     //     }
//     //   );
//     //   toast.success("status updated");
//     //   setTargetOrTopLoss()
//     // setEditOrderData({})
//     // } catch (error) {
//     //   setTargetOrTopLoss()
//     // setEditOrderData({})
//     // }
//   };

//   function deleteLine(chart) {
//     // get the selected annotation
//     if (orderCurrentChart) {
//       let controller = orderCurrentChart.plot(0).annotations();
//       let selectedAnnotation = controller.getSelectedAnnotation();
//       controller.removeAnnotation(selectedAnnotation);
//       if (targetOrTopLoss) {
//         setTimeout(() => {
//           removeTopLossAtOrTargetAt(editOrderData?.token, targetOrTopLoss);
//         }, 1000);
//       }
//     }
//     // // get the selected annotation
//     // var selectedAnnotation = currenChart.annotations().getSelectedAnnotation();
//     // // remove the selected annotation
//     // currenChart.annotations().removeAnnotation(selectedAnnotation);
//   }

//   //Category Wise Filter
//   const handleCategoryWiseFilter = (ele) => {
//     const exchangeType = ele?.exchange_type?.toString()?.toLowerCase();
//     if (stockCategoryFilter === "All") {
//       return ele;
//     }
//     return exchangeType === stockCategoryFilter?.toLowerCase();
//   };

//   useEffect(() => {
//     window.addEventListener("keydown", function (e) {
//       if (e?.keyCode === 46) {
//         if (targetOrTopLoss) {
//           deleteLine(orderCurrentChart);
//         }
//       }
//     });
//   }, [orderCurrentChart, targetOrTopLoss]);

//   //Positional Data
//   const getHistorylist = (headerData) => {
//     const formData = new URLSearchParams();
//     formData.append("exchange", headerData?.exchange_type || "MCX");
//     formData.append("symboltoken", headerData?.token);
//     formData.append("interval", chartDuration[chartView]?.interval);
//     formData.append(
//       "fromdate",
//       Index.moment()
//         .subtract(chartDuration[chartView]?.timeFrame, "d")
//         .set({ hour: 9, minute: 15 })
//         .format("YYYY-MM-DD HH:mm")
//     );
//     formData.append(
//       "todate",
//       Index.moment()
//         .add(1, "d")
//         .set({ hour: 3, minute: 30 })
//         .format("YYYY-MM-DD HH:mm")
//     );
//     Index.DataService(userLoginToken)
//       .post(Index.Api.user.angelGetAllHistory, formData)
//       .then(async (res) => {
//         if (res.data.status === 200) {
//           let tagDataObj = allTagsData?.find(
//             (ele) => ele?.token == headerData?.token
//           );

//           if (tagDataObj) {
//             tagDataObj.stockData = res.data.data;
//             setScriptData((prevScriptData) => ({
//               ...prevScriptData,
//               [headerData?.token]: tagDataObj,
//             }));
//           }
//         }
//       })
//       .catch((err) => {});
//   };

//   useEffect(() => {
//     if (allTagsData?.length > 0 && !isMarketOpen) {
//       allTagsData?.forEach((tag, index) => {
//         setTimeout(() => {
//           getHistorylist(tag);
//         }, index * 2500);
//       });
//     }
//   }, [allTagsData, isMarketOpen]);

//   useEffect(() => {
//     setPaginationModel({
//       page: 0,
//       pageSize: rowsPerPage,
//     });
//   }, [rowsPerPage]);

//   const [width, setWidth] = useState(200);
//     const [height, setHeight] = useState(200);
//     const [isResizing, setIsResizing] = useState(false);

//     const startResize = (e) => {
//         setIsResizing(true);
//     };

//     const stopResize = () => {
//         setIsResizing(false);
//     };

//     const resize = (e) => {
//         if (isResizing) {
//             setWidth(e.clientX - e.target.getBoundingClientRect().left);
//             setHeight(e.clientY - e.target.getBoundingClientRect().top);
//         }
//     };

//   return (
//     <>
//       <Index.Box className="dashboard-wrapper open-order-list-wrap">
//         <Index.Header></Index.Header>
//         <Index.Overlay></Index.Overlay>
//         <Index.Box className="main-content-sec">
//           <Index.Box className=" dash-grid">
//             <Index.Grid container spacing={2} className="chart-main">
//               {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => (
//                 <Index.Grid
//                   item
//                   xs={12}
//                   sm={6}
//                   md={3}
//                   key={index}
//                   className="grid-main"
//                 >
//                   <Index.Box className="small-chart-box">
//                     {/* <Index.OrderChartHeader /> */}
//                     <Index.Box className="line-chart-body">
//                       {/* <Index.CustomLineChart index={index} DivideHeight="4.6" /> */}
//                     </Index.Box>
//                   </Index.Box>
//                 </Index.Grid>
//               ))}
//             </Index.Grid>
//           </Index.Box>
//         </Index.Box>
//         <Index.Box
//         className="resizable"
//          style={{ width: `${width}px`, height: `${height}px` }}
//             onMouseMove={resize}
//             onMouseUp={stopResize}
//             onMouseLeave={stopResize} >
//         <Index.Box className="open-order-list-sec"
//                 onMouseDown={startResize}>
//           <Index.Box className="open-order-header">
//             <Index.Box className="open-order-title">
//               <Index.Typography
//                 className="order-title-text"
//                 variant="p"
//                 component="p"
//               >
//                 Open Order List
//               </Index.Typography>
//             </Index.Box>
//             <Index.Box className="open-order-button">
//               <Index.Box
//                 className="order-btn-bg cus-center minus-btn"
//                 onClick={() => SetIsActive(!isActive)}
//               >
//                 <img
//                   className="order-btn-img"
//                   src={Index.Svg.minusGreen}
//                   alt="minusGreen"
//                 />
//               </Index.Box>
//               <Index.Box
//                 className="order-btn-bg cus-center max-btn"
//                 onClick={() => Setactive(!active)}
//               >
//                 <img
//                   className="order-btn-img"
//                   src={Index.Svg.maxScrren}
//                   alt="maxScrren"
//                 />
//               </Index.Box>
//             </Index.Box>
//           </Index.Box>
//           {isActive && (
//             <Index.Box
//               className={active ? "open-order-body active" : "open-order-body"}
//             >
//               <Index.Box className="table-sec">
//                 <Index.Box sx={{ width: "100%" }}>
//                   <Index.DataGrid
//                     rows={[]}
//                     columns={columns}
//                     initialState={{
//                       pagination: {
//                         paginationModel: {
//                           pageSize: 12,
//                         },
//                       },
//                     }}
//                     pageSizeOptions={[12]}
//                     // checkboxSelection
//                     disableRowSelectionOnClick
//                   />
//                 </Index.Box>
//               </Index.Box>
//               <Index.Footer></Index.Footer>
//             </Index.Box>
//           )}
//         </Index.Box>
//         </Index.Box>

//         <Index.SideBar></Index.SideBar>
//       </Index.Box>
//     </>
//   );
// };

// export default OpenOrderList;
