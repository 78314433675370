import Svg from "../assets/svg";
import Png from "../assets/png";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormHelperText,
  List,
  ListItem,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Pagination,
  Autocomplete,
  IconButton,
  InputAdornment,
  Grid,
  Checkbox,
  CircularProgress,
  Modal,
  Skeleton,
  Badge,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import RecentIndex from "../component/modalbody/RecentIndex";
import DefaultSetting from "./modalbody/DefaultSetting";
import EditShortcut from "./modalbody/EditShortcut";
import ReplaceShortcut from "./modalbody/ReplaceShortcut";
import AlertModal from "./modalbody/AlertModal";
import SortingModal from "./modalbody/SortingModal";
import GridSettings from "./modalbody/GridSettings";
import GridModal from "./modalbody/GridModal";
import ReportModal from "./modalbody/ReportModal";
import DownloadChart from "./modalbody/DownloadChart";
import EditTargetLoss from "./modalbody/EditTargetLoss";
import { Link, NavLink, useLocation } from "react-router-dom";
import Overlay from "./common/Overlay";
import NotificationTab from "./modalbody/NotificationModal";
import GrayButton from "./Button/graybgbutton/graybgbutton";
import RedButton from "./Button/redbutton/RedButton";
import PrimaryButton from "./Button/primarybutton/PrimaryButton";
import RoundButton from "./Button/roundbutton/RoundButton";
import SecondaryBtn from "./Button/secondarybtn/SecondaryBtn";
import DatePickerMobile from "./common/datepicker/DatePickerMobile";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../config/DataService";
import { Api } from "../config/Api";
import { Check, ExpandMore } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import { MuiColorInput } from "mui-color-input";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { DateRangePicker } from "react-date-range";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Collapse from "@mui/material/Collapse";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AutoSquareTime from "./modalbody/AutoSquareTime";
import TableLoader from "./loader/TableLoader";
import NoRowsOverlay from "./common/NoRowsOverlay";
import useContrastColor from "./hooks/useContrastColor";

let trimText = (val = "") => {
  return val.split(" ")?.filter((e) => e === "")?.length >= 2
  ? val.trim()
    : val;
  };
  const Index = {
    moment,
    Checkbox,
  Check,
  useLocation,
  Autocomplete,
  Api,
  DataService,
  Svg,
  Png,
  Box,
  NavLink,
  useNavigate,
  Button,
  Typography,
  TextField,
  FormHelperText,
  GrayButton,
  List,
  ClearIcon,
  RefreshIcon,
  IconButton,
  InputAdornment,
  ListItem,
  RedButton,
  RoundButton,
  RecentIndex,
  DefaultSetting,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  FormControl,
  SecondaryBtn,
  EditShortcut,
  PrimaryButton,
  ReplaceShortcut,
  AlertModal,
  Stack,
  Pagination,
  SortingModal,
  GridSettings,
  GridModal,
  ReportModal,
  DownloadChart,
  EditTargetLoss,
  DatePickerMobile,
  Link,
  Overlay,
  useDispatch,
  useSelector,
  toast,
  trimText,
  MuiColorInput,
  NotificationTab,
  Grid,
  DataGrid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMore,
  StarIcon,
  StarBorderIcon,
  Tooltip,
  DateRangePicker,
  CircularProgress,
  Modal,
  MenuIcon,
  MoreVertIcon,
  QueryStatsIcon,
  Collapse,
  Skeleton,
  Badge,
  VisibilityOutlinedIcon,
  VisibilityOffOutlinedIcon,
  AutoSquareTime,
  TableLoader,
  NoRowsOverlay,
  useContrastColor,
};
export default Index;
