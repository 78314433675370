import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Index from "../../Index";
import { useState } from "react";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import useKeyboardShortcut from "../../common/keyboard-shortcuts/useKeyboardShortcut";
import usePreviousDateCalculator from "../../common/previous-date-calculator/usePreviousDateCalculator";
import Constants from "../../common/constants";
import {
  getWishListData,
  manageDashboardTags,
  manageDrawingButton,
  manageDrawingToolActiveDeactive,
  pageSetting,
} from "../../../redux/user/action";
import DrawChartButton from "../../modal/DrawChartButton";
import useCurrentInterval from "../../common/current-interval/useCurrentInterval";

const ChartHeader = (props) => {
  const dispatch = Index.useDispatch();
  const {
    onClose,
    headerData,
    chartData,
    tags,
    setTags,
    allTags,
    index,
    downloadsType,
    activeChart,
    loopIndex,
    chartLiveData,
    previousClosePrice,
    miniChartModalRef,
    isDrawingChart,
    drawingChart,
    setDrawingChart,
    drawingChartData,
    setDrawingChartData,
    isPopupChart,
  } = props;
  const {
    chartDuration,
    chartView,
    timeFrame,
    token: userToken,
    displaySettings,
    customScripts,
    wishListData,
    wishListDataTemp,
    selectedScript,
    gridView,
    userLoginDetail,
    isModalDrawingButtonActive,
    isModalDrawingToolOpen,
    theme,
    sorting,
  } = Index.useSelector((state) => state.UserReducer);
  const timerId = useRef(null);

  const themeTitle = localStorage.getItem("default-theme");
  const [curr, next, getCurrentInterval] = useCurrentInterval();

  let isWeekendDay = [6, 7]?.includes(Index.moment().isoWeekday());
  let {date: prevCloseDate} = usePreviousDateCalculator(isWeekendDay ? 2 : 1);

  const lastDateData =
    chartData?.length > 0 &&
    chartData.filter(
      (data) =>
        Index.moment(new Date(data[0])).format("YYYY-MM-DD") ==
        Index.moment(prevCloseDate).format("YYYY-MM-DD")
    );
  const prevClosePrice =
    lastDateData?.length > 0 ? lastDateData[lastDateData?.length - 1][4] : null;

  let lastTradePrice =
    props?.live && chartLiveData
      ? chartLiveData?.tick[5]
      : chartData?.[0] && +chartData?.[chartData?.length - 1][4];

  let closePrice = props?.live
    ? chartLiveData
      ? chartLiveData?.tick[6]
      : previousClosePrice
    : prevClosePrice;

  let updatedDateAndTime =
    props?.live && chartLiveData
      ? Index.moment(+chartLiveData?.timestamp).format("DD-MM-YYYY HH:mm:ss")
      : chartData?.length &&
        Index.moment(new Date(+chartData?.[chartData?.length - 1][0]))
          .subtract(5, "hours")
          .subtract(30, "minutes")
          .format("DD-MM-YYYY HH:mm:ss");

  useEffect(() => {
    if (chartView === "Intraday") {
      lastTradePrice = chartLiveData?.tick[5];
      closePrice = chartLiveData?.tick[6];
      updatedDateAndTime = Index.moment(chartLiveData?.timestamp).format(
        "DD-MM-YYYY HH:mm:ss"
      );
    }
  }, [chartLiveData?.tick]);

  // const lastTradePrice =
  //   (headerData?.stockData?.[0] &&
  //     headerData?.stockData?.[headerData?.stockData?.length - 1][5]?.toFixed(
  //       2
  //     )) ||
  //   // (chartData?.[0] && chartData?.[chartData?.length - 1][4]?.toFixed(2));
  // chartData?.[0] && +chartData?.[chartData?.length - 1][4];

  // const closePrice =
  //   (headerData?.stockData?.[0] &&
  //     headerData?.stockData?.[headerData?.stockData?.length - 1][4]?.toFixed(
  //       2
  //     )) ||
  //   // (chartData?.[0] && chartData?.[chartData?.length - 1][4]?.toFixed(2));
  //   prevClosePrice;

  const changePointPercent = Math.abs(
    (((lastTradePrice - closePrice) / closePrice) * 100)?.toFixed(2)
  );

  const changePointValue = (lastTradePrice - closePrice)?.toFixed(2);

  const [headMenu, setHeadMenu] = useState(false);
  const [downloadMenu, setDownloadMenu] = useState(false);
  const [watchListMenu, setWatchListMenu] = useState(false);
  const headMenuRef = useRef(null);
  const downloadMenuRef = useRef(null);
  const watchListMenuRef = useRef(null);

  async function updateTokenTags(t) {
    const formattedTimestamp = getCurrentInterval(
      +Constants.chartInterval[chartDuration?.[chartView]?.interval]?.value,
      headerData?.exch_seg === "MCX"
    );
    try {
      await Index.DataService(userToken).post(
        `/user/stock/stockTagAddAndUpdate`,
        {
          tag: t,
          previousTag: tags,
          script: selectedScript?.script?.toString(),
          stock_name: headerData?.stock_name || headerData?.name,
          lastTradedPrice: lastTradePrice,
          target: 12,
          stopLoss: 12,
          entryPrice: lastTradePrice,
          // exitPrice: 3,
          token: index,
          tradeType: chartView,
          timeFrame:
            Constants.shortInterval[chartDuration[chartView]?.interval],
          timeStamp: formattedTimestamp,
          chartView,
          positionalTimeFrame:
            chartView === "Positional"
              ? chartDuration[chartView]?.timeFrame
              : null,
          interval: chartDuration[chartView]?.interval,
          intervalValue:
            Constants.intervalsValue[chartDuration[chartView]?.interval],
        }
      );

      const tag = t === "Reverse" ? (tags === "Buy" ? "Sell" : "Buy") : t;
      props.handleTagUpdate && props.handleTagUpdate(tag, index);
      if (drawingChartData) {
        setDrawingChartData((prev) => ({ ...prev, tag }));
      }

      dispatch(
        manageDashboardTags(userToken, {
          chartView,
          chartDuration,
          wishListDataTemp,
          sorting,
        })
      );
    } catch (error) {
      const tag = t === "Reverse" ? (tags === "Buy" ? "Sell" : "Buy") : t;
      props.handleTagUpdate && props.handleTagUpdate(tag, index);
      if (drawingChartData) {
        setDrawingChartData((prev) => ({ ...prev, tag }));
      }
      dispatch(
        manageDashboardTags(userToken, {
          chartView,
          chartDuration,
          wishListDataTemp,
          sorting,
        })
      );
    }

    if (props?.socket) {
      props?.socket.emit("tagging_action", userLoginDetail?._id);
    }

    // setHeadMenu(!headMenu);
    setHeadMenu(false);
  }

  async function removeStockTag(t) {
    try {
      let data = {
        token: JSON.parse(index),
        tag: t,
        lastTradedPrice: lastTradePrice,
        chartView,
        timeFrame: chartDuration[chartView]?.timeFrame,
        interval: chartDuration[chartView]?.interval,
      };
      await Index.DataService(userToken).post(Index.Api.user.removeStock, data);
      props.handleTagUpdate && props.handleTagUpdate("", index);
      dispatch(
        manageDashboardTags(userToken, {
          chartView,
          chartDuration,
          wishListDataTemp,
          sorting,
        })
      );
      if (drawingChartData) {
        setDrawingChartData((prev) => ({ ...prev, tag: "" }));
      }
      if (!props?.isDrawingChart) {
        onClose();
      }
    } catch (error) {
      // toast.success("Tag updated");
    }

    if (props?.socket) {
      props?.socket.emit("tagging_action", userLoginDetail?._id);
    }
  }

  const getCurrentTag = () => {
    const params = {
      chartView,
      timeFrame: chartDuration[chartView]?.timeFrame,
      interval: chartDuration[chartView]?.interval,
    };
    Index.DataService(userToken)
      .get(`/user/stocks-single-tag?token=${index}`, { params })
      .then(({ data }) => {
        setTags(data?.data?.tag || "");
      });
  };

  //Download chart
  const printImage = (ext) => {
    const filter = (node) => {
      const exclusionClasses = ["anychart-credits"];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    if (ext?.toLowerCase() === "pdf") {
      const element =
        miniChartModalRef?.current ||
        document.getElementById(`print-${JSON.parse(index)}`);
      htmlToImage
        .toPng(element, {
          filter: filter,
        })
        .then((dataUrl) => {
          const doc = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: "a4",
          });
          const scaleFactor = doc.internal.pageSize.width / element.offsetWidth;
          const x =
            (doc.internal.pageSize.width - element.offsetWidth * scaleFactor) /
            2;
          const y =
            (doc.internal.pageSize.height -
              element.offsetHeight * scaleFactor) /
            2;
          doc.addImage(
            dataUrl,
            "PNG",
            x,
            y,
            element.offsetWidth * scaleFactor,
            element.offsetHeight * scaleFactor
          );

          doc.save(
            `${headerData?.stock_name || headerData?.name}-${JSON.parse(
              index
            )}.${ext?.toLowerCase()}`
          );
        })
        .catch((error) => {
          console.error("Error converting element to image:", error);
        });
    } else {
      let toImageFn;
      switch (ext?.toLowerCase()) {
        case "svg":
          toImageFn = htmlToImage.toSvg;
          break;
        case "png":
          toImageFn = htmlToImage.toPng;
          break;
        case "jpg":
          toImageFn = htmlToImage.toJpeg;
          break;
        default:
          console.error("Invalid file format");
          return;
      }
      let element =
        miniChartModalRef?.current ||
        document.getElementById(`print-${JSON.parse(index)}`);
      toImageFn(element, {
        filter: filter,
      })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = `${
            headerData?.stock_name || headerData?.name
          }-${JSON.parse(index)}.${ext?.toLowerCase()}`;
          link.href = dataUrl;
          link.click();
        })
        .catch((e) => {
          console.error(e, "DownloadChart error");
        });
    }
  };

  //Handle add to watchList
  const handleAddToWatchList = useCallback(async (value, watchListId) => {
    try {
      const response = await Index.DataService(userToken).post(
        Index.Api.user.addScriptToList,
        {
          ...value,
          watchListId,
        }
      );
      // setStockTagCount(data.data.data);
      // dispatch(getWishListData([...wishListData, value]));
      // Index.toast.success("Stock added to watchlist");
      setWatchListMenu((prev) => !prev);
    } catch (error) {
      // Index.toast.error("Stock already in watchlist");
    }
  }, []);

  const handlePageSetting = (data) => {
    const rowsPerPage = gridView?.row * gridView?.column;
    const countData = Math.ceil(data?.length / rowsPerPage);
    dispatch(pageSetting({ page: 1, count: countData, rowsPerPage }));
  };

  const getWatchListData = async (watchListId) => {
    try {
      const response = await Index.DataService(userToken)
        .get(Index.Api.user.getWatchListScript + `${watchListId}`)
        .then((e) => {
          dispatch(getWishListData(e.data.data));
          handlePageSetting(e.data.data);
        });
    } catch (error) {}
  };

  //Handle remove script from watchList
  const handleRemoveScriptFromWatchList = useCallback(
    async (watchListId, scriptId) => {
      try {
        const response = await Index.DataService(userToken).post(
          Index.Api.user.removeScriptFromWatchList,
          {
            watchListId,
            scriptId,
          }
        );
        getWatchListData(watchListId);
        onClose();
      } catch (error) {
        // Index.toast.error("Stock already in watchlist");
      }
    },
    []
  );

  // useEffect(() => {
  //   if (index) {
  //     getCurrentTag();
  //   }
  // }, [index]);

  useEffect(() => {
    if (
      !props?.singleChart &&
      drawingChartData &&
      drawingChartData?.token == headerData?.token
    ) {
      setTags(drawingChartData?.tag);
    }
  }, [drawingChartData]);

  useEffect(() => {
    if (!props?.singleChart) {
      timerId.current = setTimeout(() => {
        getCurrentTag();
      }, 500);
    } else {
      // setTags(props?.singleChartTag || "");
    }
    return () => {
      clearTimeout(timerId.current);
    };
  }, [headerData, chartDuration, chartView]);

  //Shortcut Alt + B to buy
  const handleShortcutBuyStock = () => {
    if (activeChart && [""]?.includes(tags)) {
      updateTokenTags("Buy");
      setTags("Buy");
    }
  };

  //Shortcut Alt + s to sell
  const handleShortcutSellStock = () => {
    if (activeChart && [""]?.includes(tags)) {
      updateTokenTags("Sell");
      setTags("Sell");
    }
  };

  //Shortcut Alt + w to watch
  const handleShortcutWatchStock = () => {
    if (activeChart && [""]?.includes(tags)) {
      updateTokenTags("Watch");
      setTags("Watch");
    }
  };

  //Shortcut Alt + r to watch
  const handleShortcutReverseStock = () => {
    if (activeChart && ["Buy", "Sell"]?.includes(tags)) {
      updateTokenTags("Reverse");
      setTags(tags === "Buy" ? "Sell" : "Buy");
    }
  };

  //Shortcut Alt + r to remove watch
  const handleShortcutRemoveStock = () => {
    if (activeChart && ["Watch"]?.includes(tags)) {
      removeStockTag(tags);
      setTags("");
    }
  };

  //Shortcut Alt + e to exit
  const handleShortcutExitStock = () => {
    if (activeChart && ["Buy", "Sell"]?.includes(tags)) {
      // updateTokenTags("Exit");
      removeStockTag(tags);
      setTags("");
    }
  };
  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    handleShortcutBuyStock,
    handleShortcutSellStock,
    handleShortcutWatchStock,
    handleShortcutReverseStock,
    handleShortcutExitStock,
    handleShortcutRemoveStock,
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (headMenuRef.current && !headMenuRef.current.contains(event.target)) {
        setHeadMenu(false);
      }
      if (
        downloadMenuRef.current &&
        !downloadMenuRef.current.contains(event.target)
      ) {
        setDownloadMenu(false);
      }
      if (
        watchListMenuRef.current &&
        !watchListMenuRef.current.contains(event.target)
      ) {
        setWatchListMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headMenuRef, downloadMenuRef, watchListMenuRef]);

  const getColorClassByTag = () => {
    switch (tags.toLowerCase()) {
      case "buy":
        return "green-btn";
      case "sell":
        return "red-btn";
      case "exit":
        return "red-btn";
      case "watch":
        return "blue-btn";
      default:
        return "";
    }
  };

  const handleToggleDrawingTool = () => {
    dispatch(manageDrawingButton({ type: "Modal" }));
    dispatch(manageDrawingToolActiveDeactive({ type: "Modal", status: true }));
  };

  const stockDetails = useMemo(() => {
    let text = "";

    if (headerData?.expiry) {
      text += `Expiry: ${headerData?.expiry}; `;
    }

    if (headerData?.lotsize) {
      text += `Lot size: ${headerData?.lotsize}`;
    }
    return text;
  }, [headerData?.expiry, headerData?.lotsize, isPopupChart]);

  return (
    <>
      {isModalDrawingButtonActive && isDrawingChart && (
        <DrawChartButton
          newChart={drawingChart && drawingChart?.chart}
          token={drawingChart && drawingChart?.token}
          setNewChart={setDrawingChart}
          isDrawingToolOpen={isModalDrawingToolOpen}
          interval={
            Constants.annotationTimeFrames[chartDuration[chartView]?.interval]
          }
          isModalTool={true}
        />
      )}
      <Index.Box
        className={`chart-header-main custom-chart-header ${
          isPopupChart ? "expiry-chart-header" : ""
        }`}
      >
        {isPopupChart && (
          <Index.Box className="expiry-box">
            <Index.Typography className="expiry-text" variant="p" component="p">
              {stockDetails}
            </Index.Typography>
          </Index.Box>
        )}
        <Index.Box
          className={`chart-header-left ${
            gridView?.row == 3 ? "custom-chart-header-left" : ""
          }`}
        >
          <Index.Box
            className={`${
              gridView?.row > 4 && gridView?.column <= 2
                ? "stock-title-box-block"
                : "stock-title-box"
            } `}
          >
            {displaySettings?.StockSymbol === "on" && (
              <Index.Typography
                className="chart-title chart-text"
                variant="p"
                component="p"
              >
                {headerData?.symbol || null}
              </Index.Typography>
            )}
            {displaySettings?.StockName === "on" && (
              <Index.Typography
                className="chart-title chart-text"
                variant="p"
                component="p"
              >
                {/* {headerData?.stock_name || headerData?.name} */}
                {headerData?.stock_name || headerData?.name}
              </Index.Typography>
            )}
          </Index.Box>
          <Index.Box className="stock-trade-price">
            {displaySettings?.cmp === "on" && (
              <Index.Typography
                className={`chart-text ${
                  +closePrice > +lastTradePrice ? "red-color" : "green-color"
                }`}
                variant="p"
                component="p"
              >
                {lastTradePrice || closePrice ? (
                  <>
                    ₹{lastTradePrice || closePrice}{" "}
                    <img
                      className="red-down-aroow"
                      alt="downRedArrow"
                      src={
                        +closePrice > +lastTradePrice
                          ? Index.Svg.downRedArrow
                          : Index.Svg.upGreenArrow
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </Index.Typography>
            )}
            <Index.Typography
              className={`chart-text ${
                +closePrice > +lastTradePrice ? "red-color" : "green-color"
              }`}
              variant="p"
              component="p"
            >
              {displaySettings?.change === "Rs"
                ? !isNaN(changePointValue)
                  ? changePointValue
                  : ""
                : isFinite(changePointPercent)
                ? `(${changePointPercent}%)`
                : ""}
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="chat-header-right-wrapper">
          <Index.Box className="chat-header-right">
            {isDrawingChart && (
              <Index.IconButton
                disableRipple
                className={`tool-icon-btn ${
                  isModalDrawingButtonActive && "active"
                }`}
                onClick={handleToggleDrawingTool}
              >
                <img
                  className="tool-icon"
                  src={Index.Svg.toolBox}
                  alt="tool-icon"
                />
              </Index.IconButton>
            )}
            {tags && (
              <Index.Box className="buy-min-btn-sec custom-tags-btn">
                <Index.RedButton
                  className={`buy-sell-btn ${
                    tags?.toLowerCase() === "buy"
                      ? "green-btn buy-btn"
                      : tags?.toLowerCase() === "sell" ||
                        tags?.toLowerCase() === "exit"
                      ? "red-btn buy-btn"
                      : tags?.toLowerCase() === "watch"
                      ? "blue-btn buy-btn"
                      : "blue-btn buy-btn"
                  }`}
                  btnLabel={tags}
                />
                {Constants.shortInterval[
                  chartDuration[chartView]?.interval
                ] && (
                  <Index.RedButton
                    className={`buy-btn min-btn ${getColorClassByTag()}`}
                    btnLabel={
                      Constants.shortInterval[
                        chartDuration[chartView]?.interval
                      ]
                    }
                  />
                )}
              </Index.Box>
            )}

            {customScripts?.length > 0 ? (
              <Index.Box
                ref={watchListMenuRef}
                className="three-dot round-plus-box"
              >
                <Index.Box
                  className={
                    watchListMenu
                      ? "head-menu watchList-menu active"
                      : "head-menu watchList-menu"
                  }
                >
                  <Index.List className="ul">
                    {customScripts
                      ?.filter((ele) =>
                        wishListData?.some(
                          (list) => list?.watchList !== ele?._id
                        )
                      )
                      ?.map((e) => {
                        return (
                          <Index.ListItem
                            key={e?._id}
                            className={`li`}
                            onClick={() => {
                              let addData = {
                                exch_seg: headerData?.exch_seg || "",
                                name:
                                  headerData?.stock_name ||
                                  headerData?.name ||
                                  "",
                                symbol: headerData?.symbol || "",
                                tick_size: headerData?.tick_size || "",
                                token: JSON.parse(headerData?.token) || "",
                              };
                              handleAddToWatchList(addData, e?._id);
                            }}
                          >
                            <Index.Typography
                              className={`mint-text`}
                              variant="p"
                              component="p"
                            >
                              {e?.name}
                            </Index.Typography>
                          </Index.ListItem>
                        );
                      })}
                  </Index.List>
                </Index.Box>
              </Index.Box>
            ) : (
              ""
            )}
            <Index.Box
              ref={downloadMenuRef}
              className="three-dot download-container"
            >
              <Index.Box
                className={downloadMenu ? "head-menu active" : "head-menu"}
              >
                <Index.List className="ul">
                  {downloadsType?.map((e) => {
                    return (
                      <Index.ListItem
                        key={e.type}
                        className={`li`}
                        onClick={() => {
                          setDownloadMenu((e) => !e);
                          printImage(e.type);
                        }}
                      >
                        <Index.Typography
                          className={`mint-text`}
                          variant="p"
                          component="p"
                        >
                          {e.type}
                        </Index.Typography>
                      </Index.ListItem>
                    );
                  })}
                </Index.List>
              </Index.Box>
            </Index.Box>
            <Index.Box ref={headMenuRef} className="three-dot">
              <img
                className="dots-img custom-dots-img"
                onClick={() => {
                  setDownloadMenu(false);
                  setWatchListMenu(false);
                  setHeadMenu(!headMenu);
                }}
                src={theme === "Light" ? Index.Svg.dots : Index.Svg.dotsWhite}
                alt="dots"
              />
              <Index.Box
                className={`head-menu tag-option ${headMenu ? "active" : ""}`}
              >
                <Index.List className="ul">
                  {allTags
                    ?.filter((ele) => {
                      if (!tags) {
                        return !["exit", "reverse", "remove"]?.includes(
                          ele?.toLowerCase()
                        );
                      } else if (tags?.toLowerCase() === "exit") {
                        return !["watch", "reverse", "remove"]?.includes(
                          ele?.toLowerCase()
                        );
                      } else if (tags?.toLowerCase() === "watch") {
                        return !["exit", "reverse"]?.includes(
                          ele?.toLowerCase()
                        );
                      } else if (tags?.toLowerCase() === "exit") {
                        return !["watch", "remove"]?.includes(
                          ele?.toLowerCase()
                        );
                      } else if (tags?.toLowerCase() === "buy") {
                        return !["sell", "watch", "remove"]?.includes(
                          ele?.toLowerCase()
                        );
                      } else if (tags?.toLowerCase() === "sell") {
                        return !["buy", "watch", "remove"]?.includes(
                          ele?.toLowerCase()
                        );
                      }
                      return ele;
                    })
                    ?.map((e) => {
                      return (
                        <Index.ListItem
                          key={e}
                          className={`${e === tags ? "active" : ""}`}
                          sx={{
                            background: `${
                              e === tags
                                ? `${
                                    tags?.toLowerCase() === "buy"
                                      ? "green !important"
                                      : tags?.toLowerCase() === "sell"
                                      ? "red !important"
                                      : tags?.toLowerCase() === "watch"
                                      ? "blue !important"
                                      : ""
                                  }`
                                : ""
                            }`,
                          }}
                          onClick={() => {
                            if (e === "Reverse") {
                              setTags(tags === "Sell" ? "Buy" : "Sell");
                              updateTokenTags(e);
                            } else if (e === "Remove") {
                              setTags("");
                              removeStockTag("Watch", index);
                            } else if (e === "Exit") {
                              setTags("");
                              removeStockTag(tags, index);
                            } else {
                              setTags(e);
                              updateTokenTags(e);
                            }
                            setHeadMenu((e) => !e);
                          }}
                        >
                          <Index.Typography
                            className={`mint-text`}
                            variant="p"
                            component="p"
                          >
                            {e}
                          </Index.Typography>
                        </Index.ListItem>
                      );
                    })}
                  {selectedScript?.market === "watchList" && (
                    <Index.ListItem
                      className={``}
                      onClick={() => {
                        handleRemoveScriptFromWatchList(
                          headerData?.watchList,
                          headerData?._id
                        );
                        setHeadMenu(false);
                        setDownloadMenu(false);
                      }}
                    >
                      <Index.Typography
                        className={`mint-text`}
                        variant="p"
                        component="p"
                      >
                        RemoveWL
                      </Index.Typography>
                    </Index.ListItem>
                  )}

                  {selectedScript?.market !== "watchList" &&
                    customScripts?.length > 0 && (
                      <Index.ListItem
                        className={``}
                        onClick={() => {
                          setHeadMenu(false);
                          setWatchListMenu((e) => !e);
                          setDownloadMenu(false);
                        }}
                      >
                        <Index.Typography
                          className={`mint-text`}
                          variant="p"
                          component="p"
                        >
                          Watchlist
                        </Index.Typography>
                      </Index.ListItem>
                    )}

                  {chartData?.length > 0 && (
                    <Index.ListItem
                      className={``}
                      onClick={() => {
                        setHeadMenu(false);
                        setWatchListMenu(false);
                        setDownloadMenu((e) => !e);
                      }}
                    >
                      <Index.Typography
                        className={`mint-text`}
                        variant="p"
                        component="p"
                      >
                        Download
                      </Index.Typography>
                    </Index.ListItem>
                  )}
                </Index.List>
              </Index.Box>
            </Index.Box>
            <img
              className="close-img"
              onClick={onClose}
              src={
                theme === "Light" ? Index.Svg.closeDark : Index.Svg.closeWhite
              }
              alt="closeWhite"
            />
          </Index.Box>
          {displaySettings?.LastUpdatedTime == "on" && (
            <Index.Box className="chart-header-time">
              <Index.Typography
                className="chart-text chart-updated-time-text"
                variant="p"
                component="p"
              >
                {updatedDateAndTime ? updatedDateAndTime : "."}
              </Index.Typography>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ChartHeader;
