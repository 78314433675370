import { Menu, MenuItem } from "@mui/material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import CustomAreaChartSingle from "../../../component/custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomCendalStickChartSingle from "../../../component/custom-chart/custom-single-charts/CustomCendalStickChartSingle";
import CustomDashedChartSingle from "../../../component/custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import CustomCandlestickMiniChart from "../../../component/custom-chart/mini-chart/CustomCandlestickMiniChart";
import CustomMiniChart from "../../../component/custom-chart/mini-chart/CustomMiniChart";
import {
  dashboardSortTagAction,
  getWishListData,
  manageDashboardTags,
  manageListSettings,
  manageStocksTagsAction,
} from "../../../redux/user/action";
import { doGet } from "../../../service/UserIndex";
import Index from "../../Index";
import useKeyboardShortcut from "../../../component/common/keyboard-shortcuts/useKeyboardShortcut";
import Constants from "../../../component/common/constants";
import useRowPerPage from "../../../component/hooks/useRowPerPage";
import useCurrentInterval from "../../../component/common/current-interval/useCurrentInterval";
import { useGridApiRef } from "@mui/x-data-grid";
import moment from "moment";
const CustomFooter = ({ loading }) => {
  return (
    <Index.Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      {loading && (
        <>
          {/* <Index.CircularProgress size={20} sx={{ marginRight: "10px" , color:"#5072A7"}} /> */}
          <Index.Typography variant="body2">Loading...</Index.Typography>
        </>
      )}
    </Index.Box>
  );
};

const downloadsType = [
  {
    type: "SVG",
    saveAs: "saveAsSvg",
  },
  {
    type: "PNG",
    saveAs: "saveAsPng",
  },
  {
    type: "JPG",
    saveAs: "saveAsJpg",
  },
  {
    type: "PDF",
    saveAs: "saveAsPdf",
  },
];

const AnalysisWindow = (props) => {
  const location = Index.useLocation();
  const apiRef = useGridApiRef();
  const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse", "Remove"];
  const {
    wishListData,
    wishListDataTemp,
    token,
    currentTag,
    displaySettings,
    miniChartSize,
    pageSetting,
    listSetting,
    chartDuration,
    chartView,
    selectedScript,
    graphType,
    isActiveShortCut,
    isUserLogin,
    twoSideArrowZoom,
    gridView,
    userLoginDetail,
    theme,
    sorting,
  } = useSelector((state) => state.UserReducer);
  const UserAuthtoken = useSelector((state) => state.UserReducer.token);
  const dispatch = useDispatch();
  const chartData = useSelector(
    (state) => state.WebSocketReducer.miniChartData
  );
  const [curr, next, getCurrentInterval] = useCurrentInterval();

  const isHistoricalLoopExecute = useRef(false);
  const dataGridContainerRef = useRef(null);
  const dataGridRef = useRef(null);
  const rowTimerId = useRef(null);
  const scrollEventId = useRef(null);
  const tableScrollTimerId = useRef(null);
  const currentVisibleRows = useRef(new Map());
  const previousVisibleRows = useRef(new Map());
  const abortControllers = useRef([]);
  const [miniChartOpen, setminiChartOpen] = useState(null);
  const [allTagsData, setAllTagsData] = useState([]);
  const [scriptData, setScriptData] = useState([]);
  const [chartLiveData, setChartLiveData] = useState(null);
  const [analysisList, setAnalysisList] = useState([]);
  const [headMenuOpen, setHeadMenuOpen] = useState(false);
  const [watchListMenuOpen, setWatchListMenuOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [dataTableHeight, setDataTableHeight] = useState(0);
  const [activeMiniChartOpen, setActiveMiniChartOpen] = useState();
  const [formattedStockData, setFormattedStockData] = useState([]);
  const [isScrollChange, setIsScrollChange] = useState(0);
  const handleClose = () => {
    setminiChartOpen(false);
  };
  const handleOpen = (params) => {
    getAllHistoryData([params], chartView === "Intraday");
    setminiChartOpen(true);
  };
  const [rowsPerPage] = useRowPerPage(
    dataGridRef,
    dataTableHeight,
    80,
    analysisList
  );
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });
  const [selectionRow, setSelectionRow] = useState(0);
  const miniChartModalRef = useRef(null);

  const [tags, setTag] = React.useState("watch");
  const addRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const handleActionClick = useCallback((params) => {
    setRowData({
      token: JSON.parse(params.token),
      stock_name: params.stockName,
      lastTradePrice: params.lastTradePrice,
    });
    setHeadMenuOpen((prevState) => !prevState);
    setWatchListMenuOpen(false);
  }, []);

  const handleWatchListActionClick = useCallback((params) => {
    setRowData(params);
    setWatchListMenuOpen((prevState) => !prevState);
    setHeadMenuOpen(false);
  }, []);

  const themeTitle = localStorage.getItem("default-theme");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    // bgcolor: "#1E2431",
    bgcolor:
      theme == "Light" ? "#fff" : theme == "Dark" ? "#212121" : "#283142",
    borderRadius: "10px 10px 0 0",
    border: "none",
  };

  useEffect(() => {
    async function fetchData() {
      const response = await doGet("/user/get-notifications", UserAuthtoken);
    }
    fetchData();
  }, []);

  const getStocksTags = async (data) => {
    const tokens = data?.map((item) => JSON.parse(item?.token));
    let bodyData = {
      tokens,
      chartView,
      timeFrame: chartDuration[chartView]?.timeFrame,
      interval: chartDuration[chartView]?.interval,
    };
    try {
      const response = await Index.DataService(token).post(
        Index.Api.user.getMultipleStocksTags,
        bodyData
      );

      if (response?.data?.data) {
        response?.data?.data?.map((item) => {
          setAnalysisList((prevData) =>
            prevData?.map((ele) => {
              if (JSON.parse(ele?.token) === JSON.parse(item?.token)) {
                return {
                  ...ele,
                  tag: item?.tag || "",
                  timeFrame: item?.timeFrame || "",
                };
              } else {
                return ele;
              }
            })
          );
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setAnalysisList(wishListData);
  }, [wishListData, chartDuration, chartView]);

  useEffect(() => {
    if (props.socket && wishListData?.length && chartView === "Intraday") {
      wishListData
        // ?.slice(
        //   (paginationModel?.page + 1) * paginationModel?.pageSize -
        //     paginationModel?.pageSize,
        //   (paginationModel?.page + 1) * paginationModel?.pageSize
        // )
        ?.forEach((token) => {
          props.socket.emit("joinRoom", `token_${JSON.parse(token?.token)}`);

          props.socket.on(`token_${JSON.parse(token?.token)}`, (data) => {
            setChartLiveData(data);
          });
        });
    }
    return () => {
      if (props.socket && wishListData?.length) {
        wishListData?.forEach((token) => {
          props.socket.emit("leaveRoom", `token_${token?.token}`);
        });
      }
      setChartLiveData(null);
    };
  }, [props.socket, wishListData, paginationModel, chartView]);

  let isWeekendDay = [6, 7]?.includes(Index.moment().isoWeekday());
  let {date: prevCloseDate} = usePreviousDateCalculator(isWeekendDay ? 2 : 1);

  const row = useMemo(() => {
    return (
      analysisList?.length &&
      analysisList?.map((item, index, array) => {
        if (
          chartLiveData &&
          JSON.parse(item.token) == JSON.parse(chartLiveData.token)
        ) {
          item["chartLiveData"] = chartLiveData;
          item["livePrice"] = chartLiveData.tick;
          item["lastTradePrice"] = chartLiveData.tick[5];
          item["closePrice"] = chartLiveData.tick[6];
        }

        if (!chartLiveData && item?.stockData && item?.stockData?.length) {
          const lastDateData = item?.stockData?.filter(
            (data) =>
              Index.moment(new Date(data[0])).format("YYYY-MM-DD") ==
              Index.moment(prevCloseDate).format("YYYY-MM-DD")
          );

          let prevClosePrice =
            lastDateData.length > 0
              ? lastDateData[lastDateData.length - 1][4]
              : item?.previousClosePrice;

          item["lastTradePrice"] =
            item?.stockData[item?.stockData?.length - 1][4] || 0;
          item["closePrice"] = prevClosePrice || 0;
        }

        return {
          rowIndex: index - paginationModel?.page * rowsPerPage,
          rowId: index + 1,
          scriptId: item?._id,
          watchListId: item?.watchList,
          tag: item?.tag || "",
          CMP: item?.lastTradePrice,
          chargepoint: `${
            item?.closePrice > item?.lastTradePrice
              ? (item?.lastTradePrice - item?.closePrice).toFixed(2)
              : item?.closePrice !== item?.lastTradePrice
              ? `+${(item?.lastTradePrice - item?.closePrice).toFixed(2)}`
              : 0
          } (${
            item?.lastTradePrice && item?.closePrice
              ? `${Math.abs(
                  (
                    ((item?.lastTradePrice - item?.closePrice) /
                      item?.closePrice) *
                    100
                  ).toFixed(2)
                )}%`
              : "0%"
          })`,
          script: item?.name || item?.stock_name || "",
          exch_seg: item?.exch_seg || "",
          symbol: item?.symbol || "",
          tick_size: item?.tick_size || "",
          timeFrame: item?.timeFrame || "",
          id: index + 1,
          stockData: item?.stockData,
          livePrice: item?.livePrice,
          chartLiveData: item?.chartLiveData,
          miniChart: {
            token: item?.token,
            lastTradePrice: item?.lastTradePrice,
          },
          token: item?.token,
          action: [
            {
              token: item?.token,
              value: Index.Svg.roundPlus,
            },
            {
              token: item?.token,
              stockName: item?.name,
              value: Index.Svg.whiteDots,
            },
          ],
          stockData: item?.stockData ?? [],
          previousClosePrice: item?.previousClosePrice ?? null,
        };
      })
    );
  }, [currentTag, analysisList, chartLiveData]);

  //handle Tag update
  const handleTagUpdate = (tagName, token) => {
    // setStockTag({ tag: tagName, token });
    setAnalysisList((prev) =>
      prev?.map((item) => {
        if (JSON.parse(item?.token) == JSON.parse(token)) {
          return {
            ...item,
            tag: tagName,
            timeFrame:
              Constants.shortInterval[chartDuration[chartView]?.interval],
          };
        }
        return item;
      })
    );
  };

  //Handle add to watchList
  const handleAddToWatchList = useCallback(async (value, watchListId) => {
    try {
      if (value?.token) {
        const response = await Index.DataService(token).post(
          Index.Api.user.addScriptToList,
          {
            ...value,
            watchListId,
          }
        );
        // setStockTagCount(data.data.data);
        // dispatch(getWishListData([...wishListData, value]));
        setWatchListMenuOpen((prev) => !prev);
        setRowId(null);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setHeadMenuOpen(false);
      }
      if (addRef.current && !addRef.current.contains(event.target)) {
        setWatchListMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownMenuRef, addRef]);

  const updateTokenTags = useCallback(
    async (t, rowData) => {
      try {
        const formattedTimestamp = getCurrentInterval(
          +Constants.chartInterval[chartDuration?.[chartView]?.interval]?.value,
          rowData?.exch_seg === "MCX"
        );
        const response = await Index.DataService(UserAuthtoken).post(
          `/user/stock/stockTagAddAndUpdate`,
          {
            tag: t,
            stock_name: rowData?.stock_name || "-",
            previousTag: rowData?.tag,
            script: selectedScript?.script?.toString(),
            lastTradedPrice: rowData?.lastTradePrice,
            target: 12,
            stopLoss: 12,
            entryPrice: rowData?.lastTradePrice,
            // exitPrice: 3,
            token: JSON.parse(rowData?.token),
            tradeType: chartView,
            timeFrame:
              Constants.shortInterval[chartDuration[chartView]?.interval],
            timeStamp: formattedTimestamp,
            chartView,
            positionalTimeFrame:
              chartView === "Positional"
                ? chartDuration[chartView]?.timeFrame
                : null,
            interval: chartDuration[chartView]?.interval,
            intervalValue:
              Constants.intervalsValue[chartDuration[chartView]?.interval],
          }
        );
        dispatch(
          manageDashboardTags(UserAuthtoken, {
            chartView,
            chartDuration,
            wishListDataTemp,
            sorting,
          })
        );
      } catch (error) {
        dispatch(
          manageDashboardTags(UserAuthtoken, {
            chartView,
            chartDuration,
            wishListDataTemp,
            sorting,
          })
        );
        console.log(error, "Sfddsfsdf");
      }
      if (props?.socket) {
        props?.socket.emit("tagging_action", userLoginDetail?._id);
      }
    },
    [analysisList]
  );

  const removeStockTag = useCallback(
    async (t, token) => {
      try {
        let data = {
          token: JSON.parse(token),
          tag: t,
          lastTradedPrice: rowData?.lastTradePrice,
          chartView,
          timeFrame: chartDuration[chartView]?.timeFrame,
          interval: chartDuration[chartView]?.interval,
        };
        const response = await Index.DataService(UserAuthtoken).post(
          Index.Api.user.removeStock,
          data
        );
        dispatch(
          manageDashboardTags(UserAuthtoken, {
            chartView,
            chartDuration,
            wishListDataTemp,
            sorting,
          })
        );
        if (props?.socket) {
          props?.socket.emit("tagging_action", userLoginDetail?._id);
        }
      } catch (error) {}
    },
    [analysisList]
  );

  const getColorClassByTag = (tag) => {
    switch (tag.toLowerCase()) {
      case "buy":
        return "green-buy-btn";
      case "sell":
        return "red-buy-btn";
      case "exit":
        return "red-buy-btn";
      case "watch":
        return "blue-watch-btn";
      default:
        return "";
    }
  };

  const handlePageSetting = (data) => {
    const rowsPerPage = gridView?.row * gridView?.column;
    const countData = Math.ceil(data?.length / rowsPerPage);
    dispatch(pageSetting({ page: 1, count: countData, rowsPerPage }));
  };

  const getWatchListData = async (watchListId) => {
    try {
      const response = await Index.DataService(token)
        .get(Index.Api.user.getWatchListScript + `${watchListId}`)
        .then((e) => {
          dispatch(getWishListData(e.data.data));
          handlePageSetting(e.data.data);
        });
    } catch (error) {}
  };

  //Handle remove script from watchList
  const handleRemoveScriptFromWatchList = useCallback(
    async (watchListId, scriptId, handleWatchListClose) => {
      try {
        const response = await Index.DataService(token).post(
          Index.Api.user.removeScriptFromWatchList,
          {
            watchListId,
            scriptId,
          }
        );
        handleWatchListClose();
        getWatchListData(watchListId);
      } catch (error) {
        // Index.toast.error("Stock already in watchlist");
      }
    },
    []
  );

  const tableWidth = useMemo(() => {
    const element = document.querySelector(".MuiDataGrid-withBorderColor");
    return element ? element.clientWidth : 0; // Use clientWidth instead of innerWidth
  }, [wishListData, window.innerWidth]);

  const columns = [
    {
      field: "id",
      headerName: "Sr.no",
      // width: 90,
      width: tableWidth * 0.1,
      minWidth: 90,
      hideable: false,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "script",
      headerName: "Script",
      // width: 200,
      width: tableWidth * 0.2,
      minWidth: 200,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      // width: 150,
      width: tableWidth * 0.1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params?.value) {
          return (
            <Index.Box className="list-label-main">
              <Index.Button
                className={`${getColorClassByTag(params?.value)} label-btn`}
                disableRipple
              >
                {params?.value}
              </Index.Button>
              <Index.Button
                className={`${getColorClassByTag(params?.value)} timeframe-btn`}
                disableRipple
              >
                {params?.row?.timeFrame}
              </Index.Button>
            </Index.Box>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "CMP",
      headerName: "CMP",
      type: "number",
      // width: 180,
      width: tableWidth * 0.15,
      minWidth: 180,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "miniChart",
      headerName: "Mini Chart",
      type: "number",
      // width: miniChartSize?.width,
      minWidth:
        miniChartSize?.width > tableWidth * 0.15
          ? miniChartSize?.width
          : tableWidth * 0.15 > 200
          ? tableWidth * 0.15
          : 200,
      maxWidth: 600,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Index.Button
          onClick={() => {
            handleOpen(params?.row);
            setActiveMiniChartOpen(params?.value?.token);
          }}
          sx={{ padding: 0 }}
          disableRipple
        >
          {graphType[theme][chartView] === 3 ? (
            <CustomCandlestickMiniChart
              chartData={params?.row?.stockData}
              token={JSON.parse(params?.value?.token)}
            />
          ) : (
            <CustomMiniChart
              chartData={params?.row?.stockData}
              token={JSON.parse(params?.value?.token)}
              chartType={graphType[theme][chartView]}
            />
          )}
        </Index.Button>
      ),
    },
    {
      field: "chargepoint",
      headerName: "Change Points (%)",
      type: "number",
      // width: 230,
      width: tableWidth * 0.15,
      minWidth: 230,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
      renderCell: (params) => {
        return (
          <>
            <Index.Box
              className="MuiDataGrid-cellContent"
              sx={{ color: params?.value?.[0] == "+" ? "#008000" : "red" }}
            >
              {params.value}
            </Index.Box>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      // width: 180,
      width: tableWidth * 0.1,
      minWidth: 180,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DropdownMenuAction
          className="drop-down"
          row={params.row}
          handleTagUpdate={handleTagUpdate}
          removeStockTag={removeStockTag}
          updateTokenTags={updateTokenTags}
          handleAddToWatchList={handleAddToWatchList}
          handleRemoveScriptFromWatchList={handleRemoveScriptFromWatchList}
        />
      ),
    },
  ];

  const {date: fromDate} = usePreviousDateCalculator(
    chartView === "Intraday" ? 1 : chartDuration[chartView]?.timeFrame - 1
  );

  const getAllHistoryData = async (dataLength, live) => {
    const controller = new AbortController();
    abortControllers.current.push(controller);
    const signal = { signal: controller.signal };
    const tokens = dataLength?.map((item) => ({
      token: JSON.parse(item?.token),
      type: item?.exch_seg,
    }));
    const intervalData =
      Constants.chartInterval[chartDuration[chartView]?.interval];
    const minutesToLess = intervalData?.value * 60000;
    const formData = {
      tokens: tokens,
      interval: intervalData?.interval,
      fromDate,
      toDate: Index.moment().format("YYYY-MM-DD"),
      timeFrame:
        Constants.shortPositionalTimeframe[
          chartDuration[chartView]?.timeFrame
        ] ?? "",
      offset: intervalData?.offset,
    };

    const formDataIntraday = {
      tokens: tokens,
      interval: intervalData?.interval,
      fromDate: Index.moment().format("YYYY-MM-DD"),
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset: intervalData?.offset,
      previousDate: prevCloseDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday,
              signal
            ),
          ]
        : [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday,
              signal
            ),
            Index.DataService(token).post(
              Index.Api.user.getAllHistoricalData,
              formData,
              signal
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        token: key,
        data: combinedData[key],
        previousCloseData: previousDateData?.[JSON.parse(key)],
      }));

      if (formattedCombinedData?.length > 0) {
        // setAnalysisList((prevData) =>
        //   prevData?.map((ele) => {
        //     const token = JSON.parse(ele?.token);
        //     const item = formattedCombinedData.find((data) => data[token]);

        //     if (item && item[token]?.length) {
        //       const format = item[token]
        //         ?.slice(1)
        //         ?.map((el) => [
        //           Index.moment(el[0]).startOf("minute").valueOf() -
        //             minutesToLess,
        //           +el[1],
        //           +el[2],
        //           +el[3],
        //           +el[4],
        //           +el[4],
        //         ]);
        //       return {
        //         ...ele,
        //         stockData: format,
        //         previousClosePrice: previousDateData[token],
        //       };
        //     } else {
        //       return ele;
        //     }
        //   })
        // );
        const format = formattedCombinedData.map((el) => {
          const data = el?.data?.map((item) => {
            const timestamp =
              Index.moment(item[0]).startOf("minute").valueOf() - minutesToLess;
            return [
              timestamp,
              +item[1],
              +item[2],
              +item[3],
              +item[4],
              +item[4],
            ];
          });
          setAnalysisList((prevAnalysisList) => {
            const findIndex = prevAnalysisList.findIndex(
              (item) => item?.token == el?.token
            );

            // If the item is not found, return the previous state
            if (findIndex === -1) {
              return prevAnalysisList;
            }

            // Create a new array with the updated item
            return prevAnalysisList?.map((item, index) => {
              if (index === findIndex) {
                return {
                  ...item,
                  stockData: data,
                  previousClosePrice: el?.previousDateData,
                };
              }
              return item; // Return the item unchanged
            });
          });
          return { ...el, data };
        });
      }
    } catch (error) {}
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const callAllHistorical = async (rowData) => {
    const chunkData = _.chunk(rowData, 4);
    for (const item of chunkData) {
      try {
        await getAllHistoryData(item, chartView === "Intraday");
        await getStocksTags(item);
        await delay(200);
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    }
  };

  useEffect(() => {
    // const timeout = setTimeout(() => {
    // callAllHistorical();
    // }, 1000);

    return () => {
      if (abortControllers.current.length > 0) {
        abortControllers.current.forEach((controller) => controller.abort());
        abortControllers.current = [];
      }
      // clearTimeout(timeout);
    };
  }, [wishListData, paginationModel, chartDuration, chartView, isScrollChange]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  const handleKeyDown = (e) => {
    const key = e.key;

    // const startIndex =
    //   (paginationModel?.page + 1) * paginationModel?.pageSize -
    //   paginationModel?.pageSize;
    // // const endIndex = (paginationModel?.page + 1) * paginationModel?.pageSize;
    // const endIndex = row?.length - 1;
    // const rowData = row?.slice(startIndex, endIndex);

    const firstId = row[0];
    const lastId = row[row?.length - 1];

    if (key === "ArrowDown") {
      if (!selectionRow || selectionRow <= 0) {
        setSelectionRow(firstId?.id);
      }
      if (selectionRow && selectionRow < lastId?.id) {
        setSelectionRow((prev) => prev + 1);
      }
    } else if (key === "ArrowUp") {
      if (selectionRow && selectionRow > firstId?.id) {
        setSelectionRow((prev) => prev - 1);
      }
    } else if (key === "ArrowRight") {
      const tableElement = apiRef.current.rootElementRef.current;
      if (tableElement) {
        tableElement.scrollLeft = 50;
        e.preventDefault();
      }
      // if (paginationModel?.page < wishListData?.length / rowsPerPage) {
      //   setSelectionRow(0);
      //   const data = {
      //     ...paginationModel,
      //     page: paginationModel?.page + 1,
      //   };
      //   setPaginationModel(data);
      //   dispatch(manageListSettings(data));
      // }
    } else if (key === "ArrowLeft") {
      const tableElement = apiRef.current.rootElementRef.current;
      if (tableElement) {
        tableElement.scrollLeft -= 50;
        e.preventDefault();
      }
      // if (paginationModel?.page > 0) {
      //   setSelectionRow(0);
      //   const data = {
      //     ...paginationModel,
      //     page: paginationModel?.page - 1,
      //   };
      //   setPaginationModel(data);
      //   dispatch(manageListSettings(data));
      // }
    }

    if (key === "Tab") {
      setSelectionRow(0);
    }
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isActiveShortCut, isUserLogin, selectionRow, paginationModel?.page]);

  const selectedData = useMemo(() => {
    return row?.length > 0
      ? row?.find((item) => selectionRow == item?.id)
      : null;
  }, [selectionRow]);

  //Shortcut Alt + B to buy
  const handleShortcutBuyStock = () => {
    if (selectionRow > 0 && selectedData && !selectedData?.tag) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        exch_seg: selectedData?.exch_seg,
      };
      updateTokenTags("Buy", data);
      handleTagUpdate("Buy", selectedData?.token);
    }
  };

  //Shortcut Alt + s to sell
  const handleShortcutSellStock = () => {
    if (selectionRow > 0 && selectedData && !selectedData?.tag) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        exch_seg: selectedData?.exch_seg,
      };
      updateTokenTags("Sell", data);
      handleTagUpdate("Sell", selectedData?.token);
    }
  };

  //Shortcut Alt + w to watch
  const handleShortcutWatchStock = () => {
    if (selectionRow > 0 && selectedData && !selectedData?.tag) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        exch_seg: selectedData?.exch_seg,
      };
      updateTokenTags("Watch", data);
      handleTagUpdate("Watch", selectedData?.token);
    }
  };

  //Shortcut Alt + r to reverse
  const handleShortcutReverseStock = () => {
    if (
      selectionRow > 0 &&
      selectedData &&
      ["Buy", "Sell"]?.includes(selectedData?.tag)
    ) {
      let data = {
        stock_name: selectedData?.script,
        lastTradePrice: selectedData?.CMP,
        token: selectedData?.token,
        tag: selectedData?.tag,
        exch_seg: selectedData?.exch_seg,
      };
      let tag = selectedData?.tag === "Buy" ? "Sell" : "Buy";
      updateTokenTags("Reverse", data);
      handleTagUpdate(tag, selectedData?.token);
    }
  };

  //Shortcut Alt + e to exit
  const handleShortcutExitStock = () => {
    if (
      selectionRow > 0 &&
      selectedData &&
      ["Buy", "Sell"]?.includes(selectedData?.tag)
    ) {
      removeStockTag(selectedData?.tag, selectedData?.token);
      handleTagUpdate("", selectedData?.token);
    }
  };

  //Shortcut Alt + r to remove watched tag
  const handleShortcutRemoveStock = () => {
    if (selectionRow > 0 && selectedData && selectedData?.tag === "Watch") {
      removeStockTag("Watch", selectedData?.token);
      handleTagUpdate("", selectedData?.token);
    }
  };

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    handleShortcutBuyStock,
    handleShortcutSellStock,
    handleShortcutWatchStock,
    handleShortcutReverseStock,
    handleShortcutExitStock,
    handleShortcutRemoveStock,
  });

  //Data table height

  let mainContentElement = document.querySelector(".main-content-sec");
  useEffect(() => {
    if (mainContentElement) {
      const mainHeight = mainContentElement.clientHeight;
      setDataTableHeight(mainHeight - 25);
    }
  }, [mainContentElement, twoSideArrowZoom]);

  const getVisibleRowsData = () => {
    // Clear current visible rows
    currentVisibleRows.current.clear();

    // Get visible row IDs and populate currentVisibleRows
    const visibleRows = apiRef.current?.getAllRowIds().filter((id) => {
      const rowElement = apiRef.current?.getRowElement(id);
      const isVisible = rowElement && rowElement.offsetParent !== null; // Check if the row is visible
      if (isVisible) {
        currentVisibleRows.current.set(id, id);
      }
      return isVisible;
    });

    // Convert current and previous visible rows to arrays
    const current = [...currentVisibleRows.current.keys()];
    const previous = [...previousVisibleRows.current.keys()];

    // Find unique current row IDs
    const uniqueCurrent =
      previous?.length > 0
        ? current.filter((id) => !previous.includes(id))
        : current;

    // Map visible rows data based on unique current IDs
    if (visibleRows?.length > 0) {
      const visibleRowsData = wishListData
        // ?.filter((item, index) => uniqueCurrent.includes(index + 1))
        // ?.filter((item, index) => current.includes(index + 1))
        ?.filter((item, index) => visibleRows.includes(index + 1))
        ?.map((item) => ({
          token: JSON.parse(item?.token),
          exch_seg: item?.exch_seg,
        }));

      // console.log("current Visible Row IDs:", current);
      // console.log("previous Visible Row IDs:", previous);
      // console.log("Unique Visible Row IDs:", uniqueCurrent);
      // console.log("Latest Visible Row IDs:", visibleRows);
      console.log("Latest Visible Row Data:", visibleRowsData);

      // Update previous visible rows if there are new visible rows
      if (visibleRowsData?.length > 0) {
        // if (previousVisibleRows.current.size !== 0) {
        //   setIsScrollChange(Index.moment().valueOf());
        // }
        callAllHistorical(visibleRowsData); // Uncomment if needed
      }
      previousVisibleRows.current.clear();
      current.forEach((ele) => {
        previousVisibleRows.current.set(ele, ele);
      });
    }
  };

  const handleScroll = (event) => {
    if (tableScrollTimerId.current) {
      clearTimeout(tableScrollTimerId.current);
    }

    tableScrollTimerId.current = setTimeout(() => {
      getVisibleRowsData();
      tableScrollTimerId.current = null;
    }, 500);
  };

  useEffect(() => {
    console.log("apiRef==", apiRef.current);
    // console.log(apiRef.current.rootElementRef.current, "api-gridElement");
    let scrollContainer;
    const gridElement = apiRef.current.rootElementRef.current;
    if (scrollEventId.current) {
      clearTimeout(scrollEventId.current);
    }

    scrollEventId.current = setTimeout(() => {
      if (gridElement) {
        console.log(gridElement, "gridElement");
        scrollContainer = gridElement.querySelector(
          ".MuiDataGrid-virtualScroller"
        );
        if (scrollContainer) {
          getVisibleRowsData();
          scrollContainer.addEventListener("scroll", handleScroll);
        }
      }
    }, 500);

    // Cleanup the event listener on component unmount
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [
    apiRef.current,
    dataGridRef.current,
    wishListData,
    chartDuration,
    chartView,
  ]);

  return (
    <>
      <Index.Box className="dashboard-wrapper">
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          {/* <Index.PrimaryButton
            className="primary-btn index-btn"
            btnLabel="Index 1"
          ></Index.PrimaryButton> */}
          <Index.Box
            className={`table-sec  ${
              row?.length < 10 ? "transaction-table" : ""
            }`}
          >
            {/* {!loader ? (
              row && row.length > 0 ? ( */}
            <div
              style={{
                width: "100%",
                height: dataTableHeight,
                // minHeight: `${dataTableHeight}px`, // Ensure the height is set correctly
                overflowY: "auto",
              }}
              // sx={{ width: "100%", height: dataTableHeight }}
              className="analysis-table analysis-window-grid-main analysis-table-cus"
            >
              {/* {/ <div  onScrollCapture={handleScroll}> /} */}
              <Index.DataGrid
                apiRef={apiRef}
                ref={dataGridRef}
                rows={loader ? [] : row}
                columns={columns}
                hideFooterPagination
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  if (newRowSelectionModel?.length > 0) {
                    const firstSelectedRowId = newRowSelectionModel[0];
                    setSelectionRow(firstSelectedRowId);
                    apiRef.current.scrollToIndexes({
                      rowIndex: firstSelectedRowId,
                    });
                  }
                }}
                rowSelectionModel={selectionRow > 0 ? [selectionRow] : []}
                rowSelection
                components={{
                  NoRowsOverlay: () => <Index.NoRowsOverlay loader={loader} />,
                }}
                localeText={{
                  footerRowSelected: () => null,
                  MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count, estimated }) => {
                      if (!estimated) {
                        return `${from} – ${to} of ${
                          count !== -1 ? count : `${to}`
                        }`;
                      }
                      return `${from} – ${to} of ${
                        count !== -1
                          ? count
                          : `${estimated > to ? estimated : to}`
                      }`;
                    },
                  },
                }}
                rowHeight={80}
                sx={{
                  "& .MuiDataGrid-cell[data-field='miniChart']": {
                    padding: 0,
                  },
                }}
              />
            </div>
            {/* ) : (
                <Index.Box
                  sx={{ textAlign: "center", padding: "20px" }}
                  className="text-data"
                >
                  No data found
                </Index.Box>
              )
            ) : (
              <Index.TableLoader />
            )} */}
          </Index.Box>
        </Index.Box>

        {miniChartOpen && (
          <>
            {" "}
            <Index.Box>
              <Index.Modal
                open={miniChartOpen}
                onClose={() => {
                  handleClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                ref={miniChartModalRef}
              >
                <Index.Box sx={style} className="mini-chart-modal-box">
                  <Index.Box className="modal-header-sec custom-modal-header">
                    <Index.ChartHeader
                      index={JSON.parse(activeMiniChartOpen)}
                      tags={
                        analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.tag
                      }
                      setTags={setTag}
                      allTags={allTags}
                      headerData={{
                        ...analysisList?.find(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        ),
                        stock_name: analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.name,
                        stockData: analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.stockData,
                      }}
                      chartData={
                        analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.stockData
                      }
                      data={analysisList?.filter(
                        (data) => data?.token == JSON.parse(activeMiniChartOpen)
                      )}
                      onClose={() => {
                        handleClose();
                      }}
                      handleTagUpdate={handleTagUpdate}
                      downloadsType={downloadsType}
                      singleChart={true}
                      singleChartTag={
                        analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.tag
                      }
                      fromDate={fromDate}
                      live={chartView === "Intraday"}
                      previousClosePrice={
                        analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.previousClosePrice
                      }
                      chartLiveData={
                        analysisList?.filter(
                          (data) =>
                            data?.token == JSON.parse(activeMiniChartOpen)
                        )?.[0]?.chartLiveData
                      }
                      miniChartModalRef={miniChartModalRef}
                      socket={props?.socket}
                    ></Index.ChartHeader>
                  </Index.Box>
                  <Index.Box className="modal-body-sec">
                    {graphType[theme][chartView] === 0 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomLineChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {graphType[theme][chartView] === 1 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomAreaChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {graphType[theme][chartView] === 2 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomDashedChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                    {graphType[theme][chartView] === 3 && (
                      <Index.Box className="chart-body custom-chart-body">
                        <CustomCendalStickChartSingle
                          height={window.innerWidth < 768 ? 300 : 500}
                          width={"100%"}
                          liveData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          stockData={
                            analysisList?.filter(
                              (data) =>
                                data?.token == JSON.parse(activeMiniChartOpen)
                            )?.[0]?.stockData
                          }
                          index={activeMiniChartOpen}
                          stockToken={activeMiniChartOpen}
                          chartLiveData={
                            chartLiveData?.token &&
                            JSON.parse(chartLiveData?.token) ==
                              JSON.parse(activeMiniChartOpen) &&
                            chartLiveData?.tick
                          }
                          live={chartView === "Intraday"}
                          isModalChart={true}
                        />
                      </Index.Box>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Modal>
            </Index.Box>{" "}
          </>
        )}
      </Index.Box>
    </>
  );
};

export default memo(AnalysisWindow);

const DropdownMenuAction = ({
  row,
  handleTagUpdate,
  removeStockTag,
  updateTokenTags,
  handleAddToWatchList,
  handleRemoveScriptFromWatchList,
}) => {
  const { id, tag, token, CMP, script, exch_seg, tick_size, symbol } = row;
  const { customScripts, selectedScript } = Index.useSelector(
    (state) => state.UserReducer
  );
  const [allTags, setAllTags] = useState([
    "Exit",
    "Watch",
    "Buy",
    "Sell",
    "Remove",
    "Reverse",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [watchListAnchorEl, setWatchListAnchorEl] = useState(null);

  //Tag menu controllers
  const handleTagMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTagMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTagMenuActionClick = (item, tag, token) => {
    let rowData = {
      stock_name: script,
      lastTradePrice: CMP,
      token: token,
      tag: tag,
      exch_seg,
    };
    if (item === "Reverse") {
      updateTokenTags("Reverse", rowData);
      handleTagUpdate &&
        handleTagUpdate(tag === "Sell" ? "Buy" : "Sell", token);
    } else if (item === "Remove") {
      removeStockTag("Watch", token);
      handleTagUpdate && handleTagUpdate("", token);
    } else if (item === "Exit") {
      removeStockTag(tag, token);
      handleTagUpdate && handleTagUpdate("", token);
    } else {
      updateTokenTags(item, rowData);
      handleTagUpdate && handleTagUpdate(item, token);
    }
    handleTagMenuClose();
  };

  const handleFilterTag = (item) => {
    if (!tag) {
      return ["Watch", "Buy", "Sell"].includes(item);
    }

    if (tag?.toLowerCase() === "watch") {
      return ["Watch", "Buy", "Sell", "Remove"].includes(item);
    }

    if (tag?.toLowerCase() === "buy") {
      return ["Exit", "Buy", "Reverse"].includes(item);
    }

    if (tag?.toLowerCase() === "sell") {
      return ["Exit", "Sell", "Reverse"].includes(item);
    }

    if (tag?.toLowerCase() === "exit") {
      return ["Exit", "Buy", "Sell"].includes(item);
    }
  };

  //WatchList controllers
  const handleWatchListClick = (event) => {
    setWatchListAnchorEl(event.currentTarget);
  };

  const handleWatchListClose = () => {
    setWatchListAnchorEl(null);
  };

  const handleWatchListActionClick = (data, id) => {
    handleAddToWatchList(data, id);
    handleWatchListClose();
  };

  return (
    <Index.Box className="my-Menu-Component">
      <Index.Box className="d-flex">
        {customScripts?.length > 0 && (
          <button
            className="table-gray-btn menu-three-dot-btn"
            onClick={handleWatchListClick}
          >
            <img
              className="action-btn-img"
              alt="add-action-icon"
              src={Index.Svg.roundPlus}
            />
          </button>
        )}
        <button
          className="table-gray-btn menu-three-dot-btn"
          onClick={handleTagMenuClick}
        >
          <img
            className="action-btn-img"
            alt="add-action-icon"
            src={Index.Svg.whiteDots}
          />
        </button>
      </Index.Box>
      <Index.Box className="menu-list-main">
        {/* WatchList Menu Dropdown */}
        <Menu
          id="simple-menu2"
          className="watchlist-menu analysis-menu"
          anchorEl={watchListAnchorEl}
          keepMounted
          open={Boolean(watchListAnchorEl)}
          onClose={handleWatchListClose}
          classes={{ paper: "custom-script-menu-scrollbar" }}
        >
          {selectedScript?.market === "watchList" && (
            <MenuItem
              key={`scripts`}
              className="remove-watchlist-bg"
              onClick={() =>
                handleRemoveScriptFromWatchList(
                  row?.watchListId,
                  row?.scriptId,
                  handleWatchListClose
                )
              }
            >
              RemoveWL
            </MenuItem>
          )}
          {customScripts?.length ? (
            customScripts?.map((item, index) => (
              <MenuItem
                key={`scripts-${index}`}
                className={`${tag === item?.name && "li-active"}`}
                onClick={() => {
                  let addData = {
                    exch_seg: exch_seg || "",
                    name: script || "",
                    symbol: symbol || "",
                    tick_size: tick_size || "",
                    token: JSON.parse(token) || "",
                  };
                  handleWatchListActionClick(addData, item?._id);
                }}
              >
                {item?.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem className="">No Data</MenuItem>
          )}
        </Menu>

        {/* Tag Menu Dropdown */}
        <Menu
          id="simple-menu"
          className="tag-menu analysis-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleTagMenuClose}
        >
          {allTags.filter(handleFilterTag).map((item) => (
            <MenuItem
              key={`${item}`}
              className={`${tag === item && "li-active"}`}
              onClick={() => handleTagMenuActionClick(item, tag, token)}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </Index.Box>
    </Index.Box>
  );
};
