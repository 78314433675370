import Index from "../Index";
import React, { useRef, useState } from "react";
import Modal from "../modal/Modal";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  GetGraphStyleAction,
  UserLogoutAction,
  activeShortCut,
  getWishListData,
  manageCategoryFilter,
  manageDefaultTheme,
  manageDownloadChartData,
  manageExitAllModal,
  manageModalParameter,
  manageNotificationCount,
  manageOtherSetting,
  manageSidebarVisibility,
  manageTwoSideArrowZoom,
  manageUserSetting,
  showDefaultSettingModal,
  showHeaderSearchBar,
} from "../../redux/user/action";
import { ThemeContext } from "../../theme/ThemeContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import usePreviousDateCalculator from "../common/previous-date-calculator/usePreviousDateCalculator";
import CustomLineChartSingle from "../custom-chart/customLineChartSingle/CustomLineChartSingle";
import ChartHeader from "../user/chartheader/ChartHeader";
import CustomAreaChartSingle from "../custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomDashedChartSingle from "../custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomCendalStickChartSingle from "../custom-chart/custom-single-charts/CustomCendalStickChartSingle";
import useKeyboardShortcut from "../common/keyboard-shortcuts/useKeyboardShortcut";
import useNotification from "../hooks/useNotification";
import Constants from "../common/constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const allStockTypes = [
  { id: 1, name: "Equity", value: "NSE" },
  { id: 2, name: "F&O", value: "NFO" },
  { id: 3, name: "Commodity", value: "MCX" },
];

const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse", "Remove"];

const Header = (props) => {
  const themeTitle = localStorage.getItem("default-theme");
  const location = Index.useLocation();
  const {
    showHeaderSearch,
    openDefaultSettingModal,
    displaySettings,
    categoryFilter,
    chartView,
    chartDuration,
    taggedStocksCount,
    graphType,
    twoSideArrowZoom,
    pageSetting,
    customSorting,
    userLoginDetail,
    GraphStyle,
    trackingWindowStocks,
    notificationCount,
    theme,
  } = useSelector((state) => state.UserReducer);
  const userType = Index.useSelector(
    (state) => state.UserReducer.userLoginDetail?.userType
  );
  const position = Index.useSelector((state) => state.UserReducer.position);
  const headMenuRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const modalRef = useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor:
      theme == "Light" ? "#ffffff" : theme == "Dark" ? "#212121" : "#283142",
    borderRadius: "10px 10px 0 0",
    border: "none",
    outline: "none",
  };

  const notification = useNotification({
    socket: props?.socket,
    userId: userLoginDetail?._id,
  });

  useEffect(() => {
    if (props?.socket) {
      props?.socket.on(`test_${userLoginDetail?._id}`, (data) => {});
    }
  }, [props?.socket]);

  const miniChartModalRef = useRef(null);
  const [recentIndex, setrecentIndex] = useState(false);
  const [editShortcut, seteditShortcut] = useState(false);
  const [replaceShortcut, setreplaceShortcut] = useState(false);
  const [alert, setAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [reportModal, setreportModal] = useState(false);
  const [downloadChart, setdownloadChart] = useState(false);
  const [autoSquareModal, setAutoSquareModal] = useState(false);
  const [themeActive, setThemeActive] = useState(false);
  const [stockTagCount, setStockTagCount] = useState();
  const [opacity, setOpacity] = useState(1);
  const [symbollist, setSymbollist] = useState([]);
  const [input, setInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [searchStock, setSearchStock] = React.useState("");
  const [openNotificationModal, setNotificationModalOpen] = useState(false);
  const [isNotificationData, setIsNotificationData] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const [openAutoCompleteSuggestions, setAutoCompleteSuggestions] =
    useState(false);
  const [singleChartOpen, setSingleChartOpen] = useState(null);
  const [singleChartData, setSingleChartData] = useState(null);
  const [tags, setTag] = useState("watch");
  const [showTools, setShowTools] = useState(false);
  const [openSearchCollapse, setOpenSearchCollapse] = useState(false);
  const [chartLiveData, setChartLiveData] = useState(null);
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { token, wishListData, selectedScript, otherSettings } =
    Index.useSelector((state) => state.UserReducer);
  const showSideBar = Index.useSelector(
    (state) => state.UserReducer.showSideBar
  );
  const { pathname } = useLocation();
  const handleClick = () => {
    setOpen(!open);
  };

  const {date: fromDate} = usePreviousDateCalculator(
    chartView === "Intraday" ? 0 : chartDuration[chartView]?.timeFrame - 1
  );

  const {date: previousDate} = usePreviousDateCalculator(1);

  useEffect(() => {
    if (singleChartData && chartView === "Intraday" && props.socket) {
      props.socket.emit(
        "joinRoom",
        `token_${JSON.parse(singleChartData?.token)}`
      );

      props.socket.on(`token_${JSON.parse(singleChartData?.token)}`, (data) => {
        setChartLiveData(data);
      });

      return () => {
        props.socket.emit("leaveRoom", `token_${singleChartData?.token}`);
        setChartLiveData(null);
      };
    }
  }, [props.socket, chartView, singleChartData]);
  const handleChartOpen = (data) => {
    setSingleChartOpen(data);
  };
  const handleChartClose = () => {
    setSingleChartOpen(null);
    setSingleChartData(null);
  };

  const { setTheme } = useContext(ThemeContext);
  const downloadMenuRef = useRef(null);
  const [downloadMenu, setDownloadMenu] = useState(false);

  const handleOpenAutoCompleteSuggestions = (list = []) => {
    if (list?.length > 0) {
      setAutoCompleteSuggestions(true);
    }
  };

  const handleCloseAutoCompleteSuggestions = () => {
    setAutoCompleteSuggestions(false);
    setSymbollist([]);
  };

  const handleThemeChange = (title) => {
    setTheme(title);
    localStorage.setItem("default-theme", title);
    sessionStorage.setItem("default-theme", title);
    // dispatch(GetGraphStyleAction(GraphStyle));
    // dispatch(
    //   manageOtherSetting(otherSettings)
    // );
    dispatch(manageDefaultTheme(title));
    dispatch(manageUserSetting(token, "theme", title));
  };
  const handleThemeButton = [
    { title: "Light", icon: Index.Svg.lightIc },
    { title: "Medium", icon: Index.Svg.mediumIc },
    { title: "Dark", icon: Index.Svg.darkIc },
  ];
  const handleLogout = () => {
    // Index.toast.success(
    //   `${userType === "admin" ? "Admin" : "User"} logout successfully`
    // );
    dispatch(UserLogoutAction(navigate));
  };

  //Show hide B/S/W count indicator
  let showBuySellWatchCount =
    document.getElementsByClassName("tracking-window")[0];

  //Hide show markets button
  let showMarketButton = document.getElementsByClassName("tracking-window")[0];

  const getSymbollistData = (value) => {
    setLoader(true);
    const params =
      selectedScript?.market !== "watchList"
        ? { market: selectedScript?.market }
        : {};
    Index.DataService(token)
      .get(Index.Api.user.getSymbollist + value, { params })
      .then((response) => {
        setLoader(false);
        setSymbollist(response.data.data);
        handleOpenAutoCompleteSuggestions(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleSearch = async (e, value) => {
    setSearchStock(value?.token);
  };

  const handleTitleSelected = async (value) => {
    getAllHistoryData(value, chartView === "Intraday");
    setSearchStock(value?.token);
  };
  const handleAddToWatchList = async (value) => {
    getAllHistoryData(value, chartView === "Intraday");
    if (value?.token) {
      let wishList = await Index.DataService(token)
        .get(Index.Api.user.getUserWatchList)
        .then((e) => {
          return e?.data?.data?.filter((data) => {
            if (data?.name == selectedScript?.name) {
              return data;
            }
          });
        });

      Index.DataService(token)
        .post(Index.Api.user.addScriptToList, {
          ...value,
          watchListId: wishList?.[0]?._id,
        })
        .then((data) => {
          if (data.data.status === 200) {
            setStockTagCount(data.data.data);
          }
        });
      dispatch(getWishListData([...wishListData, value]));
    }
  };

  const handleRemoveFormWatchList = async (value) => {
    if (value?.token) {
      const updatedData = wishListData?.filter(
        (ele) => ele?.token !== value?.token
      );

      let wishList = await Index.DataService(token)
        .get(Index.Api.user.getUserWatchList)
        .then((e) => {
          return e?.data?.data?.filter((data) => {
            if (data?.name == selectedScript?.name) {
              return data;
            }
          });
        });
      Index.DataService(token)
        .post(Index.Api.user.removeScriptToList, {
          token: parseInt(value?.token),
        })
        .then((data) => {
          if (data.data.status === 200) {
            setStockTagCount(data.data.data);
          }
        });
      dispatch(getWishListData(updatedData));
    }
  };

  const handleFullScreen = () => {
    var elem = document.getElementById("root");

    function openFullscreen() {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }

    function closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  };

  const downloadsType = [
    {
      type: "SVG",
      saveAs: "saveAsSvg",
    },
    {
      type: "PNG",
      saveAs: "saveAsPng",
    },
    {
      type: "JPG",
      saveAs: "saveAsJpg",
    },
    {
      type: "PDF",
      saveAs: "saveAsPdf",
    },
  ];

  const printImage = async (ext) => {
    const zip = new JSZip();
    const images = [];
    const filter = (node) => {
      const exclusionClasses = ["anychart-credits"];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };
    const stockData =
      location?.pathname === "/user/dashboard"
        ? wishListData
        : trackingWindowStocks;
    const isTrackingWindow =
      location?.pathname === "/user/tracking-window-charts";
    let printId;

    if (ext?.toLowerCase() === "pdf") {
      setIsDownloading(true);
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "a4",
      });
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      let currentPageHeight = 0;

      for (let i = 0; i < stockData.length; i++) {
        if (isTrackingWindow) {
          dispatch(manageDownloadChartData([stockData[i]?._id]));
          printId = `print-${JSON.parse(stockData[i]?.token)}-${
            stockData[i]?._id
          }`;
        } else {
          dispatch(manageDownloadChartData([stockData[i]?.token]));
          printId = `print-${JSON.parse(stockData[i]?.token)}`;
        }
        const element = document.getElementById(printId);
        if (element) {
          const dataUrl = await htmlToImage.toJpeg(element, { filter: filter });
          const stockList = [...stockData];
          const currentStocks = stockList?.slice(
            pageSetting?.page * pageSetting?.rowsPerPage -
              pageSetting?.rowsPerPage,
            pageSetting?.page * pageSetting?.rowsPerPage
          );
          setDownloadingProgress((prevProgress) =>
            prevProgress >= 100 ? 0 : prevProgress + 100 / currentStocks?.length
          );
          const imgProps = doc.getImageProperties(dataUrl);
          const imgWidth = pageWidth;
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

          if (currentPageHeight + imgHeight > pageHeight) {
            doc.addPage();
            currentPageHeight = 0;
          }

          doc.addImage(
            dataUrl,
            "JPEG",
            0,
            currentPageHeight,
            imgWidth,
            imgHeight
          );
          currentPageHeight += imgHeight + 0.8;
        }
      }

      doc.save("demo.pdf");
      dispatch(manageDownloadChartData([]));
      setDownloadingProgress(0);
      setIsDownloading(false);
    } else {
      setIsDownloading(true);
      let toImageFn;
      switch (ext?.toLowerCase()) {
        case "svg":
          toImageFn = htmlToImage.toSvg;
          break;
        case "png":
          toImageFn = htmlToImage.toPng;
          break;
        case "jpg":
          toImageFn = htmlToImage.toJpeg;
          break;
        default:
          console.error("Invalid file format");
          return;
      }

      for (let i = 0; i < stockData.length; i++) {
        if (isTrackingWindow) {
          dispatch(manageDownloadChartData([stockData[i]?._id]));
          printId = `print-${JSON.parse(stockData[i]?.token)}-${
            stockData[i]?._id
          }`;
        } else {
          dispatch(manageDownloadChartData([stockData[i]?.token]));
          printId = `print-${JSON.parse(stockData[i]?.token)}`;
        }

        const element = document.getElementById(printId);

        const fileName = `${
          stockData[i]?.stock_name || stockData[i]?.name
        }-${JSON.parse(stockData[i]?.token)}.${ext?.toLowerCase()}`;
        if (element) {
          const originalOverflow = element.style.overflow;
          const originalHeight = element.style.height;
          element.style.overflow = "visible";
          element.style.height = "auto";
          element.style.width = "auto";
          await new Promise((resolve) => setTimeout(resolve, 500));
          const dataUrl = await toImageFn(element, {
            filter: filter,

            width: element.scrollWidth,
            height: element.scrollHeight,
          });
          element.style.overflow = originalOverflow;
          element.style.height = originalHeight;
          if (ext?.toLowerCase() === "svg") {
            // For SVG, we can directly add the data URL to the ZIP
            let svgData = dataUrl.split(",")[1];
            if (!svgData) {
              continue;
            }
            svgData = decodeURIComponent(svgData);

            zip.file(fileName, svgData, { base64: false });
          } else {
            images.push({ dataUrl, fileName });
          }

          // images.push({ dataUrl, fileName });
          const stockList = [...stockData];
          const currentStocks = stockList?.slice(
            pageSetting?.page * pageSetting?.rowsPerPage -
              pageSetting?.rowsPerPage,
            pageSetting?.page * pageSetting?.rowsPerPage
          );
          setDownloadingProgress((prevProgress) =>
            prevProgress >= 100 ? 0 : prevProgress + 100 / currentStocks?.length
          );
          // Download image
          // const link = document.createElement("a");
          // link.download = fileName;
          // link.href = dataUrl;
          // link.click();
        }
      }
      for (const image of images) {
        const { dataUrl, fileName } = image;
        const imgData = dataUrl.split(",")[1];
        zip.file(fileName, imgData, { base64: true });
      }
      await zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "images.zip");
      });

      dispatch(manageDownloadChartData([]));
      setTimeout(() => {
        setDownloadingProgress(0);
        setIsDownloading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        downloadMenuRef.current &&
        !downloadMenuRef.current.contains(event.target)
      ) {
        setDownloadMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [downloadMenuRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (headMenuRef.current && !headMenuRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headMenuRef]);

  useEffect(() => {
    let params = {
      chartView,
    };
    Index.DataService(token)
      .get(Index.Api.user.stockTagCounts, { params })
      .then((data) => {
        if (data.data.status === 200) {
          setStockTagCount(data.data.data);
        }
      });
  }, [chartView, chartDuration]);

  const getCurrentTag = (stockToken) => {
    const params = {
      chartView,
      timeFrame: chartDuration[chartView]?.timeFrame,
      interval: chartDuration[chartView]?.interval,
    };
    Index.DataService(token)
      .get(`/user/stocks-single-tag?token=${stockToken}`, { params })
      .then(({ data }) => {
        setTag(data?.data?.tag || "");
        setSingleChartData((prev) => ({
          ...prev,
          tag: data?.data?.tag || "",
        }));
      });
  };

  const getAllHistoryData = async (value, live) => {
    const minutesToLess =
      Constants.chartInterval[chartDuration[chartView]?.interval]?.value *
      60000;
    const formData = {
      tokens: [{ token: value?.token, type: value?.exch_seg }],
      interval:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.interval,
      fromDate,
      toDate: Index.moment().format("YYYY-MM-DD"),
      timeFrame:
        Constants.shortPositionalTimeframe[chartDuration[chartView]?.timeFrame] ?? '' ,
      offset:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.offset,
    };

    const formDataIntraday = {
      tokens: [{ token: value?.token, type: value?.exch_seg }],
      interval:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.interval,
      fromDate: Index.moment().format("YYYY-MM-DD"),
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset:
        Constants.chartInterval[chartDuration[chartView]?.interval]?.offset,
      previousDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
          ]
        : [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
            Index.DataService(token).post(
              Index.Api.user.getAllHistoricalData,
              formData
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (formattedCombinedData.length) {
        getCurrentTag(JSON.parse(value?.token));
        let format = formattedCombinedData?.[0]?.[value?.token]?.map((el) => [
          Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess,
          +el[1],
          +el[2],
          +el[3],
          +el[4],
          +el[4],
        ]);

        if (format?.length) {
          setSingleChartData({
            ...value,
            stockData: format,
            previousClosePrice: previousDateData[value?.token] || 0,
          });
          handleChartOpen(+value?.token);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setShowTools(true);
      } else {
        setShowTools(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleMouseMove = (e, xAxis, yAxis) => {
    dispatch(
      manageModalParameter({ x: e.clientX - xAxis, y: e.clientY + yAxis })
    );
  };

  const handleElementClick = (e, xAxis, yAxis) => {
    handleMouseMove(e, xAxis, yAxis);
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    const handleMouseDown = (e) => {
      handleMouseMove(e, 600, 40);
    };
    if (modalElement) {
      modalElement.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [modalRef]);

  useEffect(() => {
    const dashboardElement = document.querySelector(".dashboard-wrapper");
    const mainSecElement = document.querySelector(".main-content-sec");
    const footerElement = document.querySelector(".footer-sec");
    if (twoSideArrowZoom) {
      if (dashboardElement) {
        dashboardElement.style.paddingTop = "0px";
      }

      if (footerElement) {
        footerElement.style.right = "5px";
      }

      if (mainSecElement) {
        mainSecElement.style.minHeight = "100vh";
      }
    } else {
      if (dashboardElement) {
        dashboardElement.style.paddingTop = "45px";
      }

      if (footerElement) {
        footerElement.style.right = "50px";
      }

      if (mainSecElement) {
        mainSecElement.style.minHeight = `calc(${100}vh - ${45}px)`;
      }
    }
  }, [twoSideArrowZoom, displaySettings?.UtilityBar]);

  //Shortcut e
  const handleHideShowHeaderSidebar = (status) => {
    dispatch(manageTwoSideArrowZoom(status));
  };

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    handleHideShowHeaderSidebar,
  });

  //Change List View to Grid View
  const handleViewChange = (view) => {
    const currentPath = location?.pathname;
    const dashboard = "/user/dashboard";
    const analysisList = "/user/analysis-window";
    const trackingCharts = "/user/tracking-window-charts";
    const trackingList = "/user/tracking-window";
    let path;

    if (view === 0) {
      path =
        currentPath === dashboard
          ? analysisList
          : currentPath === trackingCharts
          ? trackingList
          : currentPath === trackingList
          ? currentPath
          : analysisList;
    }
    if (view === 1) {
      path =
        currentPath === analysisList
          ? dashboard
          : currentPath === trackingList
          ? trackingCharts
          : dashboard;
    }

    navigate(path);
  };

  //Change List View to Grid View in Mobile
  const handleMobileViewChange = (view) => {
    const currentPath = location?.pathname;
    const dashboard = "/user/dashboard";
    const analysisList = "/user/analysis-window";
    const trackingCharts = "/user/tracking-window-charts";
    const trackingList = "/user/tracking-window";
    let path;

    if (view === 0) {
      path =
        currentPath === dashboard
          ? analysisList
          : currentPath === trackingCharts
          ? trackingList
          : currentPath === trackingList
          ? currentPath
          : analysisList;
    }
    if (view === 1) {
      path =
        currentPath === analysisList
          ? dashboard
          : currentPath === trackingList
          ? trackingCharts
          : dashboard;
    }

    navigate(path);
  };

  const getUserNotificationCount = async () => {
    try {
      const response = await Index.DataService(token).get(
        Index.Api.user.seenNotification
      );
      dispatch(manageNotificationCount(response?.data?.data?.totalCount));
    } catch (error) {}
  };

  useEffect(() => {
    getUserNotificationCount();
  }, []);

  useEffect(() => {
    if (props?.socket) {
      props?.socket.on(
        `receive_notification_${userLoginDetail?._id}`,
        (data) => {
          if (data) {
            getUserNotificationCount();
          }
        }
      );
      return () => {
        props?.socket.off(`receive_notification_${userLoginDetail?._id}`);
      };
    }
  }, []);

  return (
    <>
      <Index.Box
        className={`header-wrapper ${twoSideArrowZoom && "hide-main-header"}`}
      >
        <Index.Box className="header-left">
          <Index.Box className="report-chart-btn-sec-main">
            <Index.Box
              className="back-btn-main"
              onClick={() => navigate("/user/dashboard")}
            >
              <img
                className="report-back-img"
                alt="back"
                src={Index.Svg.leftRoundArrow}
              />
            </Index.Box>
            <Index.Box className="report-chart-btn-sec custom-report-chart">
              <Index.PrimaryButton
                className={`primary-btn chart-repot-btn repot-btn-cus bg-gray 
  ${reportModal ? "report-btn-active-cus" : "chart-btn-fix"} ||   ${
                  downloadChart
                    ? "chart-btn-cus report-btn-active-text"
                    : "chart-btn-fix"
                } `}
                btnLabel="Report"
              />
              {/* <Index.PrimaryButton
                className={`primary-btn chart-repot-btn chart-btn-cus bg-gray ${
                  downloadChart ? "chart-btn-active" : "chart-btn-fix"
                } || ${
                  reportModal
                    ? "repot-btn-cus repot-btn-text-active"
                    : "chart-btn-fix"
                } `}
                btnLabel="Chart"
                onClick={(e) => {
                  handleElementClick(e, 100, 40);
                  setdownloadChart(!downloadChart);
                }}
              /> */}
            </Index.Box>
          </Index.Box>
          {/* desktop list and grid view */}
          <Index.Box className="grid-list-btn-sec custom-grid-btn desktop-view-chart">
            <Index.Tooltip title="List View" arrow>
              <Index.Box className="analysis-list-icon-main">
                <Index.Button
                  className={`analysis-window-btn ${
                    ["/user/tracking-window", "/user/analysis-window"].includes(
                      location?.pathname
                    ) && "active"
                  }`}
                  onClick={() => handleViewChange(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_204"
                          data-name="Rectangle 204"
                          width="20"
                          height="20"
                          transform="translate(1002 42)"
                          fill="#fff"
                          stroke="#707070"
                          strokeWidth="1"
                        />
                      </clipPath>
                    </defs>
                    <g
                      className="grid-list-btn list-btn"
                      id="Mask_Group_37"
                      data-name="Mask Group 37"
                      transform="translate(-1002 -42)"
                      clipPath="url(#clip-path)"
                    >
                      <g id="grid" transform="translate(998.667 38.667)">
                        <path
                          id="Path_390"
                          data-name="Path 390"
                          d="M8.333,5h15V6.667h-15Z"
                          fill="#42506f"
                        />
                        <path
                          id="Path_391"
                          data-name="Path 391"
                          d="M8.333,20h15v1.667h-15Z"
                          fill="#42506f"
                        />
                        <path
                          id="Path_392"
                          data-name="Path 392"
                          d="M8.333,12.5h15v1.667h-15Z"
                          fill="#42506f"
                        />
                        <path
                          id="Path_393"
                          data-name="Path 393"
                          d="M3.333,12.5H5v1.667H3.333Z"
                          fill="#42506f"
                        />
                        <path
                          id="Path_394"
                          data-name="Path 394"
                          d="M3.333,5H5V6.667H3.333Z"
                          fill="#42506f"
                        />
                        <path
                          id="Path_395"
                          data-name="Path 395"
                          d="M3.333,20H5v1.667H3.333Z"
                          fill="#42506f"
                        />
                      </g>
                    </g>
                  </svg>
                </Index.Button>
              </Index.Box>
            </Index.Tooltip>
            <Index.Tooltip title="Grid View" arrow>
              <Index.Box className="dashboard-icon-main">
                <Index.Button
                  className={`grid-img-sec ${
                    [
                      "/user/dashboard",
                      "/user/tracking-window-charts",
                    ].includes(location?.pathname) && "active"
                  }`}
                  onClick={() => handleViewChange(1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 19.999 20.001"
                  >
                    <path
                      id="Union_5"
                      data-name="Union 5"
                      d="M-5266-7150a1,1,0,0,1-1-1v-18a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1v18a1,1,0,0,1-1,1Zm17.5-1.5v-4.65h-4.649v4.65Zm-6.15,0v-4.65h-4.7v4.65Zm-10.851,0h4.65v-4.65h-4.65v-1.5h4.65v-4.7h-4.65Zm17-6.148v-4.7h-4.649v4.7Zm-6.15,0v-4.7h-4.7v4.7Zm6.15-6.2v-4.649h-4.649v4.649Zm-6.15,0v-4.649h-4.7v4.649Zm-6.2,0v-4.649h-4.65v4.649Z"
                      transform="translate(5267 7170)"
                      fill="#42506F"
                    />
                  </svg>
                </Index.Button>
              </Index.Box>
            </Index.Tooltip>
          </Index.Box>

          {/* mobile list and grid view */}
          <Index.Box className="grid-list-btn-sec custom-grid-btn mobile-view-chart">
            {["/user/dashboard", "/user/tracking-window-charts"]?.includes(
              location?.pathname
            ) ? (
              <Index.Tooltip title="List View" arrow>
                <Index.Box className="analysis-list-icon-main">
                  <Index.Button
                    className="analysis-window-btn"
                    onClick={() => handleMobileViewChange(0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_204"
                            data-name="Rectangle 204"
                            width="20"
                            height="20"
                            transform="translate(1002 42)"
                            fill="#fff"
                            stroke="#707070"
                            strokeWidth="1"
                          />
                        </clipPath>
                      </defs>
                      <g
                        className="grid-list-btn list-btn"
                        id="Mask_Group_37"
                        data-name="Mask Group 37"
                        transform="translate(-1002 -42)"
                        clipPath="url(#clip-path)"
                      >
                        <g id="grid" transform="translate(998.667 38.667)">
                          <path
                            id="Path_390"
                            data-name="Path 390"
                            d="M8.333,5h15V6.667h-15Z"
                            fill="#42506f"
                          />
                          <path
                            id="Path_391"
                            data-name="Path 391"
                            d="M8.333,20h15v1.667h-15Z"
                            fill="#42506f"
                          />
                          <path
                            id="Path_392"
                            data-name="Path 392"
                            d="M8.333,12.5h15v1.667h-15Z"
                            fill="#42506f"
                          />
                          <path
                            id="Path_393"
                            data-name="Path 393"
                            d="M3.333,12.5H5v1.667H3.333Z"
                            fill="#42506f"
                          />
                          <path
                            id="Path_394"
                            data-name="Path 394"
                            d="M3.333,5H5V6.667H3.333Z"
                            fill="#42506f"
                          />
                          <path
                            id="Path_395"
                            data-name="Path 395"
                            d="M3.333,20H5v1.667H3.333Z"
                            fill="#42506f"
                          />
                        </g>
                      </g>
                    </svg>
                  </Index.Button>
                </Index.Box>
              </Index.Tooltip>
            ) : (
              <Index.Tooltip title="Grid View" arrow>
                <Index.Box className="dashboard-icon-main">
                  <Index.Button
                    className="grid-img-sec"
                    onClick={() => handleMobileViewChange(1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.999"
                      height="20.001"
                      viewBox="0 0 19.999 20.001"
                    >
                      <path
                        id="Union_5"
                        data-name="Union 5"
                        d="M-5266-7150a1,1,0,0,1-1-1v-18a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1v18a1,1,0,0,1-1,1Zm17.5-1.5v-4.65h-4.649v4.65Zm-6.15,0v-4.65h-4.7v4.65Zm-10.851,0h4.65v-4.65h-4.65v-1.5h4.65v-4.7h-4.65Zm17-6.148v-4.7h-4.649v4.7Zm-6.15,0v-4.7h-4.7v4.7Zm6.15-6.2v-4.649h-4.649v4.649Zm-6.15,0v-4.649h-4.7v4.649Zm-6.2,0v-4.649h-4.65v4.649Z"
                        transform="translate(5267 7170)"
                        fill="#42506F"
                      />
                    </svg>
                  </Index.Button>
                </Index.Box>
              </Index.Tooltip>
            )}
          </Index.Box>

          <Index.Box
            className={`script-btn market-main-box ${
              showMarketButton && "hide-markets-btn"
            } custom-market-btn`}
          >
            <Index.GrayButton
              onClick={(e) => {
                handleElementClick(e, 100, 40);

                setrecentIndex(!recentIndex);
              }}
              ref={modalRef}
              className="gray-bg-btn more-index-btn"
              btnLabel="Markets"
              imgsrc={Index.Svg.downArrow}
            />
          </Index.Box>
          {["/user/dashboard", "/user/tracking-window-charts"]?.includes(
            location?.pathname
          ) && (
            <>
              {isDownloading ? (
                <Index.Box className="three-dot dashboard-three-dot">
                  <Index.Box className="round-btn-main">
                    <Index.Button className="round-btn download-btn chart-download-btn">
                      <Index.CircularProgress
                        // variant="determinate"
                        variant={
                          downloadingProgress === 100
                            ? "indeterminate"
                            : "determinate"
                        }
                        size={15}
                        value={downloadingProgress}
                        color="inherit"
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              ) : (
                <Index.Box
                  ref={downloadMenuRef}
                  className="three-dot dashboard-three-dot"
                >
                  {wishListData.length > 0 ? (
                    <Index.RoundButton
                      className="round-btn download-btn chart-download-btn"
                      imgsrc={Index.Svg.download}
                      onClick={() => {
                        setDownloadMenu((e) => !e);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <Index.Box
                    className={downloadMenu ? "head-menu active" : "head-menu"}
                  >
                    <Index.List className="ul">
                      {downloadsType?.map((e) => {
                        return (
                          <Index.ListItem
                            key={e.type}
                            className={`li`}
                            onClick={() => {
                              setDownloadMenu((e) => !e);
                              setIsDownloading(true);
                              printImage(e.type);
                            }}
                          >
                            <Index.Typography
                              className={`mint-text`}
                              variant="p"
                              component="p"
                            >
                              {e.type}
                            </Index.Typography>
                          </Index.ListItem>
                        );
                      })}
                    </Index.List>
                  </Index.Box>
                </Index.Box>
              )}
            </>
          )}

          {/* Tracking window details header */}
          <Index.Box
            className={`buy-sell-watch-main ${
              showBuySellWatchCount && "show-count"
            } custom-tracking-header`}
          >
            {location?.pathname === "/user/tracking-window-charts" && (
              <>
                <Index.Box className="category-select-box">
                  <Index.Select
                    className="common-dropdown"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    displayEmpty
                    value={categoryFilter}
                    onChange={(event) => {
                      let value = event?.target?.value;
                      dispatch(
                        manageCategoryFilter(
                          typeof value === "string" ? value.split(",") : value
                        )
                      );
                    }}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em className="buy-label-text">Category</em>;
                      }
                      const data = allStockTypes
                        ?.filter((item) => {
                          if (selected?.includes(item?.value)) {
                            return item;
                          }
                        })
                        ?.map((ele) => {
                          if (ele) {
                            return ele?.name;
                          }
                        });
                      return data?.join(", ");
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {allStockTypes.map((tag, index) => (
                      <Index.MenuItem
                        key={tag?.id}
                        value={tag?.value}
                        className="common-dropdown-menu"
                      >
                        {tag?.name}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.Box>

                <Index.Box className="header-exit-all-main">
                  <Index.PrimaryButton
                    className="primary-btn header-btn"
                    btnLabel="Exit All"
                    onClick={() => {
                      dispatch(manageExitAllModal());
                    }}
                  />
                </Index.Box>
              </>
            )}
            <Index.Box className="buy-sell-watch-label">
              <Index.Box className="buy-label">Buy</Index.Box>
              <Index.Box className="buy-label value-lable">
                {taggedStocksCount?.buyTag ? taggedStocksCount?.buyTag : 0}
              </Index.Box>
            </Index.Box>
            <Index.Box className="buy-sell-watch-label">
              <Index.Box className="buy-label bg-red">Sell</Index.Box>
              <Index.Box className="buy-label value-lable bg-red-20">
                {taggedStocksCount?.sellTag ? taggedStocksCount?.sellTag : 0}
              </Index.Box>
            </Index.Box>
            <Index.Box className="buy-sell-watch-label">
              <Index.Box className="buy-label bg-blue">Watch</Index.Box>
              <Index.Box className="buy-label value-lable bg-blue-20">
                {taggedStocksCount?.watchTag ? taggedStocksCount?.watchTag : 0}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="header-right custom-header-right">
          <Index.List className="main-ul">
            <Index.ListItem className="main-li">
              <Index.Badge
                className="notification-badge"
                badgeContent={notificationCount}
                invisible={notificationCount < 1}
                color="secondary"
                overlap="circular"
              >
                <Index.Box
                  className="btn-bg"
                  ref={modalRef}
                  onClick={(e) => {
                    handleElementClick(e, 450, 20);

                    dispatch(
                      setNotificationModalOpen(!openDefaultSettingModal)
                    );
                  }}
                >
                  <img
                    className="head-btn-img"
                    alt="search"
                    src={Index.Svg.notification}
                  />
                </Index.Box>
              </Index.Badge>
            </Index.ListItem>
            <Index.ListItem
              className={`main-li search-btn ${
                pathname === "/user/tracking-window-charts" ? "bsw-search" : ""
              }`}
            >
              {showHeaderSearch && (
                <Index.Autocomplete
                  className={`header-search-box hide-mobile-search ${
                    showHeaderSearch &&
                    showBuySellWatchCount &&
                    "header-search-box-resize"
                  }`}
                  id="fullWidth-symbollist"
                  variant="standard"
                  options={symbollist}
                  onChange={handleSearch}
                  onFocus={(e, el) => {
                    dispatch(activeShortCut(false));
                  }}
                  open={openAutoCompleteSuggestions}
                  onOpen={handleOpenAutoCompleteSuggestions}
                  onBlur={() => {
                    dispatch(showHeaderSearchBar(!showHeaderSearch));
                  }}
                  onClose={(v) => {
                    dispatch(activeShortCut(true));
                    handleCloseAutoCompleteSuggestions();
                  }}
                  loading={loader}
                  onInputChange={(e, v) => {
                    dispatch(activeShortCut(false));
                    let value = Index.trimText(v);
                    if (value?.length === 0) {
                      setSymbollist([]);
                    }
                    if (value.length > 2) {
                      getSymbollistData(value);
                    }
                    setInput(value);
                  }}
                  inputValue={input}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (typeof option !== "string") {
                      return option?.name;
                    }
                  }}
                  renderOption={(val, option) => {
                    const label = `${
                      (displaySettings &&
                        displaySettings?.StockSymbol === "on" &&
                        option &&
                        option?.symbol) ||
                      ""
                    }    ${
                      (displaySettings &&
                        displaySettings?.StockName === "on" &&
                        option &&
                        option?.name) ||
                      ""
                    } ${option && (option?.exch_seg || option?.Exch)}`;

                    const isItemInWishList = wishListData.some(
                      (item) => item?.token === option?.token
                    );
                    return (
                      <Index.Box className="search-autocomplete-list-item">
                        <span
                          className="search-autocomplete-title"
                          onClick={() => handleTitleSelected(option)}
                        >
                          {label}
                        </span>
                        <Index.Button
                          className="search-autocomplete-btn"
                          onClick={
                            isItemInWishList
                              ? () => handleRemoveFormWatchList(option)
                              : () => handleAddToWatchList(option)
                          }
                          sx={{
                            padding: isItemInWishList ? "3px 7px" : "3px 6px",
                          }}
                        >
                          {isItemInWishList ? "-" : "+"}
                        </Index.Button>
                      </Index.Box>
                    );
                  }}
                  renderInput={(params) => (
                    <>
                      <Index.TextField
                        onBlur={(e) => {
                          dispatch(activeShortCut(true));
                        }}
                        onFocus={(e) => {
                          dispatch(activeShortCut(false));
                        }}
                        className="search-input"
                        {...params}
                        placeholder="Search"
                      />
                    </>
                  )}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) => {
                      const nameMatches =
                        displaySettings?.StockName === "on" &&
                        option?.name
                          ?.toLowerCase()
                          ?.includes(inputValue?.toLowerCase());
                      const symbolMatches =
                        displaySettings?.StockSymbol === "on" &&
                        option?.symbol
                          ?.toLowerCase()
                          ?.includes(inputValue?.toLowerCase());
                      return nameMatches || symbolMatches;
                    })
                  }
                  classes={{ paper: "custom-script-menu-scrollbar" }}
                />
              )}

              <Index.Box
                className="btn-bg"
                onClick={() => {
                  dispatch(showHeaderSearchBar(!showHeaderSearch));
                  setOpenSearchCollapse(!openSearchCollapse);
                }}
              >
                <img
                  className="head-btn-img"
                  alt="search"
                  src={Index.Svg.search}
                />
              </Index.Box>
            </Index.ListItem>
            {!showTools && (
              <>
                {[
                  "/user/tracking-window",
                  "/user/tracking-window-charts",
                ]?.includes(location?.pathname) ? (
                  <Index.ListItem className="main-li custom-tool-show">
                    <Index.Link to={"/user/dashboard"}>
                      <Index.Box className="btn-bg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 19.999 20.001"
                        >
                          <path
                            id="Union_5"
                            data-name="Union 5"
                            d="M-5266-7150a1,1,0,0,1-1-1v-18a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1v18a1,1,0,0,1-1,1Zm17.5-1.5v-4.65h-4.649v4.65Zm-6.15,0v-4.65h-4.7v4.65Zm-10.851,0h4.65v-4.65h-4.65v-1.5h4.65v-4.7h-4.65Zm17-6.148v-4.7h-4.649v4.7Zm-6.15,0v-4.7h-4.7v4.7Zm6.15-6.2v-4.649h-4.649v4.649Zm-6.15,0v-4.649h-4.7v4.649Zm-6.2,0v-4.649h-4.65v4.649Z"
                            transform="translate(5267 7170)"
                            fill="#ffffff"
                          />
                        </svg>
                      </Index.Box>
                    </Index.Link>
                  </Index.ListItem>
                ) : (
                  <Index.ListItem className="main-li custom-tool-show">
                    <Index.Link to={"/user/tracking-window-charts"}>
                      <Index.Box className="btn-bg">
                        <img
                          className="head-btn-img"
                          alt="chart Yellow"
                          src={Index.Svg.chartYellow}
                        />
                      </Index.Box>
                    </Index.Link>
                  </Index.ListItem>
                )}
                <Index.ListItem
                  ref={modalRef}
                  className="main-li custom-tool-show"
                  onClick={(e) => {
                    dispatch(showDefaultSettingModal(!openDefaultSettingModal));
                    handleElementClick(e, 600, 40);
                  }}
                >
                  <Index.Box className="btn-bg">
                    <img
                      className="head-btn-img"
                      alt="settinh"
                      src={Index.Svg.setting}
                    />
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem
                  className="main-li custom-tool-show"
                  onClick={handleFullScreen}
                >
                  <Index.Box className="btn-bg">
                    <img
                      className="head-btn-img zoom-img"
                      alt="zoom"
                      src={
                        theme === "Light" ? Index.Svg.zoomWhite : Index.Svg.zoom
                      }
                    />
                  </Index.Box>
                </Index.ListItem>
              </>
            )}
            <Index.ListItem
              className="main-li bar-btn custom-menu-logo-box"
              ref={headMenuRef}
            >
              <Index.Box
                className="btn-bg "
                onClick={() => {
                  setShow(!show);
                  dispatch(manageSidebarVisibility(false));
                }}
              >
                <img
                  className="head-btn-img bar-img"
                  alt="bar"
                  src={
                    theme === "Light"
                      ? Index.Png.menuLogoWhite
                      : Index.Png.menuLogo
                  }
                />
              </Index.Box>
              {show && (
                <Index.Box className="toggle-menu-sec">
                  <List
                    className="main-toggle-list custom-toggle-list"
                    sx={{
                      width: "100%",
                      maxWidth: 281,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    {showTools && (
                      <>
                        <ListItemButton
                          className="toggle-list-btn"
                          onClick={(e) => {
                            handleElementClick(e, 470, 10);
                            setrecentIndex(!recentIndex);
                            setShow(false);
                          }}
                        >
                          <Index.QueryStatsIcon className="toggle-list-ic" />
                          <ListItemText
                            primary="Markets"
                            className="toggle-menu-text"
                          />
                        </ListItemButton>
                      </>
                    )}
                    <ListItemButton
                      className="toggle-list-btn"
                      onClick={() => {
                        navigate("/user/profile");
                        setShow(false);
                      }}
                    >
                      <img
                        className="toggle-list-ic"
                        alt="profilIc"
                        src={Index.Svg.profilIc}
                      />
                      <ListItemText
                        primary="Profile"
                        className="toggle-menu-text"
                      />
                    </ListItemButton>

                    <ListItemButton
                      className="toggle-list-btn"
                      onClick={() => {
                        navigate("/user/performance-report");
                        setShow(false);
                      }}
                    >
                      <img
                        className="toggle-list-ic"
                        alt="dataIc"
                        src={Index.Svg.dataIc}
                      />
                      <ListItemText
                        primary="Data"
                        className="toggle-menu-text"
                      />
                    </ListItemButton>
                    <ListItemButton
                      className="toggle-list-btn"
                      onClick={handleClick}
                    >
                      <img
                        className="toggle-list-ic"
                        alt="themeIc"
                        src={Index.Svg.themeIc}
                      />
                      <ListItemText
                        primary="Theme"
                        className="toggle-menu-text"
                      />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className="coll-main"
                    >
                      <List
                        className="collapse-list custom-theme"
                        component="div"
                        disablePadding
                      >
                        {handleThemeButton.map((item, index) => (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            className={`toggle-list-btn ${
                              themeActive === index && "active"
                            } custom-menu-btn`}
                            onClick={() => (
                              setThemeActive(index),
                              handleThemeChange(item.title)
                            )}
                            key={index}
                          >
                            <img
                              className="toggle-list-ic"
                              alt="lightIc"
                              src={item.icon}
                            />
                            <ListItemText primary={item.title} />
                            {theme === item.title ? (
                              <img
                                alt="listCheck"
                                src={
                                  theme === "Light"
                                    ? Index.Svg.lightChecked
                                    : Index.Svg.listChecked
                                }
                              />
                            ) : (
                              <img alt="listCheck" src={Index.Svg.listCheck} />
                            )}
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    {/* full screen, tracking window and setting */}
                    {showTools && (
                      <>
                        <ListItemButton
                          className="toggle-list-btn"
                          onClick={() => {
                            navigate(
                              [
                                "/user/tracking-window-charts",
                                "/user/tracking-window",
                              ]?.includes(location?.pathname)
                                ? "/user/dashboard"
                                : "/user/tracking-window-charts"
                            );
                            setShow(false);
                          }}
                        >
                          <img
                            className="toggle-list-ic"
                            alt="chart Yellow"
                            src={
                              [
                                "/user/tracking-window-charts",
                                "/user/tracking-window",
                              ]?.includes(location?.pathname)
                                ? Index.Svg.menuList
                                : theme === "Light"
                                ? Index.Svg.chartWhite
                                : Index.Svg.chartYellow
                            }
                          />
                          <ListItemText
                            primary={
                              [
                                "/user/tracking-window-charts",
                                "/user/tracking-window",
                              ]?.includes(location?.pathname)
                                ? "Dashboard"
                                : "Tracking window"
                            }
                            className="toggle-menu-text"
                          />
                        </ListItemButton>
                        <ListItemButton
                          className="toggle-list-btn"
                          onClick={(e) => {
                            dispatch(
                              showDefaultSettingModal(!openDefaultSettingModal)
                            );
                            handleElementClick(e, 490, -400);
                            setShow(false);
                          }}
                        >
                          <img
                            className="toggle-list-ic"
                            alt="setting"
                            src={
                              theme === "Light"
                                ? Index.Svg.settingWhite
                                : Index.Svg.setting
                            }
                          />
                          <ListItemText
                            primary="Setting"
                            className="toggle-menu-text"
                          />
                        </ListItemButton>
                        {/* <ListItemButton
                          className="toggle-list-btn"
                          onClick={() => {
                            handleFullScreen();
                            setShow(false);
                          }}
                        >
                          <img
                            className="toggle-list-ic"
                            alt="zoom"
                            src={
                              theme === "Light"
                                ? Index.Svg.zoomWhite
                                : Index.Svg.zoom
                            }
                          />
                          <ListItemText
                            primary="Full screen"
                            className="toggle-menu-text"
                          />
                        </ListItemButton> */}
                      </>
                    )}
                    <ListItemButton
                      className="toggle-list-btn"
                      onClick={handleLogout}
                    >
                      <img
                        className="toggle-list-ic"
                        alt="logoutIc"
                        src={Index.Svg.logoutIc}
                      />
                      <ListItemText
                        primary="Logout"
                        className="toggle-menu-text"
                      />
                    </ListItemButton>
                  </List>
                </Index.Box>
              )}
            </Index.ListItem>
            <Index.ListItem className="main-li sidbar-btn">
              <Index.Box
                className="btn-bg"
                onClick={() => {
                  dispatch(manageSidebarVisibility(!showSideBar));
                }}
              >
                <Index.MenuIcon className="header-menu-icon" />
              </Index.Box>
            </Index.ListItem>
          </Index.List>
        </Index.Box>
      </Index.Box>

      {/* Single Chart View Start */}
      {singleChartOpen && (
        <>
          {" "}
          <Index.Box>
            <Index.Modal
              open={Boolean(singleChartOpen)}
              onClose={() => {
                handleChartClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              ref={miniChartModalRef}
            >
              <Index.Box sx={style} className="mini-chart-modal-box">
                <Index.Box className="modal-header-sec custom-modal-header">
                  <ChartHeader
                    headerData={singleChartData}
                    chartData={singleChartData?.stockData}
                    data={singleChartData}
                    tags={tags}
                    setTags={setTag}
                    allTags={allTags}
                    index={JSON.parse(singleChartOpen)}
                    downloadsType={downloadsType}
                    onClose={() => {
                      handleChartClose();
                    }}
                    singleChart={true}
                    singleChartTag={singleChartData?.tag}
                    fromDate={fromDate}
                    live={chartView === "Intraday"}
                    previousClosePrice={singleChartData?.previousClosePrice}
                    miniChartModalRef={miniChartModalRef}
                    socket={props?.socket}
                  />
                </Index.Box>
                <Index.Box className="modal-body-sec">
                  {graphType[theme][chartView] === 0 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomLineChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        stockData={singleChartData?.stockData}
                        index={JSON.parse(singleChartOpen)}
                        stockToken={JSON.parse(singleChartOpen)}
                        chartLiveData={chartLiveData?.tick}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 1 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomAreaChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        stockData={singleChartData?.stockData}
                        index={JSON.parse(singleChartOpen)}
                        stockToken={JSON.parse(singleChartOpen)}
                        chartLiveData={chartLiveData?.tick}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 2 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomDashedChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        stockData={singleChartData?.stockData}
                        index={JSON.parse(singleChartOpen)}
                        stockToken={JSON.parse(singleChartOpen)}
                        chartLiveData={chartLiveData?.tick}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 3 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomCendalStickChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        stockData={singleChartData?.stockData}
                        index={JSON.parse(singleChartOpen)}
                        stockToken={JSON.parse(singleChartOpen)}
                        chartLiveData={chartLiveData?.tick}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                      />
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>{" "}
        </>
      )}
      {/* Single Chart View End */}
      <Modal
        show={recentIndex}
        position={position}
        setShow={setrecentIndex}
        title={"Recent Index"}
        x={position?.x}
        y={position?.y}
        width={"615px"}
        isMobile={isMobile}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        html={<Index.RecentIndex show={recentIndex} setShow={setrecentIndex} />}
        opacity={opacity}
        setOpacity={setOpacity}
      />
      <Modal
        position={position}
        show={openDefaultSettingModal}
        setShow={() =>
          dispatch(showDefaultSettingModal(!openDefaultSettingModal))
        }
        title={"Default Settings"}
        x={position?.x}
        y={position?.y}
        width={"700px"}
        opacity={opacity}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        setOpacity={setOpacity}
        html={
          <Index.DefaultSetting
            seteditShortcut={seteditShortcut}
            editShortcut={editShortcut}
            setdefaultSetting={() =>
              dispatch(showDefaultSettingModal(!openDefaultSettingModal))
            }
            defaultSetting={openDefaultSettingModal}
            autoSquareModal={autoSquareModal}
            setAutoSquareModal={setAutoSquareModal}
          />
        }
      />

      <Modal
        show={openNotificationModal}
        setShow={() =>
          dispatch(setNotificationModalOpen(!openNotificationModal))
        }
        className="notification-ic"
        position={position}
        isNotificationData={isNotificationData}
        title={"Notifications"}
        x={position?.x}
        y={position?.y}
        width={"600px"}
        SVG={Index.Png.notification}
        opacity={opacity}
        setOpacity={setOpacity}
        html={
          <Index.NotificationTab
            seteditShortcut={seteditShortcut}
            editShortcut={editShortcut}
            isNotificationData={isNotificationData}
            setIsNotificationData={setIsNotificationData}
            setNotificationModalOpen={setNotificationModalOpen}
            setdefaultSetting={() =>
              dispatch(showDefaultSettingModal(!openDefaultSettingModal))
            }
            defaultSetting={openDefaultSettingModal}
          />
        }
      />

      <Modal
        show={editShortcut}
        setShow={seteditShortcut}
        title={"Edit Shortcut"}
        position={position}
        x={position?.x}
        y={position?.y}
        width={"442px"}
        opacity={opacity}
        setOpacity={setOpacity}
        html={
          <Index.EditShortcut
            seteditShortcut={seteditShortcut}
            editShortcut={editShortcut}
            setreplaceShortcut={setreplaceShortcut}
            replaceShortcut={replaceShortcut}
          />
        }
      />

      <Modal
        show={replaceShortcut}
        setShow={setreplaceShortcut}
        title={"Replace Shortcut "}
        position={position}
        x={position?.x}
        y={position?.y}
        SVG={
          theme === "Medium"
            ? Index.Svg.settingsSolid
            : theme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        width={"442px"}
        opacity={opacity}
        setOpacity={setOpacity}
        html={<Index.ReplaceShortcut />}
      />

      <Modal
        show={alert}
        setShow={setAlert}
        title={"Alert"}
        x={position?.x}
        y={position?.y}
        position={position}
        width={"460px"}
        opacity={opacity}
        setOpacity={setOpacity}
        html={<Index.AlertModal />}
      />
      <Modal
        show={reportModal}
        setShow={setreportModal}
        title={"Report"}
        x={position?.x}
        y={position?.y}
        position={position}
        width={"393px"}
        opacity={opacity}
        setOpacity={setOpacity}
        html={<Index.ReportModal />}
      />
      <Modal
        position={position}
        show={downloadChart}
        setShow={setdownloadChart}
        title={"Download Chart"}
        x={position?.x}
        y={position?.y}
        width={"616px"}
        opacity={opacity}
        setOpacity={setOpacity}
        html={<Index.DownloadChart />}
      />
      <Modal
        show={autoSquareModal}
        setShow={setAutoSquareModal}
        title="Auto Square Time"
        x={position?.x}
        y={position?.y}
        position={position}
        opacity={opacity}
        setOpacity={setOpacity}
        width={"407px"}
        html={<Index.AutoSquareTime setAutoSquareModal={setAutoSquareModal} />}
      />
    </>
  );
};
export default Header;
