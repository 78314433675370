import React, { useEffect, useRef, useState } from "react";
import Index from "../Index";
import PropTypes from "prop-types";

import {
  GetGraphStyleAction,
  manageDisplaySettings,
  manageMiniChartSize,
  activeShortCut,
  manageRowsPerPage,
  manageDefaultSetting,
  manageUserSetting,
} from "../../redux/user/action";
import { manageOtherSetting } from "../../redux/user/action";
import Constants from "../common/constants";
import { DataService } from "../../config/DataService";
import Modal from "../modal/Modal";
import AutoSquareTime from "./AutoSquareTime";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabpanel-simple"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="function-tab-details">
          <Index.Typography className="text-typo-tab">
            {children}
          </Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DefaultSetting = (props) => {
  // const declaration region
  const {
    seteditShortcut,
    editShortcut,
    setdefaultSetting,
    defaultSetting,
    autoSquareModal,
    setAutoSquareModal,
  } = props;
  const dispatch = Index.useDispatch();
  const chartStyle = Index.useSelector((store) => store.UserReducer.GraphStyle);
  const themeTitle = localStorage.getItem("default-theme");
  const colorPickerRef = useRef(null);

  const {
    chartView,
    otherSettings,
    displaySettings,
    graphType,
    rowsPerPage,
    chartDefaultSettings,
    token: userToken,
    miniChartSize,
    theme,
    position,
  } = Index.useSelector((store) => store.UserReducer);

  const [selectedChart, setSelectedChart] = useState(
    graphType[theme][chartView]
  );
  const [isMobileView, setIsMobileView] = useState(false);

  const shortCutList = [
    { id: 1, shortCutName: "Line Chart", shortCutKeys: ["L"] },
    { id: 2, shortCutName: "Area Chart", shortCutKeys: ["A"] },
    { id: 3, shortCutName: "Candle Chart", shortCutKeys: ["C"] },
    { id: 4, shortCutName: "Dashed Chart", shortCutKeys: ["D"] },
    { id: 5, shortCutName: "Intraday View", shortCutKeys: ["I"] },
    { id: 6, shortCutName: "5 Days/1 Minute", shortCutKeys: ["1"] },
    { id: 7, shortCutName: "15 Days/2 Minute", shortCutKeys: ["2"] },
    { id: 8, shortCutName: "1 Month/3 Minute", shortCutKeys: ["3"] },
    { id: 9, shortCutName: "3 Months/4 Minute", shortCutKeys: ["4"] },
    { id: 10, shortCutName: "6 Months/5 Minute", shortCutKeys: ["5"] },
    { id: 11, shortCutName: "1 Year/10 Minute", shortCutKeys: ["6"] },
    { id: 12, shortCutName: "15 Minute", shortCutKeys: ["7"] },
    { id: 13, shortCutName: "Positional View", shortCutKeys: ["P"] },
    { id: 14, shortCutName: "5 working Day", shortCutKeys: ["1"] },
    { id: 15, shortCutName: "15 Minute", shortCutKeys: ["CTRL", "1"] },
    { id: 16, shortCutName: "30 Minute", shortCutKeys: ["CTRL", "2"] },
    { id: 17, shortCutName: "1 Hour", shortCutKeys: ["CTRL", "3"] },
    { id: 18, shortCutName: "15 working Day", shortCutKeys: ["2"] },
    { id: 19, shortCutName: "30 Minute", shortCutKeys: ["CTRL", "1"] },
    { id: 20, shortCutName: "1 Hour", shortCutKeys: ["CTRL", "2"] },
    { id: 22, shortCutName: "4 Hour", shortCutKeys: ["CTRL", "3"] },
    { id: 23, shortCutName: "1 Month", shortCutKeys: ["3"] },
    { id: 24, shortCutName: "4 Hour", shortCutKeys: ["CTRL", "1"] },
    { id: 25, shortCutName: "1 Day", shortCutKeys: ["CTRL", "2"] },
    { id: 26, shortCutName: "3 Month", shortCutKeys: ["4"] },
    { id: 27, shortCutName: "4 Hour", shortCutKeys: ["CTRL", "1"] },
    { id: 28, shortCutName: "1 Day", shortCutKeys: ["CTRL", "2"] },
    { id: 29, shortCutName: "6 Month", shortCutKeys: ["5"] },
    { id: 30, shortCutName: "1 Day", shortCutKeys: ["CTRL", "1"] },
    { id: 31, shortCutName: "1 Week", shortCutKeys: ["CTRL", "2"] },
    { id: 32, shortCutName: "1 Year", shortCutKeys: ["6"] },
    { id: 33, shortCutName: "1 Day", shortCutKeys: ["CTRL", "1"] },
    { id: 34, shortCutName: "2 Day", shortCutKeys: ["CTRL", "2"] },
    { id: 35, shortCutName: "3 Day", shortCutKeys: ["CTRL", "3"] },
    { id: 36, shortCutName: "1 Week", shortCutKeys: ["CTRL", "4"] },
    { id: 37, shortCutName: "2 Week", shortCutKeys: ["CTRL", "5"] },
    { id: 38, shortCutName: "3 Week", shortCutKeys: ["CTRL", "6"] },
    { id: 39, shortCutName: "Buy", shortCutKeys: ["CTRL", "B"] },
    { id: 40, shortCutName: "Sell", shortCutKeys: ["CTRL", "S"] },
    { id: 41, shortCutName: "Watch", shortCutKeys: ["ALT", "W"] },
    { id: 42, shortCutName: "Exit", shortCutKeys: ["CTRL", "E"] },
    { id: 43, shortCutName: "Reverse", shortCutKeys: ["CTRL", "R"] },
    { id: 44, shortCutName: "View Chart Popup", shortCutKeys: ["Shift", "O"] },
    { id: 45, shortCutName: "Hide Sidebar/Header", shortCutKeys: ["H"] },
    { id: 46, shortCutName: "Show Sidebar/Header", shortCutKeys: ["V"] },
  ];

  const IntradayButtons = [
    { title: "1 Min", value: "ONE_MINUTE" },
    { title: "2 Min", value: "TWO_MINUTE" },
    { title: "3 Min", value: "THREE_MINUTE" },
    { title: "4 Min", value: "FOUR_MINUTE" },
    { title: "5 Min", value: "FIVE_MINUTE" },
    { title: "10 Min", value: "TEN_MINUTE" },
    { title: "15 Min", value: "FIFTEEN_MINUTE" },
  ];

  const DefaultModelStyleButtons = [
    { title: "Line", icon: Index.Svg.modalLine },
    { title: "Area", icon: Index.Svg.modalArea },
    { title: "Dotted", icon: Index.Svg.modalDotted },
    { title: "Candle Stick", icon: Index.Svg.modalCandelChart },
  ];

  const PositionalButtons = [
    {
      title: "5 Days",
      value: 5,
      time: [
        { intervalTitle: "15 Min", timeFrame: 5, interval: "FIFTEEN_MINUTE" },
        { intervalTitle: "30 Min", timeFrame: 5, interval: "THIRTY_MINUTE" },
        { intervalTitle: "1 Hour", timeFrame: 5, interval: "ONE_HOUR" },
      ],
    },
    {
      title: "15 Days",
      value: 15,
      time: [
        { intervalTitle: "30 Min", timeFrame: 15, interval: "THIRTY_MINUTE" },
        { intervalTitle: "1 Hour", timeFrame: 15, interval: "ONE_HOUR" },
        { intervalTitle: "4 Hour", timeFrame: 15, interval: "FOUR_HOUR" },
      ],
    },
    {
      title: "1 Month",
      value: 30,
      time: [
        { intervalTitle: "4 Hour", timeFrame: 30, interval: "FOUR_HOUR" },
        { intervalTitle: "1 Day", timeFrame: 30, interval: "ONE_DAY" },
      ],
    },
    {
      title: "3 Months",
      value: 90,
      time: [
        { intervalTitle: "4 Hour", timeFrame: 90, interval: "FOUR_HOUR" },
        { intervalTitle: "1 Day", timeFrame: 90, interval: "ONE_DAY" },
      ],
    },
    {
      title: "6 Months",
      value: 180,
      time: [
        { intervalTitle: "1 Day", timeFrame: 180, interval: "ONE_DAY" },
        { intervalTitle: "1 Week", timeFrame: 180, interval: "ONE_WEEK" },
      ],
    },
    {
      title: "1 Year",
      value: 365,
      time: [
        { intervalTitle: "1 Day", timeFrame: 365, interval: "ONE_DAY" },
        { intervalTitle: "2 Day", timeFrame: 365, interval: "TWO_DAY" },
        { intervalTitle: "3 Day", timeFrame: 365, interval: "THREE_DAY" },
        { intervalTitle: "1 Week", timeFrame: 365, interval: "ONE_WEEK" },
        { intervalTitle: "2 Week", timeFrame: 365, interval: "TWO_WEEK" },
        { intervalTitle: "3 Week", timeFrame: 365, interval: "THREE_WEEK" },
      ],
    },
  ];

  const defaultSettingDisplayToggle = [
    {
      name: "ScriptDetails",
      label: " Script details",
      firstValue: "on",
      secondValue: "off",
    },
    {
      name: "cmp",
      label: "CMP",
      firstValue: "on",
      secondValue: "off",
    },
    {
      name: "change",
      label: "Change(Rs & %)",
      firstValue: "Rs",
      secondValue: "%",
    },
    {
      name: "TimeScale",
      label: "Time Scale",
      firstValue: "on",
      secondValue: "off",
    },
    {
      name: "PriceScale",
      label: "Price scale",
      firstValue: "on",
      secondValue: "off",
    },
    selectedChart === 3
      ? {
          name: "OHLCValues",
          label: "OHLC Values",
          firstValue: "on",
          secondValue: "off",
        }
      : null,
    {
      name: "Alerts",
      label: "Alerts",
      firstValue: "on",
      secondValue: "off",
    },
    {
      name: "StockSymbol",
      label: "Stock Symbol",
      firstValue: "on",
      secondValue: "off",
    },
    {
      name: "StockName",
      label: "Stock Name",
      firstValue: "on",
      secondValue: "off",
    },
    chartView === "Intraday"
      ? {
          name: "PriceIndicator",
          label: "Price Indicator",
          firstValue: "on",
          secondValue: "off",
        }
      : null,

    {
      name: "UtilityBar",
      label: "Utility Bar",
      firstValue: "left",
      secondValue: "right",
    },
    {
      name: "LastUpdatedTime",
      label: "Last Updated Time",
      firstValue: "on",
      secondValue: "off",
    },
    chartView == "Positional"
      ? {
          name: "DaySplitter",
          label: "Day Splitter",
          firstValue: "on",
          secondValue: "off",
        }
      : null,
    {
      name: "StopLossTargetPrice",
      label: "Stop Loss Target Price",
      firstValue: "on",
      secondValue: "off",
    },
    {
      name: "AutoSquare",
      label: "Auto square",
      firstValue: "on",
      secondValue: "off",
    },
  ];

  // state declaration region
  const [value, setValue] = React.useState(0);
  const [lineChart, setLineChart] = React.useState(
    chartStyle?.[theme]?.lineGraph
  );
  const [areaChart, setAreaChart] = React.useState(
    chartStyle?.[theme]?.areaGraph
  );
  const [dottedChart, setDottedChart] = React.useState(
    chartStyle?.[theme]?.dottedGraph
  );
  const [miniChartSizeData, setMiniChartSizeData] =
    React.useState(miniChartSize);
  const [candleChart, setCandleChart] = React.useState(
    chartStyle?.[theme]?.candleStickGraph
  );
  const [otherSetting, setOtherSetting] = useState(otherSettings);
  const [customDefaultSettings, setCustomDefaultSettings] = useState({
    ...chartDefaultSettings,
    chartView,
  });

  const [toggleValue, setToggleValue] = useState(displaySettings);
  const [chartColorDropdownOpen, setChartColorDrowdownOpen] = useState(false);

  // function declaration region
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const manageChartStyle = (keyPath, value) => {
    dispatch(
      manageUserSetting(userToken, `GraphStyle.${theme}.${keyPath}`, value)
    );
  };

  const handleChangeLineGraphColor = (e) => {
    if (colorPickerRef.current) {
      clearTimeout(colorPickerRef.current);
    }

    colorPickerRef.current = setTimeout(() => {
      setLineChart((prev) => {
        return { ...prev, color: e };
      });
      dispatch(
        GetGraphStyleAction({
          ...chartStyle,
          [theme]: {
            ...chartStyle[theme],
            lineGraph: {
              ...chartStyle[theme].lineGraph,
              color: e,
            },
          },
        })
      );
      manageChartStyle("lineGraph.color", e);
      colorPickerRef.current = null;
    }, 500);
  };

  const handleChangeLineGraphThickness = (event, key) => {
    setLineChart((prev) => {
      return { ...prev, [key]: event?.target?.value || event };
    });
    dispatch(
      GetGraphStyleAction({
        ...chartStyle,
        [theme]: {
          ...chartStyle[theme],
          lineGraph: {
            ...chartStyle[theme].lineGraph,
            [key]: event?.target?.value || event,
          },
        },
      })
    );
    manageChartStyle(`lineGraph.${key}`, event?.target?.value || event);
  };

  const handleChangeAreaGraphColor = (e) => {
    if (colorPickerRef.current) {
      clearTimeout(colorPickerRef.current);
    }

    colorPickerRef.current = setTimeout(() => {
      setAreaChart((prev) => {
        return { ...prev, color: e };
      });
      dispatch(
        GetGraphStyleAction({
          ...chartStyle,
          [theme]: {
            ...chartStyle[theme],
            areaGraph: {
              ...chartStyle[theme].areaGraph,
              color: e,
            },
          },
        })
      );
      manageChartStyle("areaGraph.color", e);
      colorPickerRef.current = null;
    }, 500);
  };

  const handleChangeAreaGraphThickness = (event, key) => {
    if (colorPickerRef.current && key === "thicknessColor") {
      clearTimeout(colorPickerRef.current);
    }

    colorPickerRef.current = setTimeout(() => {
      setAreaChart((prev) => {
        return { ...prev, [key]: event?.target?.value || event };
      });
      dispatch(
        GetGraphStyleAction({
          ...chartStyle,
          [theme]: {
            ...chartStyle[theme],
            areaGraph: {
              ...chartStyle[theme].areaGraph,
              [key]: event?.target?.value || event,
            },
          },
        })
      );
      manageChartStyle(`areaGraph.${key}`, event?.target?.value || event);
      colorPickerRef.current = null;
    }, 500);
  };

  const handleChangeDottedGraphColor = (e) => {
    if (colorPickerRef.current) {
      clearTimeout(colorPickerRef.current);
    }

    colorPickerRef.current = setTimeout(() => {
      setDottedChart((prev) => {
        return { ...prev, color: e };
      });
      dispatch(
        GetGraphStyleAction({
          ...chartStyle,
          [theme]: {
            ...chartStyle[theme],
            dottedGraph: {
              ...chartStyle[theme].dottedGraph,
              color: e,
            },
          },
        })
      );
      manageChartStyle("dottedGraph.color", e);
      colorPickerRef.current = null;
    }, 500);
  };

  const handleChangeDottedGraphThickness = (event, key) => {
    setDottedChart((prev) => {
      return { ...prev, [key]: event?.target?.value || event };
    });
    dispatch(
      GetGraphStyleAction({
        ...chartStyle,
        [theme]: {
          ...chartStyle[theme],
          dottedGraph: {
            ...chartStyle[theme].dottedGraph,
            [key]: event?.target?.value || event,
          },
        },
      })
    );
    manageChartStyle(`dottedGraph.${key}`, event?.target?.value || event);
  };

  const handleChangeCandleGraphColor = (e, name) => {
    if (colorPickerRef.current) {
      clearTimeout(colorPickerRef.current);
    }

    colorPickerRef.current = setTimeout(() => {
      setCandleChart((prev) => {
        return { ...prev, [name]: e };
      });
      dispatch(
        GetGraphStyleAction({
          ...chartStyle,
          [theme]: {
            ...chartStyle[theme],
            candleStickGraph: {
              ...chartStyle[theme].candleStickGraph,
              [name]: e,
            },
          },
        })
      );
      manageChartStyle(`candleStickGraph.${name}`, e);
      colorPickerRef.current = null;
    }, 500);
  };

  const handleChangeCandleGraphThickness = (event, key) => {
    setCandleChart((prev) => {
      return { ...prev, [key]: event?.target?.value || event };
    });
    dispatch(
      GetGraphStyleAction({
        ...chartStyle,
        [theme]: {
          ...chartStyle[theme],
          candleStickGraph: {
            ...chartStyle[theme].candleStickGraph,
            [key]: event?.target?.value || event,
          },
        },
      })
    );
    manageChartStyle(`candleStickGraph.${key}`, event?.target?.value || event);
  };
  const colorSetting = [
    {
      heading: "Line Chart",
      image:
        theme === "Light"
          ? Index.Png.lineChartLight
          : theme === "Medium"
          ? Index.Png.lineChart
          : Index.Png.lineChartDark,
      variable: lineChart,
      handleColor: handleChangeLineGraphColor,
      handleThickness: handleChangeLineGraphThickness,
    },
    {
      heading: "Area Chart",
      image:
        theme === "Light"
          ? Index.Png.AreaChartLight
          : theme === "Medium"
          ? Index.Png.AreaChart
          : Index.Png.AreaChartDark,
      variable: areaChart,
      handleColor: handleChangeAreaGraphColor,
      handleThickness: handleChangeAreaGraphThickness,
    },
    {
      heading: "Dotted Chart",
      image:
        theme === "Light"
          ? Index.Png.dottedChartLight
          : theme === "Medium"
          ? Index.Png.dottedChart
          : Index.Png.dottedChartDark,

      variable: dottedChart,
      handleColor: handleChangeDottedGraphColor,
      handleThickness: handleChangeDottedGraphThickness,
    },
    {
      heading: "Candle Stick Chart",
      image:
        theme === "Light"
          ? Index.Png.candelChartLight
          : theme === "Medium"
          ? Index.Png.candelChart
          : Index.Png.candelChartDark,

      variable: candleChart,
      handleColor: handleChangeCandleGraphColor,
      handleThickness: handleChangeCandleGraphThickness,
    },
  ];

  const handleChangeOtherSettings = (e, key, element) => {
    if (colorPickerRef.current && element == "color") {
      clearTimeout(colorPickerRef.current);
    }

    colorPickerRef.current = setTimeout(() => {
      setOtherSetting((el) => ({
        ...el,
        [theme]: {
          ...el[theme],
          [key]: {
            ...otherSettings[theme][key],
            [element]: e?.target?.value || e,
          },
        },
      }));
      dispatch(
        manageOtherSetting({
          ...otherSettings,
          [theme]: {
            ...otherSettings[theme],
            [key]: {
              ...otherSettings[theme][key],
              [element]: e?.target?.value || e,
            },
          },
        })
      );
      dispatch(
        manageUserSetting(
          userToken,
          `colorSetting.${theme}.${key}.${element}`,
          e?.target?.value || e
        )
      );
      colorPickerRef.current = null;
    }, 500);
  };

  const otherSettingInitial = [
    {
      title: "Crosshair",
      key: "crosshair",
    },
    {
      title: "Stop Loss",
      key: "stopLoss",
    },
    {
      title: "Target",
      key: "target",
    },
    {
      title: "Scale",
      key: "scale",
    },
    {
      title: "Day Splitter",
      key: "daySplitter",
    },
  ];

  const handleEditModel = () => {
    setdefaultSetting(!defaultSetting);
    seteditShortcut(!editShortcut);
  };

  const handleOpenTimeSetting = () => {
    setAutoSquareModal(true);
  };

  const handleToggleGroup = (event, newAlignment, item) => {
    if (item?.name === "AutoSquare" && newAlignment === "on") {
      setAutoSquareModal(true);
      return false;
    }

    if (newAlignment === null) {
      setToggleValue((pre) => ({
        ...pre,
        [item.name]: pre[item.name],
      }));
    } else {
      setToggleValue((pre) => ({
        ...pre,
        [item.name]: newAlignment,
      }));
    }

    dispatch(
      manageDisplaySettings({ ...displaySettings, [item?.name]: newAlignment })
    );

    dispatch(
      manageUserSetting(userToken, `displaySetting.${item?.name}`, newAlignment)
    );
  };

  const handleColorSettingReset = () => {
    dispatch(GetGraphStyleAction(Constants.defaultGraphStyle));
    dispatch(manageOtherSetting(Constants.defaultOtherSettings));
  };

  const handleMiniChartSize = () => {
    dispatch(manageMiniChartSize(miniChartSizeData));
    dispatch(manageUserSetting(userToken, "miniChartSize", miniChartSizeData));
  };

  //Manage Default Setting
  const handleManageDefaultSetting = () => {
    dispatch(manageDefaultSetting(customDefaultSettings));
    setdefaultSetting(false);
    dispatch(
      manageUserSetting(userToken, "chartSetting", customDefaultSettings)
    );
  };

  useEffect(() => {
    if (displaySettings) {
      setToggleValue(displaySettings);
    }
  }, [displaySettings]);

  useEffect(() => {
    setLineChart(chartStyle?.[theme]?.lineGraph);
    setAreaChart(chartStyle?.[theme]?.areaGraph);
    setDottedChart(chartStyle?.[theme]?.dottedGraph);
    setCandleChart(chartStyle?.[theme]?.candleStickGraph);
    setOtherSetting(otherSettings);
  }, [otherSettings, chartStyle?.[theme]]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 641) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Index.Box className="tab-content-main default-setting-main">
        <Index.Box sx={{ width: "100%" }} className="tab-details-content">
          <Index.Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="tab-border-content"
          >
            <Index.Tabs
              className="tabs-content-top"
              value={value}
              variant="scrollable"
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Index.Tab
                className="tabs-content-title"
                label="Chart Settings"
                {...a11yProps(0)}
              />
              <Index.Tab
                className="tabs-content-title"
                label="Display"
                {...a11yProps(1)}
              />

              <Index.Tab
                className={`tabs-content-title ${isMobileView && "tab-hidden"}`}
                label="Shortcuts"
                {...a11yProps(2)}
              />

              <Index.Tab
                className="tabs-content-title"
                label="Colour Settings"
                {...a11yProps(3)}
              />
            </Index.Tabs>
          </Index.Box>
          <Index.Box className="tab-body">
            <TabPanel value={value} index={0} className="tabs-content-panel">
              <Index.Box className="tab-panel-details">
                <Index.Box className="chart-view-sec">
                  <Index.Grid container gap={2}>
                    <Index.Grid
                      container
                      xs={12}
                      sm={3}
                      md={2}
                      lg={2}
                      className="chart-view-grid"
                    >
                      <Index.Typography
                        className="modal-sub-title"
                        variant="p"
                        component="p"
                      >
                        Chart View
                      </Index.Typography>
                    </Index.Grid>
                    <Index.Grid container xs={10} sm={8} md={9} lg={9}>
                      <Index.Box className="modal-ul-li">
                        <Index.List className="modal-ul default-model-ul chart-view-btn-ul">
                          {["Intraday", "Positional"].map((item, index) => (
                            <Index.Grid container xs={5} sm={5} md={6} lg={6}>
                              <Index.ListItem
                                style={{ marginRight: "20px" }}
                                className={`modal-li cus-center ${
                                  customDefaultSettings?.chartView === item &&
                                  "active"
                                } `}
                                onClick={() => {
                                  setCustomDefaultSettings({
                                    ...customDefaultSettings,
                                    chartView: item,
                                  });
                                }}
                                key={index}
                              >
                                <Index.Typography
                                  className=""
                                  variant="p"
                                  component="p"
                                >
                                  {item}
                                </Index.Typography>
                              </Index.ListItem>
                            </Index.Grid>
                          ))}
                        </Index.List>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="time-frame-sec">
                  {customDefaultSettings?.chartView === "Intraday" ? (
                    <>
                      <Index.Box className="time-frame-content intraday-sec">
                        <Index.Box className="time-frame-left">
                          <Index.Box className="time-frame-ul-li">
                            <Index.List className="time-frame-ul">
                              {IntradayButtons.map((item, index) => (
                                <Index.ListItem
                                  className={`time-frame-li ${
                                    customDefaultSettings?.[
                                      customDefaultSettings?.chartView
                                    ]?.interval === item?.value && "active"
                                  } `}
                                  onClick={() => {
                                    setCustomDefaultSettings({
                                      ...customDefaultSettings,
                                      [customDefaultSettings?.chartView]: {
                                        ...customDefaultSettings?.[
                                          customDefaultSettings?.chartView
                                        ],
                                        interval: item?.value,
                                      },
                                    });
                                  }}
                                  key={index}
                                >
                                  <Index.Box className="day-btn">
                                    <Index.Typography
                                      className=""
                                      variant="p"
                                      component="p"
                                    >
                                      {item.title}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.ListItem>
                              ))}
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </>
                  ) : (
                    <>
                      <Index.Typography
                        className="modal-sub-title"
                        variant="p"
                        component="p"
                      >
                        Time Frame
                      </Index.Typography>
                      <Index.Box className="time-frame-content positional-sec ">
                        <Index.Box className="time-frame-left">
                          <Index.Box className="time-frame-ul-li">
                            <Index.List className="time-frame-ul">
                              {PositionalButtons.map((item, index) => (
                                <Index.ListItem
                                  className={`time-frame-li chart-setting-timeframe ${
                                    customDefaultSettings?.[
                                      customDefaultSettings?.chartView
                                    ]?.timeFrame === item?.value && "active"
                                  } `}
                                  key={index}
                                >
                                  <Index.Box
                                    className="day-btn"
                                    onClick={() => {
                                      let data;
                                      if (
                                        customDefaultSettings?.chartView ===
                                        "Positional"
                                      ) {
                                        data = {
                                          ...customDefaultSettings?.[
                                            customDefaultSettings?.chartView
                                          ],
                                          timeFrame: item.value,
                                          interval: item?.time?.[0]?.interval,
                                        };
                                      } else {
                                        data = {
                                          ...customDefaultSettings?.[
                                            customDefaultSettings?.chartView
                                          ],
                                          interval: item?.time?.[0]?.interval,
                                        };
                                      }
                                      setCustomDefaultSettings({
                                        ...customDefaultSettings,
                                        [customDefaultSettings?.chartView]:
                                          data,
                                      });
                                    }}
                                  >
                                    <Index.Typography
                                      className="timeframe-title"
                                      variant="p"
                                      component="p"
                                    >
                                      {item.title}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.ListItem>
                              ))}
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Typography
                        className="modal-sub-title"
                        variant="p"
                        component="p"
                      >
                        Time Interval
                      </Index.Typography>
                      <Index.Box className="time-frame-content positional-sec ">
                        <Index.Box className="time-frame-left">
                          <Index.Box className="time-frame-ul-li">
                            <Index.List className="time-frame-ul">
                              {PositionalButtons?.find(
                                (item) =>
                                  item?.value ===
                                  customDefaultSettings?.[
                                    customDefaultSettings?.chartView
                                  ]?.timeFrame
                              )?.time?.map((item, index) => (
                                <Index.ListItem
                                  className={`time-frame-li chart-setting-timeframe ${
                                    customDefaultSettings?.[
                                      customDefaultSettings?.chartView
                                    ]?.interval === item?.interval && "active"
                                  } `}
                                  key={index}
                                >
                                  <Index.Box
                                    className="day-btn"
                                    onClick={() => {
                                      setCustomDefaultSettings({
                                        ...customDefaultSettings,
                                        [customDefaultSettings?.chartView]: {
                                          ...customDefaultSettings?.[
                                            customDefaultSettings?.chartView
                                          ],
                                          interval: item?.interval,
                                        },
                                      });
                                    }}
                                  >
                                    <Index.Typography
                                      className="timeframe-title"
                                      variant="p"
                                      component="p"
                                    >
                                      {item.intervalTitle}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.ListItem>
                              ))}
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </>
                  )}
                </Index.Box>

                <Index.Box className="style-sec">
                  <Index.Typography
                    className="modal-sub-title"
                    variant="p"
                    component="p"
                  >
                    Chart
                  </Index.Typography>
                  <Index.Box className="modal-ul-li">
                    <Index.Grid container>
                      <Index.List className="modal-ul">
                        {DefaultModelStyleButtons.map((item, index) => (
                          <Index.Grid
                            container
                            xs={6}
                            sm={3}
                            md={3}
                            lg={3}
                            gap={5}
                          >
                            <Index.ListItem
                              className={`modal-li cus-center ${
                                customDefaultSettings?.[
                                  customDefaultSettings?.chartView
                                ]?.chartType === index && "active"
                              } custom-def-setting`}
                              onClick={() => {
                                setCustomDefaultSettings({
                                  ...customDefaultSettings,
                                  [customDefaultSettings?.chartView]: {
                                    ...customDefaultSettings?.[
                                      customDefaultSettings?.chartView
                                    ],
                                    chartType: index,
                                  },
                                });
                              }}
                              key={index}
                            >
                              <Index.Typography
                                className="moda-list-p"
                                variant="p"
                                component="p"
                              >
                                {" "}
                                <img
                                  className={`modal-chart-img ${
                                    theme === "Dark" &&
                                    customDefaultSettings?.[
                                      customDefaultSettings?.chartView
                                    ]?.chartType === index
                                      ? "active"
                                      : ""
                                  } `}
                                  src={item.icon}
                                  alt="modalLine"
                                />
                                {item.title}
                              </Index.Typography>
                            </Index.ListItem>
                          </Index.Grid>
                        ))}
                      </Index.List>
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="okay-sec">
                  <Index.PrimaryButton
                    className="primary-btn index-btn okay-btn"
                    btnLabel="Okay"
                    disableRipple={false}
                    onClick={handleManageDefaultSetting}
                  />
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Index.Box className="tab-panel-details">
                <Index.Box className="switch-wrapper">
                  {defaultSettingDisplayToggle.map((item, index) =>
                    item !== null ? (
                      <Index.Box className="switch-box" key={index}>
                        <Index.Typography
                          className="tab-sub-title"
                          variant="p"
                          component="p"
                        >
                          {item.label}
                        </Index.Typography>
                        <Index.Box
                          className={`com-toggle-btn ${
                            item?.name === "AutoSquare" && "auto-square-toggle"
                          }`}
                        >
                          <Index.ToggleButtonGroup
                            value={toggleValue?.[item?.name]}
                            exclusive
                            onChange={(event, newAlignment) => {
                              if (
                                newAlignment &&
                                toggleValue?.[item?.name] !== newAlignment
                              ) {
                                handleToggleGroup(event, newAlignment, item);
                              }
                            }}
                            aria-label="text alignment"
                            className="alignment-toggle-group"
                          >
                            <Index.ToggleButton
                              value={item.firstValue}
                              aria-label="left aligned"
                              className="alignment-toggle-value"
                            >
                              {item.firstValue}
                            </Index.ToggleButton>
                            <Index.ToggleButton
                              value={item.secondValue}
                              aria-label="centered"
                              className="alignment-toggle-value"
                            >
                              {item.secondValue}
                            </Index.ToggleButton>
                          </Index.ToggleButtonGroup>
                          {item?.name === "AutoSquare" &&
                            toggleValue?.[item?.name] == "on" && (
                              <Index.Tooltip title="Auto square time" arrow>
                                <Index.IconButton
                                  className="time-setting-btn"
                                  onClick={handleOpenTimeSetting}
                                >
                                  <img
                                    src={Index.Png.timeSetting}
                                    className="time-setting-img"
                                  />
                                </Index.IconButton>
                              </Index.Tooltip>
                            )}
                        </Index.Box>
                      </Index.Box>
                    ) : (
                      <></>
                    )
                  )}
                  <Index.Box className="switch-box">
                    <Index.Typography
                      className="tab-sub-title"
                      variant="p"
                      component="p"
                    >
                      Mini Chart Width
                    </Index.Typography>
                    {/* <Index.Box className="com-toggle-btn"> */}
                    <Index.Box className="time-frame-right mini-chart-size-box">
                      <Index.Box className="index-input-grp mini-chart-size-input">
                        <Index.Box
                          className="label-no cus-center"
                          onClick={handleMiniChartSize}
                        >
                          +
                        </Index.Box>
                        <Index.Box className="form-group">
                          <Index.TextField
                            type="text"
                            fullWidth
                            name="width"
                            value={miniChartSizeData?.width}
                            id="fullWidth-minutes-1"
                            className="form-control"
                            placeholder="W(px)"
                            onChange={(e) => {
                              let value = +e.target.value?.replace(/\D/g, "");
                              if (value <= 600) {
                                setMiniChartSizeData({
                                  ...miniChartSizeData,
                                  width: value,
                                });
                              }
                            }}
                            onBlur={(e) => {
                              dispatch(activeShortCut(true));
                            }}
                            onFocus={(e) => {
                              dispatch(activeShortCut(false));
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="data-display-sec">
                  <Index.Typography
                    className="tab-sub-title"
                    variant="p"
                    component="p"
                  >
                    Number Of Rows
                  </Index.Typography>
                  <Index.Box className="drop-sec data-display-drop data-display-drop-cus row-nums-drop">
                    <Index.FormControl>
                      <Index.Select
                        value={rowsPerPage}
                        onChange={(e) => {
                          let value = e.target.value;
                          dispatch(manageRowsPerPage(value));
                          dispatch(
                            manageUserSetting(userToken, "rowsPerPage", value)
                          );
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem value={5}>5</Index.MenuItem>
                        <Index.MenuItem value={10}>10</Index.MenuItem>
                        <Index.MenuItem value={20}>20</Index.MenuItem>
                        <Index.MenuItem value={30}>30</Index.MenuItem>
                        <Index.MenuItem value={40}>40</Index.MenuItem>
                        <Index.MenuItem value={50}>50</Index.MenuItem>
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Index.Box className="tab-panel-details">
                <Index.Box className="shortcuts-sec">
                  <Index.Box className="shortcuts-key-btn">
                    <Index.Typography
                      className="key-title"
                      variant="p"
                      component="p"
                    >
                      General Shortcut keys
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="shortcuts-key-edit-delete">
                    <Index.Box className="shortcuts-ul">
                      {shortCutList?.map((item, index) => {
                        return (
                          <Index.Box className="shortcuts-li" key={index}>
                            <Index.Box className="li-title-sec">
                              <Index.Typography
                                className="li-title"
                                variant="p"
                                component="p"
                              >
                                {item.shortCutName}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="li-button-sec">
                              <Index.SecondaryBtn
                                className="secondarybtn-btn shortcut-btn"
                                btnLabel={
                                  item.shortCutKeys?.length > 0
                                    ? item.shortCutKeys?.join(" + ")
                                    : item.shortCutKeys?.join("")
                                }
                                sx={{ marginRight: "5px" }}
                                key={index}
                              />
                              <Index.Box className="edit-delete-sec"></Index.Box>
                            </Index.Box>
                          </Index.Box>
                        );
                      })}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Index.Box className="tab-panel-details">
                <Index.Box className="color-setting">
                  <Index.Button
                    className="reload-btn cus-center"
                    onClick={handleColorSettingReset}
                  >
                    {" "}
                    <img
                      className="reload-img"
                      src={Index.Svg.reload}
                      alt="reload"
                    />
                  </Index.Button>
                  <Index.Typography
                    className="modal-sub-title"
                    variant="p"
                    component="p"
                  >
                    Choose your chart color
                  </Index.Typography>

                  <Index.Box className="chart-color-setting">
                    <Index.Select
                      className={`common-dropdown chart-color-dropdown ${
                        chartColorDropdownOpen && "active"
                      }`}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedChart}
                      onChange={(e) => {
                        setSelectedChart(e.target.value);
                      }}
                      onOpen={(e) => {
                        setChartColorDrowdownOpen(true);
                      }}
                      onClose={(e) => {
                        setChartColorDrowdownOpen(false);
                      }}
                    >
                      {colorSetting.map((chartName, index) => (
                        <Index.MenuItem
                          key={index}
                          value={index}
                          className="common-dropdown-menu"
                        >
                          <Index.Box className="select-chart-card">
                            <Index.Box className="chart-card-img-box">
                              <img src={chartName.image} alt="chart-image" />
                            </Index.Box>
                            <Index.Box className="chart-card-title-box">
                              <Index.Typography className="chart-card-title">
                                {chartName.heading}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.MenuItem>
                      ))}
                    </Index.Select>

                    <Index.Box className="chart-color-box input-none">
                      <Index.Box
                        className="color-box color-box-inline"
                        sx={{ display: "flex" }}
                      >
                        {colorSetting?.[selectedChart].heading ===
                        "Candle Stick Chart" ? (
                          <>
                            <Index.Box className="color-sec">
                              <Index.Typography
                                className="tab-sub-title"
                                variant="p"
                                component="p"
                              >
                                Falling
                              </Index.Typography>
                              <Index.MuiColorInput
                                value={
                                  colorSetting?.[selectedChart].variable[
                                    "fallingColor"
                                  ]
                                }
                                type="color"
                                format="hex"
                                name="fallingColor"
                                className="color-input"
                                onChange={(e) =>
                                  colorSetting?.[selectedChart].handleColor(
                                    e,
                                    "fallingColor"
                                  )
                                }
                              ></Index.MuiColorInput>
                            </Index.Box>

                            <Index.Box className="color-sec">
                              <Index.Typography
                                className="tab-sub-title"
                                variant="p"
                                component="p"
                              >
                                Rising
                              </Index.Typography>
                              <Index.MuiColorInput
                                name="risingColor"
                                value={
                                  colorSetting?.[selectedChart].variable[
                                    "risingColor"
                                  ]
                                }
                                type="color"
                                format="hex"
                                className="color-input"
                                onChange={(e) =>
                                  colorSetting?.[selectedChart].handleColor(
                                    e,
                                    "risingColor"
                                  )
                                }
                              ></Index.MuiColorInput>
                            </Index.Box>
                          </>
                        ) : (
                          <Index.Box className="color-sec">
                            <Index.Typography
                              className="tab-sub-title mr-5"
                              variant="p"
                              component="p"
                            >
                              Colour
                            </Index.Typography>
                            <Index.MuiColorInput
                              value={
                                colorSetting?.[selectedChart].variable["color"]
                              }
                              type="color"
                              format="hex"
                              className="color-input"
                              onChange={(e) =>
                                colorSetting?.[selectedChart].handleColor(e)
                              }
                            ></Index.MuiColorInput>
                          </Index.Box>
                        )}

                        <Index.Box className="color-sec thickness-sec">
                          <Index.Typography
                            className="tab-sub-title mr-5"
                            variant="p"
                            component="p"
                          >
                            Thickness
                          </Index.Typography>
                          {![
                            "Line Chart",
                            "Dotted Chart",
                            "Candle Stick Chart",
                          ].includes(colorSetting?.[selectedChart].heading) && (
                            <Index.MuiColorInput
                              value={
                                colorSetting?.[selectedChart].variable[
                                  "thicknessColor"
                                ]
                              }
                              type="color"
                              format="hex"
                              name="thicknessColor"
                              className="color-input"
                              onChange={(event) =>
                                colorSetting?.[selectedChart].handleThickness(
                                  event,
                                  "thicknessColor"
                                )
                              }
                            ></Index.MuiColorInput>
                          )}

                          <Index.Box className="drop-sec ml-0 thickness-drop">
                            <Index.FormControl>
                              <Index.Select
                                value={
                                  colorSetting?.[selectedChart].variable[
                                    "thickness"
                                  ]
                                }
                                onChange={(e) =>
                                  colorSetting?.[selectedChart].handleThickness(
                                    e,
                                    "thickness"
                                  )
                                }
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem value={1}>
                                  <img
                                    className="line-img"
                                    src={Index.Svg.lineOne}
                                    alt="lineOne"
                                  />
                                </Index.MenuItem>
                                <Index.MenuItem value={2}>
                                  <img
                                    className="line-img"
                                    src={Index.Svg.lineTwo}
                                    alt="lineTwo"
                                  />
                                </Index.MenuItem>
                                <Index.MenuItem value={3}>
                                  <img
                                    className="line-img"
                                    src={Index.Svg.lineThree}
                                    alt="lineThree"
                                  />
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="chart-color-wrapper input-none">
                    <Index.Grid container>
                      {otherSettingInitial.map((e) => {
                        return (
                          <>
                            <Index.Grid container xs={12} sm={6} lg={4} gap={5}>
                              <Index.Box className="crosshair-sec">
                                <Index.Grid container xs={4} sm={6} lg={6}>
                                  <Index.Typography
                                    className="tab-sub-title title"
                                    variant="p"
                                    component="p"
                                  >
                                    {e.title}
                                  </Index.Typography>
                                </Index.Grid>
                                <Index.Grid container xs={1} sm={2} lg={2}>
                                  <Index.Box className="color-sec color-select">
                                    <Index.MuiColorInput
                                      value={
                                        otherSetting?.[theme]?.[e.key]?.color
                                      }
                                      type="color"
                                      format="hex"
                                      className="color-input"
                                      onChange={(event) => {
                                        handleChangeOtherSettings(
                                          event,
                                          e.key,
                                          "color"
                                        );
                                      }}
                                    ></Index.MuiColorInput>
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid container xs={1} sm={2} lg={2}>
                                  <Index.Box className="drop-sec ml-0 thickness-drop">
                                    <Index.FormControl>
                                      <Index.Select
                                        value={
                                          otherSetting?.[theme]?.[e.key]
                                            ?.thickness
                                        }
                                        onChange={(event) =>
                                          handleChangeOtherSettings(
                                            event,
                                            e.key,
                                            "thickness"
                                          )
                                        }
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        <Index.MenuItem value={1}>
                                          <img
                                            className="line-img"
                                            src={Index.Svg.lineOne}
                                            alt="lineOne"
                                          />
                                        </Index.MenuItem>
                                        <Index.MenuItem value={2}>
                                          <img
                                            className="line-img"
                                            src={Index.Svg.lineTwo}
                                            alt="lineTwo"
                                          />
                                        </Index.MenuItem>
                                        <Index.MenuItem value={3}>
                                          <img
                                            className="line-img"
                                            src={Index.Svg.lineThree}
                                            alt="lineTwo"
                                          />
                                        </Index.MenuItem>
                                      </Index.Select>
                                    </Index.FormControl>
                                  </Index.Box>
                                </Index.Grid>
                                {/* {e?.key !== "daySplitter" && ( */}
                                <Index.Grid container xs={1} sm={1} lg={1}>
                                  <Index.Box className="drop-sec thickness-drop">
                                    <Index.FormControl>
                                      <Index.Select
                                        value={
                                          otherSetting?.[theme]?.[e.key]
                                            ?.lineType
                                        }
                                        onChange={(event) =>
                                          handleChangeOtherSettings(
                                            event,
                                            e.key,
                                            "lineType"
                                          )
                                        }
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        <Index.MenuItem value={"0"}>
                                          <img
                                            className="line-img"
                                            src={Index.Svg.lineOne}
                                            alt="lineOne"
                                          />
                                        </Index.MenuItem>
                                        <Index.MenuItem value={"4 3"}>
                                          <img
                                            className="line-img modal-dotted"
                                            src={Index.Svg.modalDotted}
                                            alt="modalDotted"
                                          />
                                        </Index.MenuItem>
                                      </Index.Select>
                                    </Index.FormControl>
                                  </Index.Box>
                                </Index.Grid>
                                {/* )} */}
                              </Index.Box>
                            </Index.Grid>
                          </>
                        );
                      })}
                    </Index.Grid>
                    <Index.Box className="crosshair-sec crosshair-thickness-sec"></Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default DefaultSetting;
