import React, { useRef, useState } from "react";
import Index from "../Index";
import {
  activeShortCut,
  manageGridBorder,
  manageGridColor,
  manageGridSpacing,
  manageUserSetting,
} from "../../redux/user/action";

const GridModal = () => {
  const dispatch = Index.useDispatch();
  const colorPickerRef = useRef(null);
  const { gridSpacing, gridBorder, gridColor, previousGridColor, token } =
    Index.useSelector((store) => store.UserReducer);
  const [error, setError] = useState("");

  const manageGridStyle = (keyPath, value) => {
    dispatch(manageUserSetting(token, keyPath, value));
  };

  return (
    <>
      <Index.Box className="grid-setting-wrapper grid-modal-main">
        <Index.Box className="gap-thickness-color-sec">
          <Index.Box className="gap-sec">
            <Index.Box className="cus-input-grp ">
              <Index.Typography
                className="cus-lable light-font"
                variant="p"
                component="p"
              >
                Grid Gap
              </Index.Typography>
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth-grid-gap"
                  className="form-control"
                  value={gridSpacing * 8}
                  onChange={(e) => {
                    // setOpacity(e.target.value / 100);
                    if (e.target.value <= 30) {
                      dispatch(manageGridSpacing(+e.target.value / 8));
                      manageGridStyle("gridSpacing", +e.target.value / 8);
                      setError("");
                    } else {
                      setError("Not more than 30");
                    }
                  }}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                  name="gap"
                  // placeholder="20"
                  autoComplete="off"
                />
                {error && (
                  <Index.FormHelperText error className="error-text">
                    {error}
                  </Index.FormHelperText>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="gap-sec line-thickness">
            <Index.Box className="cus-input-grp ">
              <Index.Typography
                className="cus-lable light-font"
                variant="p"
                component="p"
              >
                Line Thickness
              </Index.Typography>
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth-line-thickness"
                  className="form-control"
                  // placeholder="2"
                  value={gridBorder}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9]/g, "");
                    if (numericValue !== inputValue) {
                      e.target.value = numericValue;
                    }
                    dispatch(manageGridBorder(numericValue));
                    manageGridStyle("gridBorder", numericValue);
                  }}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                  name="thickness"
                  autoComplete="off"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="gap-sec line-color">
            <Index.Box className="cus-input-grp ">
              <Index.Typography
                className="cus-lable light-font grid-line-color-heading"
                variant="p"
                component="p"
              >
                Line Color
              </Index.Typography>
              <Index.Box className="check-color-pick-sec">
                {/* <Index.Box className="check-color-box cus-center pink-bg">
                                    <img className='check-img' alt='checkWhite' src={Index.Svg.checkWhite} />

                                </Index.Box> */}
                <Index.Box className="previous-color-main">
                  <label className="color-label">Previous</label>
                  <Index.Button
                    className="previous-color-btn"
                    sx={{
                      backgroundColor: previousGridColor,
                      ":hover": {
                        backgroundColor: previousGridColor,
                      },
                    }}
                    onClick={() => dispatch(manageGridColor(previousGridColor))}
                  ></Index.Button>
                </Index.Box>

                <Index.Box>
                  <label className="color-label">Current</label>
                  <Index.MuiColorInput
                    // type="color"
                    variant="standard"
                    // className="color-input"
                    name="color"
                    value={gridColor}
                    onChange={(e) => {
                      // setOpacity(e.target.value / 100);
                      if (colorPickerRef.current) {
                        clearTimeout(colorPickerRef.current);
                      }

                      colorPickerRef.current = setTimeout(() => {
                        dispatch(manageGridColor(e));
                        manageGridStyle("gridColor", e);
                        colorPickerRef.current = null;
                      }, 500);
                    }}
                    format="hex"
                    isAlphaHidden
                    inputProps={{
                      readOnly: true,
                      value: "",
                    }}
                  />
                </Index.Box>
                {/* <Index.Box className="check-color-box cus-center eyeDrop-sec">
                                    <img className='eyeDrop-img' alt='eyeDrop' src={Index.Svg.eyeDrop} />

                                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default GridModal;
