import { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {
//   showHeaderSearchBar,
//   showDefaultSettingModal,
//   GetGraphTypeAction,
//   setChartDuration,
//   setChartView,
//   setTimeFrame,
// } from "./actions"; // Import your action creators here

const useKeyboardShortcut = (externalFunctions = {}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isActiveShortCut,
    isUserLogin,
    chartView,
    timeFrame,
    openDefaultSettingModal,
    chartDuration,
    twoSideArrowZoom,
  } = useSelector((state) => state.UserReducer);

  const pressedKeys = useRef([]);

  const addPressedKey = useCallback((key) => {
    key = key.toLowerCase();
    pressedKeys.current.push(key);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      const key = event.key.toLowerCase();
      if (!pressedKeys.current?.includes(key)) {
        addPressedKey(key);
      }
    },
    [addPressedKey]
  );

  const handleKeyUp = useCallback(
    (event) => {
      const key = event.key.toLowerCase();
      let keys = pressedKeys.current;
      const hasKeys = (...providedKeys) => {
        if (providedKeys.length !== keys.length) {
          return false;
        }

        return providedKeys.every((value) => keys.includes(value));
      };

      if (isActiveShortCut && isUserLogin) {
        if (hasKeys("control", "b")) {
          externalFunctions?.handleShortcutBuyStock &&
            externalFunctions?.handleShortcutBuyStock();
        } else if (hasKeys("control", "s")) {
          externalFunctions?.handleShortcutSellStock &&
            externalFunctions?.handleShortcutSellStock();
        } else if (hasKeys("alt", "w")) {
          externalFunctions?.handleShortcutWatchStock &&
            externalFunctions?.handleShortcutWatchStock();
        } else if (hasKeys("control", "r")) {
          if (externalFunctions?.handleShortcutReverseStock) {
            externalFunctions?.handleShortcutReverseStock();
          }
          if (externalFunctions?.handleShortcutRemoveStock) {
            externalFunctions?.handleShortcutRemoveStock();
          }
        } else if (hasKeys("control", "e")) {
          if (externalFunctions?.handleShortcutExitStock) {
            externalFunctions?.handleShortcutExitStock &&
              externalFunctions?.handleShortcutExitStock();
          }
          if (externalFunctions?.handleOrderStockExit) {
            externalFunctions?.handleOrderStockExit &&
              externalFunctions?.handleOrderStockExit();
          }
        } else if (hasKeys("shift", "s")) {
          externalFunctions?.handleHideChartTimeScale &&
            externalFunctions?.handleHideChartTimeScale();
        } else if (hasKeys("shift", "p")) {
          externalFunctions?.handleHideChartPriceRange &&
            externalFunctions?.handleHideChartPriceRange();
        } else if (hasKeys("shift", "d")) {
          externalFunctions?.handleOpenDrawingModal &&
            externalFunctions?.handleOpenDrawingModal();
        } else if (hasKeys("alt", "t")) {
          externalFunctions?.handleSetTarget &&
            externalFunctions?.handleSetTarget();
        } else if (hasKeys("alt", "l")) {
          externalFunctions?.handleSetStopLoss &&
            externalFunctions?.handleSetStopLoss();
        } else if (hasKeys("shift", "o")) {
          externalFunctions?.handleDrawingChartModal &&
            externalFunctions?.handleDrawingChartModal();
        } else if (hasKeys("alt", "backspace")) {
          if (!twoSideArrowZoom) {
            externalFunctions?.handleBackspacePressed &&
              externalFunctions?.handleBackspacePressed();
          }
        } else if (hasKeys("control", "1")) {
          if (chartDuration[chartView]?.timeFrame === 5) {
            externalFunctions?.handleFifteenMinute &&
              externalFunctions?.handleFifteenMinute(
                chartDuration[chartView]?.timeFrame,
                "FIFTEEN_MINUTE"
              );
          } else if (chartDuration[chartView]?.timeFrame === 15) {
            externalFunctions?.handleThirtyMinute &&
              externalFunctions?.handleThirtyMinute(
                chartDuration[chartView]?.timeFrame,
                "THIRTY_MINUTE"
              );
          } else if ([30, 90]?.includes(chartDuration[chartView]?.timeFrame)) {
            externalFunctions?.handleFourHour &&
              externalFunctions?.handleFourHour(
                chartDuration[chartView]?.timeFrame,
                "FOUR_HOUR"
              );
          } else if (
            [180, 365]?.includes(chartDuration[chartView]?.timeFrame)
          ) {
            externalFunctions?.handleOneDay &&
              externalFunctions?.handleOneDay(
                chartDuration[chartView]?.timeFrame,
                "ONE_DAY"
              );
          }
        } else if (hasKeys("control", "2")) {
          if (chartDuration[chartView]?.timeFrame === 5) {
            externalFunctions?.handleThirtyMinute &&
              externalFunctions?.handleThirtyMinute(
                chartDuration[chartView]?.timeFrame,
                "THIRTY_MINUTE"
              );
          } else if (chartDuration[chartView]?.timeFrame === 15) {
            externalFunctions?.handleOneHour &&
              externalFunctions?.handleOneHour(
                chartDuration[chartView]?.timeFrame,
                "ONE_HOUR"
              );
          } else if (chartDuration[chartView]?.timeFrame === 180) {
            externalFunctions?.handleOneWeek &&
              externalFunctions?.handleOneWeek(
                chartDuration[chartView]?.timeFrame,
                "ONE_WEEK"
              );
          } else if ([30, 90].includes(chartDuration[chartView]?.timeFrame)) {
            externalFunctions?.handleOneDay &&
              externalFunctions?.handleOneDay(
                chartDuration[chartView]?.timeFrame,
                "ONE_DAY"
              );
          } else if (chartDuration[chartView]?.timeFrame === 365) {
            externalFunctions?.handleTwoDay &&
              externalFunctions?.handleTwoDay(
                chartDuration[chartView]?.timeFrame,
                "TWO_DAY"
              );
          }
        } else if (hasKeys("control", "3")) {
          if (chartDuration[chartView]?.timeFrame === 5) {
            externalFunctions?.handleOneHour &&
              externalFunctions?.handleOneHour(
                chartDuration[chartView]?.timeFrame,
                "ONE_HOUR"
              );
          } else if (chartDuration[chartView]?.timeFrame === 15) {
            externalFunctions?.handleFourHour &&
              externalFunctions?.handleFourHour(
                chartDuration[chartView]?.timeFrame,
                "FOUR_HOUR"
              );
          } else if (chartDuration[chartView]?.timeFrame === 365) {
            externalFunctions?.handleThreeDay &&
              externalFunctions?.handleThreeDay(
                chartDuration[chartView]?.timeFrame,
                "THREE_DAY"
              );
          }
        } else if (hasKeys("control", "4")) {
          if (chartDuration[chartView]?.timeFrame === 365) {
            externalFunctions?.handleOneWeek &&
              externalFunctions?.handleOneWeek(
                chartDuration[chartView]?.timeFrame,
                "ONE_WEEK"
              );
          }
        } else if (hasKeys("control", "5")) {
          if (chartDuration[chartView]?.timeFrame === 365) {
            externalFunctions?.handleTwoWeek &&
              externalFunctions?.handleTwoWeek(
                chartDuration[chartView]?.timeFrame,
                "TWO_WEEK"
              );
          }
        } else if (hasKeys("control", "6")) {
          if (chartDuration[chartView]?.timeFrame === 365) {
            externalFunctions?.handleThreeWeek &&
              externalFunctions?.handleThreeWeek(
                chartDuration[chartView]?.timeFrame,
                "THREE_WEEK"
              );
          }
        } else if (hasKeys(" ")) {
          if (!twoSideArrowZoom) {
            externalFunctions?.handleSpacePressed &&
              externalFunctions?.handleSpacePressed();
          }
        } else if (hasKeys("escape")) {
          externalFunctions?.handleEscapeModalOff &&
            externalFunctions?.handleEscapeModalOff();
        } else if (hasKeys("delete")) {
          if (externalFunctions?.headerRemoveTargetOrStopLoss) {
            externalFunctions?.headerRemoveTargetOrStopLoss &&
              externalFunctions?.headerRemoveTargetOrStopLoss();
          }
          if (externalFunctions?.removeAnnotationOnDeletePress) {
            externalFunctions?.removeAnnotationOnDeletePress &&
              externalFunctions?.removeAnnotationOnDeletePress();
          }
        } else if (hasKeys("s")) {
          externalFunctions?.headerSearch && externalFunctions?.headerSearch();
        } else if (hasKeys("l")) {
          externalFunctions?.handleLineChart &&
            externalFunctions?.handleLineChart();
        } else if (hasKeys("a")) {
          externalFunctions?.handleAreaChart &&
            externalFunctions?.handleAreaChart();
        } else if (hasKeys("d")) {
          externalFunctions?.handleDashedChart &&
            externalFunctions?.handleDashedChart();
        } else if (hasKeys("c")) {
          externalFunctions?.handleCandlestickChart &&
            externalFunctions?.handleCandlestickChart();
        } else if (hasKeys("p")) {
          externalFunctions?.positionalViewOpen &&
            externalFunctions?.positionalViewOpen();
        } else if (hasKeys("i")) {
          externalFunctions?.intradayViewOpen &&
            externalFunctions?.intradayViewOpen();
        } else if (hasKeys("v")) {
          externalFunctions?.handleHideShowHeaderSidebar &&
            externalFunctions?.handleHideShowHeaderSidebar(false);
        } else if (hasKeys("h")) {
          externalFunctions?.handleHideShowHeaderSidebar &&
            externalFunctions?.handleHideShowHeaderSidebar(true);
        } else if (hasKeys("1")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleOneMinInterval &&
              externalFunctions?.handleOneMinInterval();
          } else {
            externalFunctions?.handleFiveWorkingDay &&
              externalFunctions?.handleFiveWorkingDay();
          }
        } else if (hasKeys("2")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleTwoMinInterval &&
              externalFunctions?.handleTwoMinInterval();
          } else {
            externalFunctions?.handleFifteenWorkingDay &&
              externalFunctions?.handleFifteenWorkingDay();
          }
        } else if (hasKeys("3")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleThreeMinInterval &&
              externalFunctions?.handleThreeMinInterval();
          } else {
            externalFunctions?.handleOneMonthChart &&
              externalFunctions?.handleOneMonthChart();
          }
        } else if (hasKeys("4")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleFourMinInterval &&
              externalFunctions?.handleFourMinInterval();
          } else {
            externalFunctions?.handleThreeMonthChart &&
              externalFunctions?.handleThreeMonthChart();
          }
        } else if (hasKeys("5")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleFiveMinInterval &&
              externalFunctions?.handleFiveMinInterval();
          } else {
            externalFunctions?.handleSixMonthChart &&
              externalFunctions?.handleSixMonthChart();
          }
        } else if (hasKeys("6")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleTenMinInterval &&
              externalFunctions?.handleTenMinInterval();
          } else {
            externalFunctions?.handleOneYearChart &&
              externalFunctions?.handleOneYearChart();
          }
        } else if (hasKeys("7")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleFifteenMinInterval &&
              externalFunctions?.handleFifteenMinInterval();
          }
        }
        pressedKeys.current = [];
        keys = [];
      } else {
        pressedKeys.current = [];
        keys = [];
      }
    },
    [
      isActiveShortCut,
      isUserLogin,
      dispatch,
      navigate,
      externalFunctions,
      addPressedKey,
      twoSideArrowZoom,
    ]
  );

  const ignoreShortcut = (e) => {
    const controlSubKeys = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "b",
      "s",
      "w",
      "r",
      "e",
    ];
    const shiftSubKeys = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "b",
      "s",
      "w",
      "r",
      "e",
    ];

    const isShortcutPrevent =
      (e?.ctrlKey && controlSubKeys?.includes(e?.key?.toLowerCase())) ||
      (e?.shiftKey && shiftSubKeys?.includes(e?.key?.toLowerCase()));
    if (isShortcutPrevent) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      document.addEventListener("keyup", handleKeyUp);
      document.addEventListener("keydown", ignoreShortcut);
      return () => {
        document.removeEventListener("keyup", handleKeyUp);
        document.removeEventListener("keydown", ignoreShortcut);
      };
    }
  }, [isActiveShortCut, isUserLogin, handleKeyUp]);

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [isActiveShortCut, isUserLogin, handleKeyPress]);

  return { handleKeyPress, handleKeyUp };
};

export default useKeyboardShortcut;
